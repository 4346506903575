import React from 'react'
import { connect } from 'react-redux'

import loadable from '@loadable/component'
import classNames from 'classnames'

import * as actionCreators from '../../../../../store/actions'
import { mapStateToProps, translate } from '../../../../../utils'

const JujoLoading = loadable(() => import('../../../../loading'))

export class JujoSVGTextAnchorManagerComponent extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      loading: true,
      text_element: null,
      rect_element: null,
    }
  }

  componentWillUnmount() {
    // Fix Warning: Can't perform a React state update on an unmounted component
    this.setState = () => {}
  }

  componentDidMount = async () => {
    if (typeof window !== 'undefined') {
      const { element_key } = this.props

      const domElement = document.getElementById(element_key)
      const text_element = domElement.getElementsByTagName('text')[0]
      const rect_element = domElement.getElementsByTagName('rect')[0]

      this.setState({ text_element, rect_element, loading: false })
    }
  }

  handleTextLeftClicked = () => {
    const { text_element, rect_element } = this.state

    const rect_x = rect_element.getAttribute('x')

    text_element.setAttribute('x', rect_x)

    if (text_element.childElementCount > 0) {
      text_element.childNodes.forEach(item => {
        item.setAttributeNS(null, 'x', rect_x)
      })
    }

    text_element.setAttribute('text-anchor', 'start')
  }

  handleTextHorizontalCenterClicked = () => {
    const { text_element, rect_element } = this.state

    const rect_x = rect_element.getAttribute('x')
    const rect_width = rect_element.getAttribute('width')

    const new_x_position = (
      parseFloat(rect_width / 2) + parseFloat(rect_x)
    ).toFixed(2)

    text_element.setAttribute('x', new_x_position)

    if (text_element.childElementCount > 0) {
      text_element.childNodes.forEach(item => {
        item.setAttributeNS(null, 'x', new_x_position)
      })
    }

    text_element.setAttribute('text-anchor', 'middle')
  }

  handleTextRightClicked = () => {
    const { text_element, rect_element } = this.state

    const rect_x = rect_element.getAttribute('x')
    const rect_width = rect_element.getAttribute('width')

    const new_x_position = (
      parseFloat(rect_width) + parseFloat(rect_x)
    ).toFixed(2)

    text_element.setAttribute('x', new_x_position)

    if (text_element.childElementCount > 0) {
      text_element.childNodes.forEach(item => {
        item.setAttributeNS(null, 'x', new_x_position)
      })
    }

    text_element.setAttribute('text-anchor', 'end')
  }

  handleTextTopClicked = () => {
    const { text_element, rect_element } = this.state

    const first_line_height = text_element.childNodes[0].getBBox().height
    const rect_y = rect_element.getBBox().y

    const new_y_position = (
      parseFloat(rect_y) +
      parseFloat(first_line_height) / 2
    ).toFixed(2)

    text_element.setAttribute('y', new_y_position)
  }

  handleTextVerticalCenterClicked = () => {
    const { text_element, rect_element } = this.state

    const text_height = text_element.getBBox().height
    const first_line_height = text_element.childNodes[0].getBBox().height
    const rect_bbox = rect_element.getBBox()
    const rect_height = rect_bbox.height
    const rect_y = rect_bbox.y

    const new_y_position = (
      parseFloat(rect_y) +
      parseFloat(first_line_height) +
      (parseFloat(rect_height) - parseFloat(text_height)) / 2
    ).toFixed(2)

    text_element.setAttribute('y', new_y_position)
  }

  handleTextBottomClicked = () => {
    const { text_element, rect_element } = this.state

    const text_height = text_element.getBBox().height
    const first_line_height = text_element.childNodes[0].getBBox().height
    const rect_bbox = rect_element.getBBox()
    const rect_height = rect_bbox.height
    const rect_y = rect_bbox.y

    const new_y_position = (
      parseFloat(rect_y) +
      parseFloat(first_line_height) +
      parseFloat(rect_height) -
      parseFloat(text_height)
    ).toFixed(2)

    text_element.setAttribute('y', new_y_position)
  }

  drawElement = () => {
    const html = []

    html.push(
      <div key="text_anchor_manager">
        <div>{translate('text_anchor')}</div>
        <div className={classNames('d-flex')}>
          <div
            className={classNames('background-image theme-svg text-left-icon')}
            style={{ width: 40, height: 40 }}
            role="button"
            label="text-left"
            tabIndex={0}
            onClick={() => this.handleTextLeftClicked()}
            onKeyPress={() => this.handleTextLeftClicked()}
          />
          <div
            className={classNames(
              'background-image theme-svg text-center-icon mx-2'
            )}
            style={{ width: 40, height: 40 }}
            role="button"
            label="text-center"
            tabIndex={0}
            onClick={() => this.handleTextHorizontalCenterClicked()}
            onKeyPress={() => this.handleTextHorizontalCenterClicked()}
          />
          <div
            className={classNames('background-image theme-svg text-right-icon')}
            style={{ width: 40, height: 40 }}
            role="button"
            label="text-right"
            tabIndex={0}
            onClick={() => this.handleTextRightClicked()}
            onKeyPress={() => this.handleTextRightClicked()}
          />
        </div>
        <div className={classNames('d-flex')}>
          <div
            className={classNames('background-image theme-svg align-top-icon')}
            style={{ width: 40, height: 40 }}
            role="button"
            label="text-left"
            tabIndex={0}
            onClick={() => this.handleTextTopClicked()}
            onKeyPress={() => this.handleTextTopClicked()}
          />
          <div
            className={classNames(
              'background-image theme-svg align-center-icon mx-2'
            )}
            style={{ width: 40, height: 40 }}
            role="button"
            label="text-left"
            tabIndex={0}
            onClick={() => this.handleTextVerticalCenterClicked()}
            onKeyPress={() => this.handleTextVerticalCenterClicked()}
          />
          <div
            className={classNames(
              'background-image theme-svg align-bottom-icon'
            )}
            style={{ width: 40, height: 40 }}
            role="button"
            label="text-left"
            tabIndex={0}
            onClick={() => this.handleTextBottomClicked()}
            onKeyPress={() => this.handleTextBottomClicked()}
          />
        </div>
      </div>
    )

    return html
  }

  render() {
    const { loading } = this.state
    return (
      <>
        {loading === true && <JujoLoading />}
        {loading === false && (
          <div className={classNames('')}>{this.drawElement()}</div>
        )}
      </>
    )
  }
}

export default connect(
  mapStateToProps,
  actionCreators
)(JujoSVGTextAnchorManagerComponent)
