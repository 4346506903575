import React, { useState, useEffect } from 'react'
import classNames from 'classnames'
import loadable from '@loadable/component'

const JujoLoading = loadable(() => import('../../../../loading'))
const ImageMultiplyManager = loadable(() => import('./image_multiply_manager'))

function IEQButtons({ element_key, img_element }) {
  const [horizontally_flipped, setHorizontallyFlipped] = useState(false)
  const [vertically_flipped, setVerticallyFlipped] = useState(false)

  const loadCoordinates = () => {
    const img_coordinates = img_element
      .getAttribute('transform')
      .match(/\((.*?)\)/)[1]
      .split(',')
    return img_coordinates
  }

  useEffect(() => {
    const coordinates = loadCoordinates()
    const hor_flipped = coordinates[0] === '-1'
    const ver_flipped = coordinates[3] === '-1'

    setHorizontallyFlipped(hor_flipped)
    setVerticallyFlipped(ver_flipped)
  }, [])

  const updateMatrix = coordinates => {
    const string_coordinates = coordinates.join(',')
    img_element.setAttribute('transform', `matrix(${string_coordinates})`)
  }

  const handleFlipHorizontal = () => {
    const coordinates = loadCoordinates()
    const scale_x = coordinates[0]
    const img_width = img_element.getAttribute('width')

    coordinates[0] = scale_x === '1' ? '-1' : '1'
    coordinates[4] =
      scale_x === '1'
        ? parseFloat(coordinates[4]) + parseFloat(img_width)
        : parseFloat(coordinates[4]) - parseFloat(img_width)

    updateMatrix(coordinates)
    setHorizontallyFlipped(!horizontally_flipped)
  }

  const handleFlipVertical = () => {
    const coordinates = loadCoordinates()
    const scale_y = coordinates[3]
    const img_height = img_element.getAttribute('height')

    coordinates[3] = scale_y === '1' ? '-1' : '1'
    coordinates[5] =
      scale_y === '1'
        ? parseFloat(coordinates[5]) + parseFloat(img_height)
        : parseFloat(coordinates[5]) - parseFloat(img_height)

    updateMatrix(coordinates)
    setVerticallyFlipped(!vertically_flipped)
  }

  return (
    <div className={classNames('d-flex w-100')}>
      <div
        className={classNames(
          'background-image flip-horizontal-icon',
          horizontally_flipped && 'theme-svg'
        )}
        style={{ width: 25, height: 25 }}
        role="button"
        tabIndex={0}
        label="flip-horizontal"
        onClick={() => {
          handleFlipHorizontal()
        }}
        onKeyPress={() => {
          handleFlipHorizontal()
        }}
      />
      <div
        className={classNames(
          'ms-3 background-image flip-vertical-icon',
          vertically_flipped && 'theme-svg'
        )}
        style={{ width: 25, height: 25 }}
        role="button"
        tabIndex={0}
        label="flip-horizontal"
        onClick={() => {
          handleFlipVertical()
        }}
        onKeyPress={() => {
          handleFlipVertical()
        }}
      />
      <ImageMultiplyManager element_key={element_key} />
    </div>
  )
}

/**
 * `ImageEditorQuickActions` is a function that returns a `JujoLoading` component if the `img_element`
 * is null, otherwise it returns an `IEQButtons` component
 * @returns A component that is waiting for the image element to be set.
 */
function ImageEditorQuickActions({ element_key }) {
  const [img_element, setImageElement] = useState(null)

  const initData = async () => {
    const g_element = document.getElementById(element_key)
    const img_el = g_element.getElementsByTagName('image')[0]

    setImageElement(img_el)
  }

  useEffect(() => {
    const execInitData = async () => {
      await initData()
    }

    execInitData()
  }, [])

  return (
    <>
      {img_element === null && <JujoLoading />}
      {img_element !== null && (
        <IEQButtons element_key={element_key} img_element={img_element} />
      )}
    </>
  )
}

export default ImageEditorQuickActions
