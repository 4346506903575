import React from 'react'
import { connect } from 'react-redux'

import * as actionCreators from '../../../store/actions'
import { mapStateToProps } from '../../../utils'

export class ECTimeFieldComponent extends React.Component {
  componentWillUnmount() {
    // Fix Warning: Can't perform a React state update on an unmounted component
    this.setState = () => {}
  }

  render() {
    const { field, initialValue, handleValueChanged } = this.props
    const { name } = field
    return (
      <input
        type="time"
        name={name}
        defaultValue={initialValue}
        onChange={e => {
          const { value } = e.target
          handleValueChanged(name, value)
        }}
      />
    )
  }
}

export default connect(mapStateToProps, actionCreators)(ECTimeFieldComponent)
