import React from 'react'
import loadable from '@loadable/component'
import classNames from 'classnames'

const JujoSVGNewTextBtn = loadable(() => import('./new_text'))
const JujoSVGNewImageBtn = loadable(() => import('./new_image'))
const JujoSVGMoveForwardBtn = loadable(() => import('./move_forward'))
const JujoSVGMoveBackwardBtn = loadable(() => import('./move_backward'))
const JujoSVGDuplicateBtn = loadable(() => import('./duplicate'))
const JujoSVGDeleteBtn = loadable(() => import('./delete'))

function JujoSVGQuickActions({
  svg_id,
  element_key,
  default_font,
  setFocusedElement,
}) {
  return (
    <div className={classNames('d-flex flex-wrap justify-content-center')}>
      <JujoSVGNewTextBtn
        svg_id={svg_id}
        default_font={default_font}
        setFocusedElement={setFocusedElement}
      />
      <JujoSVGNewImageBtn
        svg_id={svg_id}
        setFocusedElement={setFocusedElement}
      />
      <JujoSVGMoveForwardBtn element_key={element_key} />
      <JujoSVGMoveBackwardBtn element_key={element_key} />
      <JujoSVGDuplicateBtn
        svg_id={svg_id}
        element_key={element_key}
        setFocusedElement={setFocusedElement}
      />
      <JujoSVGDeleteBtn
        element_key={element_key}
        setFocusedElement={setFocusedElement}
      />
    </div>
  )
}

export default JujoSVGQuickActions
