import React, { Component } from 'react'
import { connect } from 'react-redux'

import uuid from 'react-uuid'
import renderFieldsTypeMap from '../../enums/renderFieldsTypeMap'
import Box from './_parts/box'

import * as apiService from '../../services/apiService'
import * as actionCreators from '../../store/actions'

import './styles.scss'
import countries from '../../translations/countries.json'
import boxActionsMap from '../../enums/boxActionsMap'
import requestTypesMap from '../../enums/requestTypesMap'
import dataRenderComponentTypesMap from '../../enums/dataRenderComponentTypesMap'
import apiCallMap from '../../enums/apiCallMap'
import { baseRequestObject, parseEndpoint } from '../../services/servicesHelper'
import apiRequestTypesMap from '../../enums/apiRequestTypesMap'
import { mapStateToProps } from '../../utils'

const classNames = require('classnames')

class BoxList extends Component {
  constructor(props) {
    super(props)
    this.state = {
      initialized: false,
      data: [],
    }
  }

  componentWillUnmount() {
    this.setState = () => {}
  }

  componentDidMount = async () => {
    await this.loadBoxList()
  }

  loadBoxList = async (changeInitializedStatus = false) => {
    if (changeInitializedStatus === true) this.setState({ initialized: false })

    const reqObj = this.composeRequest()
    const { parsedEp } = reqObj

    const result = await apiService.httpGet(`${process.env.apiUrl}${parsedEp}`)
    if (result) {
      const { status } = result
      if (status === 200) {
        this.setState({ initialized: true, data: result.data.data })
      }
    }

    if (changeInitializedStatus === true) this.setState({ initialized: true })
  }

  composeRequest = () => {
    const { parentData, authentication, entity, environment, specialization } =
      this.props

    const { entities } = specialization.config
    const entityDefinition =
      entities[entity][dataRenderComponentTypesMap.box_list]

    const { apis } = entityDefinition
    const { getData } = apis
    const { apiCall, defaultFilters, placeholderMapping, relatedEntity } =
      getData

    const requestEntity =
      relatedEntity && relatedEntity !== 'inherit' ? relatedEntity : entity

    const requestData = baseRequestObject(
      apiCallMap[apiCall],
      requestEntity,
      apiRequestTypesMap.get,
      environment,
      authentication
    )
    requestData.defaultFilters = defaultFilters || []
    requestData.placeholderMapping = placeholderMapping || []
    requestData.parentData = parentData || {}
    const parsedEp = parseEndpoint(requestData)

    return {
      parsedEp,
      data: [],
    }
  }

  handleButtonClick = (item, button) => {
    switch (button.action) {
      case boxActionsMap.edit_entity:
        this.handleEditEntity(item)
        break
      default:
        break
    }
  }

  handleEditEntity = async item => {
    const { section, dynamicForm, updateDynamicFormIntoRedux } = this.props
    const { entity } = section

    let dForm = { ...dynamicForm }
    dForm.entity = entity
    dForm.type = requestTypesMap.update
    dForm.source = item
    dForm.visible = true
    dForm = await updateDynamicFormIntoRedux(dForm)
  }

  handleNewEntity = async () => {
    const { section, parentData, dynamicForm, updateDynamicFormIntoRedux } =
      this.props
    const { entity } = section

    const dForm = { ...dynamicForm }
    dForm.entity = entity
    dForm.type = requestTypesMap.add
    dForm.visible = true
    dForm.parent = parentData.data

    dForm.callback.onSave = {
      action: 'reloadAddressesOnCreationCompleted',
      params: {
        sender: this,
      },
    }

    await updateDynamicFormIntoRedux({ ...dForm })
  }

  renderActions = () => {
    const { data } = this.state
    const { section } = this.props
    const { addNewEnabled, maxInstances } = section
    const renderAddButton = !!(
      (maxInstances && addNewEnabled === true && maxInstances > data.length) ||
      (addNewEnabled && maxInstances === undefined)
    )

    const html = []
    if (renderAddButton) {
      html.push(
        <div
          key={uuid()}
          className={classNames(
            'container-fluid d-flex justify-content-end py-2 border-bottom shadow-sm'
          )}
        >
          <div
            className={classNames('btn add-icon-2 theme-svg')}
            style={{
              backgroundRepeat: 'no-repeat',
              backgroundPosition: 'center',
              width: '25px',
              height: '25px',
              backgroundSize: '20px',
            }}
            label="btn"
            role="button"
            tabIndex={0}
            onClick={() => {
              this.handleNewEntity()
            }}
            onKeyPress={() => {
              this.handleNewEntity()
            }}
          />
        </div>
      )
    }

    return html
  }

  renderBoxList = () => {
    const { data } = this.state

    const { environment, section, specialization } = this.props
    const { translations } = specialization
    const { texts } = translations
    const { locale } = environment

    const { buttonList, renderFields } = section

    const html = []

    if (data.length === 0) {
      html.push(
        <div key={uuid()} className={classNames({ empty_section: true })}>
          {texts[locale].no_data_to_render}
        </div>
      )
    }
    for (let i = 0; i !== data.length; i += 1) {
      const content = []
      const item = data[i]

      for (let j = 0; j !== renderFields.length; j += 1) {
        const row = renderFields[j]
        let line = ''
        for (let x = 0; x !== row.length; x += 1) {
          const cell = row[x]
          const { type, value } = cell
          if (type === renderFieldsTypeMap.label) {
            line += texts[locale][value]
          } else if (type === renderFieldsTypeMap.static) {
            line += value || ''
          } else if (type === renderFieldsTypeMap.data) {
            const dataValue = item[value] || ''
            line += dataValue
          } else if (type === renderFieldsTypeMap.country) {
            const dataValue = countries[locale][item[value]]
            line += dataValue
          }
        }
        content.push(line)
      }

      html.push(
        <Box
          key={uuid()}
          content={content}
          buttons={buttonList}
          item={item}
          onClick={this.handleButtonClick}
        />
      )
    }
    return html
  }

  render() {
    const { initialized } = this.state

    return (
      <>
        {initialized && (
          <div className={classNames('bg-white p-3 rounded-5')}>
            {this.renderActions()}
            <div
              className={classNames(
                'd-flex flex-wrap justify-content-start p-2'
              )}
            >
              {this.renderBoxList()}
            </div>
          </div>
        )}
      </>
    )
  }
}

export default connect(mapStateToProps, actionCreators)(BoxList)
