import React from 'react'
import { connect } from 'react-redux'
import * as actionCreators from '../../../../../../../../src/store/actions'
import { cloneObj, mapStateToProps } from '../../../../../../../../src/utils'
import * as apiService from '../../../../../../../../src/services/apiService'
import FieldShell from '../../../../common/field_shell'

import ECTextFieldComponent from '../../../../../../../../src/components/common/fields/textField'
import ECDateFieldComponent from '../../../../../../../../src/components/common/fields/dateField'
import JujoLoading from '../../../../../../../../src/components/loading/index'
import { saveSessionToServer } from '../../../../../../../../src/services/sessionHelper'
import JujoButtonComponent from '../../../../../../../../src/components/jujo_button'
import ECSelectFieldComponent from '../../../../../../../../src/components/common/fields/selectField'

const classNames = require('classnames')

const empty_fields = {
  invoice_file_total: {
    name: 'invoice_file_total',
    value: '',
    disabled: true,
  },
  invoice_number: {
    name: 'invoice_number',
    value: '',
  },
  invoice_total_amount: {
    name: 'invoice_total_amount',
    value: '',
  },
  invoice_costs: {
    name: 'invoice_costs',
    value: '0',
  },
  invoice_year_of_competence: {
    name: 'invoice_year_of_competence',
    value: new Date().getFullYear(),
  },
  invoice_date: {
    name: 'invoice_date',
    value: '',
  },
  invoice_net: {
    name: 'invoice_net',
    value: 0,
  },
  invoice_technical_change: {
    name: 'invoice_technical_change',
    value: 0,
  },
  invoice_description_month: {
    name: 'invoice_description_month',
    value: '',
  },
  invoice_description: {
    name: 'invoice_description',
    value: '',
  },
  invoice_total_amount_commission: {
    name: 'invoice_total_amount_commission',
    value: 0,
  },
}

export class OssigenoWizardStepInvoicing extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      working: false,
      fields: cloneObj(empty_fields),
      show_invoice_data: false,
    }
  }

  componentWillUnmount() {
    // fix Warning: Can't perform a React state update on an unmounted component
    this.setState = () => {}
  }

  componentDidMount = async () => {
    const { session } = this.props
    const { session_data } = session
    const { wizard } = session_data
    const { invoice_data } = wizard
    const { invoiced } = invoice_data
    this.setState({ show_invoice_data: !invoiced })
  }

  handlePickerChange = async (item, value) => {
    const { fields } = this.state
    const fieldsUpdated = { ...fields }
    const { session, updateSession } = this.props
    const session_copy = cloneObj(session)
    const { session_id, session_data } = session_copy
    const { wizard } = session_data
    wizard[item] = value
    fieldsUpdated[item].value = value
    this.setState({ fields: fieldsUpdated })
    const returned_data = await saveSessionToServer(session_id, session_data)
    session_copy.session_data = returned_data
    await updateSession(session_copy)
  }

  handleFieldChanged = (name, value) => {
    const { fields } = this.state
    const fieldsUpdated = { ...fields }
    let valueUpdated = value
    if (name === 'invoice_total_amount' || name === 'invoice_costs') {
      valueUpdated = parseFloat(valueUpdated.toString().replace(',', '.'))
    }
    fieldsUpdated[name].value = valueUpdated

    const {
      invoice_total_amount,
      invoice_costs,
      invoice_total_amount_commission,
      invoice_net,
      invoice_file_total,
    } = fieldsUpdated

    // Levo eventuali costi
    fieldsUpdated.invoice_net.value = 0
    if (
      !Number.isNaN(invoice_total_amount.value) &&
      !Number.isNaN(invoice_costs.value) &&
      invoice_total_amount.value !== '' &&
      invoice_costs.value !== ''
    ) {
      fieldsUpdated.invoice_net.value =
        parseFloat(invoice_total_amount.value) - parseFloat(invoice_costs.value)
    }

    // Levo l'eventuale commissione sull'importo totale imposta manualmente da Ciano
    if (
      !Number.isNaN(invoice_total_amount.value) &&
      !Number.isNaN(invoice_total_amount_commission.value) &&
      invoice_total_amount.value !== '' &&
      invoice_total_amount_commission.value !== ''
    ) {
      fieldsUpdated.invoice_net.value -=
        (parseFloat(invoice_total_amount.value) / 100) *
        parseFloat(invoice_total_amount_commission.value)
    }

    fieldsUpdated.invoice_technical_change.value = 1
    if (
      !Number.isNaN(invoice_file_total.value) &&
      !Number.isNaN(invoice_net.value) &&
      invoice_file_total.value !== '' &&
      invoice_file_total.value !== 0 &&
      invoice_net.value !== '' &&
      parseFloat(invoice_net.value) !== 0
    ) {
      fieldsUpdated.invoice_technical_change.value =
        parseFloat(invoice_net.value) / parseFloat(invoice_file_total.value)
    }

    this.setState({ fields: fieldsUpdated })
  }

  handleApplyTechnicalChange = async () => {
    const { session } = this.props
    const { session_data } = session
    const { wizard } = session_data
    const { import_id, client_id } = wizard
    const { fields } = this.state
    const parsedEp = 'action/post/fee/apply-technical-change'
    const data = {
      form_fields: cloneObj(fields),
      import_id,
      client_id,
    }
    await this.setState({ working: true })
    const response = await apiService.httpPost(
      `${process.env.apiUrl}${parsedEp}`,
      data
    )
    const { status } = response
    if (status === 200 || status === 201) {
      if (typeof window !== 'undefined') {
        window.location.reload()
        await this.setState({ fields: { ...empty_fields } })
      }
    }
    await this.setState({ working: false })
  }

  handleNextStep = async () => {
    const { session, updateSession } = this.props
    const session_copy = cloneObj(session)
    const { session_id, session_data } = session_copy
    const { wizard } = session_data
    wizard.active_step = 3
    await this.setState({ working: true })
    const returned_data = await saveSessionToServer(session_id, session_data)
    session_copy.session_data = returned_data
    await updateSession(session_copy)
    await this.setState({ working: false })
  }

  handleShowInvoiceData = () => {
    this.setState({ show_invoice_data: true })
  }

  showErrors = errors => {
    const html = []
    html.push(
      <>
        <div className="fw-bold text-center mb-5">
          <h3 className="text-danger">ATTENZIONE!!!</h3>
          <h4 className="text-danger">
            L'importazione si è conclusa con errori, prego risolverli prima di
            procedere alla lavorazione dei dati
          </h4>
        </div>
        <div >
          <h5 className="fw-normal">Lista errori riscontrati:</h5>
          <hr />
        </div>
      </>
    )
    for (let i = 0; i < errors.length; i += 1) {
      const { type, message } = errors[i]
      html.push(
        <div className="my-1 border rounded-3 p-3 border-2">
          <h5 className="text-danger">{`# ${type}`}</h5>
          <div dangerouslySetInnerHTML={{ __html: message }} />
        </div>
      )
    }
    return html
  }

  render() {
    const { fields, working, show_invoice_data } = this.state
    const { session } = this.props
    const { session_data } = session
    const { wizard } = session_data
    const { invoice_data, errors } = wizard
    const {
      file_total,
      invoiced,
      competence_year_list,
      invoice_description_month_list,
    } = invoice_data
    const {
      invoice_number,
      invoice_total_amount,
      invoice_costs,
      invoice_year_of_competence,
      invoice_date,
      invoice_net,
      invoice_technical_change,
      invoice_description,
      invoice_description_month,
      invoice_total_amount_commission,
      invoice_file_total,
    } = fields

    invoice_file_total.value = file_total

    const year_of_competence_field = {
      name: 'invoice_year_of_competence',
      options: competence_year_list,
    }

    const invoice_description_month_field = {
      name: 'invoice_description_month',
      options: invoice_description_month_list,
    }

    return (
      <>
        {working && <JujoLoading />}
        {errors.length > 0 && !working && (
          <div className={classNames('w-100 w-md-50 d-flex flex-column')}>
            {this.showErrors(errors)}
          </div>
        )}
        {errors.length === 0 && !working && (
          <div className={classNames('w-100 w-md-50 d-flex flex-column')}>
            <div className={classNames('mb-5 fw-bold w-100 text-center')}>
              Dati di fatturazione
            </div>
            {!show_invoice_data && (
              <div
                className={classNames(
                  'bg-light-grey p-3 px-5 d-flex flex-column align-items-center justify-content-center'
                )}
              >
                <div
                  className={classNames(
                    'py-2 text-center d-flex align-items-center'
                  )}
                >
                  <div
                    className={classNames('green-check-icon me-2')}
                    style={{
                      width: '25px',
                      height: '25px',
                      backgroundPosition: 'center',
                      backgroundSize: 'contain',
                      backgroundRepeat: 'no-repeat',
                    }}
                  />
                  Hai già definito i dati di fatturazione per questa lavorazione
                </div>
                <div className={classNames('py-2 text-center')}>
                  <div>
                    <JujoButtonComponent
                      bstyle={8}
                      bwidth="200px"
                      blabel="Voglio ridefinirli"
                      handleClick={() => {
                        this.handleShowInvoiceData()
                      }}
                    />
                  </div>
                </div>
              </div>
            )}
            {show_invoice_data && (
              <div className={classNames('bg-light-grey p-3 px-5 d-flex row')}>
                
                {!working && (
                  <>
                    <div className={classNames('col-12 col-md-4 py-2')}>
                      <FieldShell fieldName="invoice_date" withBorder>
                        <ECDateFieldComponent
                          initialValue={invoice_date.value}
                          field={invoice_date}
                          handleValueChanged={this.handleFieldChanged}
                        />
                      </FieldShell>
                    </div>
                    <div className={classNames('col-12 col-md-4 py-2')}>
                      <FieldShell fieldName="invoice_number" withBorder>
                        <ECTextFieldComponent
                          initialValue={invoice_number.value}
                          field={invoice_number}
                          handleValueChanged={this.handleFieldChanged}
                        />
                      </FieldShell>
                    </div>

                    <div className={classNames('col-12 col-md-4 py-2')}>
                      <FieldShell
                        fieldName="invoice_year_of_competence"
                        withBorder
                      >
                        <ECSelectFieldComponent
                          field={year_of_competence_field}
                          handleValueChanged={this.handlePickerChange}
                          initialValue={invoice_year_of_competence.value}
                        />
                      </FieldShell>
                    </div>
                    <div className={classNames('col-12 col-md-4 py-2')}>
                      <FieldShell fieldName="invoice_total_amount" withBorder>
                        <ECTextFieldComponent
                          initialValue={invoice_total_amount.value}
                          field={invoice_total_amount}
                          handleValueChanged={this.handleFieldChanged}
                        />
                      </FieldShell>
                    </div>
                    <div className={classNames('col-12 col-md-4 py-2')}>
                      <FieldShell fieldName="invoice_costs" withBorder>
                        <ECTextFieldComponent
                          initialValue={invoice_costs.value}
                          field={invoice_costs}
                          handleValueChanged={this.handleFieldChanged}
                        />
                      </FieldShell>
                    </div>
                    <div className={classNames('col-12 col-md-4 py-2')}>
                      <FieldShell
                        fieldName="invoice_total_amount_commission"
                        withBorder
                      >
                        <ECTextFieldComponent
                          initialValue={invoice_total_amount_commission.value}
                          field={invoice_total_amount_commission}
                          handleValueChanged={this.handleFieldChanged}
                        />
                      </FieldShell>
                    </div>
                    <div className={classNames('col-12 col-md-4 py-2')}>
                      <FieldShell fieldName="invoice_net" withBorder>
                        <div>{invoice_net.value}</div>
                      </FieldShell>
                    </div>
                    <div className={classNames('col-12 col-md-4 py-2')}>
                      {invoice_file_total.value > 0 && (
                        <FieldShell fieldName="invoice_file_total" withBorder>
                          <ECTextFieldComponent
                            initialValue={invoice_file_total.value}
                            field={invoice_file_total}
                            handleValueChanged={null}
                          />
                        </FieldShell>
                      )}
                    </div>
                    <div className={classNames('col-12 col-md-4 py-2')}>
                      {invoice_technical_change.value > 0 && (
                        <FieldShell
                          fieldName="invoice_technical_change"
                          withBorder
                        >
                          <div>{invoice_technical_change.value}</div>
                        </FieldShell>
                      )}
                    </div>
                    <div className={classNames('col-12 col-md-3 py-2')}>
                      <FieldShell
                        fieldName="invoice_description_month"
                        withBorder
                      >
                        <ECSelectFieldComponent
                          field={invoice_description_month_field}
                          handleValueChanged={this.handlePickerChange}
                          initialValue={invoice_description_month.value}
                        />
                      </FieldShell>
                    </div>
                    <div className={classNames('col-12 col-md-9 py-2')}>
                      <FieldShell fieldName="invoice_description" withBorder>
                        <ECTextFieldComponent
                          initialValue={invoice_description.value}
                          field={invoice_description}
                          handleValueChanged={this.handleFieldChanged}
                        />
                      </FieldShell>
                    </div>
                    <div className={classNames('col-12 col-md-2 pb-2 pt-2')} />
                    <div className={classNames('col-12 col-md-8 pb-2 pt-2')}>
                      <JujoButtonComponent
                        bstyle={8}
                        bwidth="400px"
                        blabel="Salva dati di fatturazione ed applica eventuale cambio tecnico"
                        bDisabled={
                          !(
                            invoice_technical_change.value !== '' &&
                            parseFloat(invoice_technical_change.value) >= 0 &&
                            invoice_number.value !== '' &&
                            invoice_year_of_competence.value !== '' &&
                            invoice_date.value !== ''
                          )
                        }
                        handleClick={() => {
                          this.handleApplyTechnicalChange()
                        }}
                      />
                    </div>
                    <div className={classNames('col-12 col-md-2 pb-2 pt-2')} />
                  </>
                )}
              </div>
            )}

            {invoiced === true && (
              <div
                className={classNames(
                  'mt-5 w-100 d-flex align-items-center justify-content-center'
                )}
              >
                <JujoButtonComponent
                  bstyle={3}
                  bwidth="200px"
                  blabel="Avanti"
                  handleClick={() => {
                    this.handleNextStep()
                  }}
                />
              </div>
            )}
          </div>
        )}
      </>
    )
  }
}
export default connect(
  mapStateToProps,
  actionCreators
)(OssigenoWizardStepInvoicing)
