import classNames from 'classnames'
import React from 'react'
import { connect } from 'react-redux'

import * as actionCreators from '../../store/actions'
import { mapStateToProps, translate } from '../../utils'

export class JujoLoading extends React.Component {
  componentWillUnmount() {
    // Fix Warning: Can't perform a React state update on an unmounted component
    this.setState = () => {}
  }

  render() {
    return (
      <div
        className={classNames(
          'd-flex justify-content-center align-items-center'
        )}
        style={{ width: '100%', height: '100%' }}
      >
        {translate('loading')}
        <div
          className={classNames('loading-icon theme-svg ms-1')}
          style={{
            backgroundPosition: 'center',
            backgroundSize: '30px 30px',
            backgroundRepeat: 'no-repeat',
            height: '30px',
            width: '30px',
          }}
        />
      </div>
    )
  }
}

export default connect(mapStateToProps, actionCreators)(JujoLoading)
