/* eslint-disable react/no-unused-state */
import React from 'react'
import { connect } from 'react-redux'

import loadable from '@loadable/component'
import classNames from 'classnames'

import * as actionCreators from '../../../../store/actions'
import { mapStateToProps } from '../../../../utils'

const JujoLoading = loadable(() => import('../../../loading'))
const ECTextFieldComponent = loadable(() =>
  import('../../../common/fields/textField')
)

export class JujoSVGGenericSingleElementComponent extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      loading: true,
      g_element: null,
      generic_element: null,
      edit_label: false,
    }
  }

  componentWillUnmount() {
    // Fix Warning: Can't perform a React state update on an unmounted component
    this.setState = () => {}
  }

  componentDidMount = async () => {
    const { element_key } = this.props
    const g_element = document.getElementById(element_key)
    await this.setState({ g_element })

    const generic_element = this.retrieveGenericElement()

    this.setState({ generic_element, loading: false })
  }

  retrieveGenericElement = () => {
    console.log(
      'this feature specializes based on the component that extends this object. Please implement this method according to your needs!'
    )
    return null
  }

  handleLabelChanged = async (_fieldName, value) => {
    const { g_element } = this.state
    g_element.setAttribute('label', value)
  }

  handleEditLabelClicked = () => {
    const { edit_label } = this.state
    this.setState({ edit_label: !edit_label })
  }

  handleDuplicate = () => {
    const { element_key, duplicateElement } = this.props
    duplicateElement(element_key)
  }

  handleDelete = () => {
    const { deleteElement, element_key } = this.props
    deleteElement(element_key)
  }

  handleMoveUp = () => {
    const { element_key, moveElement } = this.props
    moveElement(element_key, 'up')
  }

  handleMoveDown = () => {
    const { element_key, moveElement } = this.props
    moveElement(element_key, 'down')
  }

  drawGenericBlock = () => {
    const { g_element, edit_label } = this.state
    const { element_key } = this.props

    const label = g_element.getAttribute('label')

    const html = []

    html.push(
      <div key={element_key} className={classNames('mb-3 pb-3 border-bottom')}>
        <div className={classNames('d-flex flex-column')}>
          <div
            className={classNames(
              'd-flex flex-column-reverse flex-md-row align-items-center mb-2 mb-md-1 justify-content-md-between'
            )}
          >
            <div
              className={classNames('d-flex flex-row align-items-center w-100')}
            >
              <div
                className={classNames('ffamily-secondary fc-gray-500 w-fill')}
              >
                {edit_label && (
                  <div className={classNames('bg-gray-100 px-1')}>
                    <ECTextFieldComponent
                      initialValue={label}
                      field={{ name: `${element_key}_label` }}
                      handleValueChanged={this.handleLabelChanged}
                    />
                  </div>
                )}
                {!edit_label && <span>{label}</span>}
              </div>
            </div>
            <div
              className={classNames('d-flex mb-1 mb-md-unset ms-unset ms-md-2')}
            >
              <div
                className={classNames(
                  'background-image theme-svg',
                  edit_label ? 'save-icon' : 'edit-icon'
                )}
                style={{ width: 18, height: 18 }}
                role="button"
                tabIndex={0}
                label="edit_label_button"
                onClick={() => {
                  this.handleEditLabelClicked()
                }}
                onKeyPress={() => {
                  this.handleEditLabelClicked()
                }}
              />
              <div
                className={classNames(
                  'background-image theme-svg ms-2 duplicate-icon'
                )}
                style={{ width: 18, height: 18 }}
                role="button"
                tabIndex={0}
                label="duplicate_button"
                onClick={() => {
                  this.handleDuplicate()
                }}
                onKeyPress={() => {
                  this.handleDuplicate()
                }}
              />
              <div
                className={classNames(
                  'background-image theme-svg move-up-icon ms-2'
                )}
                style={{ width: 18, height: 18 }}
                role="button"
                tabIndex={0}
                label="delete_button"
                onClick={() => {
                  this.handleMoveUp()
                }}
                onKeyPress={() => {
                  this.handleMoveUp()
                }}
              />
              <div
                className={classNames(
                  'background-image theme-svg move-down-icon ms-2'
                )}
                style={{ width: 18, height: 18 }}
                role="button"
                tabIndex={0}
                label="delete_button"
                onClick={() => {
                  this.handleMoveDown()
                }}
                onKeyPress={() => {
                  this.handleMoveDown()
                }}
              />
              <div
                className={classNames(
                  'background-image theme-svg trash-icon ms-2'
                )}
                style={{ width: 18, height: 18 }}
                role="button"
                tabIndex={0}
                label="delete_button"
                onClick={() => {
                  this.handleDelete()
                }}
                onKeyPress={() => {
                  this.handleDelete()
                }}
              />
            </div>
          </div>
          {this.drawEditElement()}
        </div>
      </div>
    )

    return html
  }

  drawEditElement = () => {
    const html = []
    html.push(<div key="not_expandable_part">Not Expandable Part</div>)
    return html
  }

  render() {
    const { loading } = this.state
    return (
      <>
        {loading === true && <JujoLoading />}
        {loading === false && (
          <div className={classNames('')}>{this.drawGenericBlock()}</div>
        )}
      </>
    )
  }
}

export default connect(
  mapStateToProps,
  actionCreators
)(JujoSVGGenericSingleElementComponent)
