export function deleteRectBorder() {
  const existing_border = document.getElementById('rect_border')
  if (existing_border) existing_border.remove()

  const existing_line = document.getElementById('center_reference_line')
  if (existing_line) existing_line.remove()
}

export function getRectCoordinates(element) {
  const { nodeName } = element

  const bbox = element.getBBox()
  const { x, y, width, height } = bbox

  let matrix = null
  if (nodeName === 'image') {
    const transform = element.transform.baseVal

    matrix = transform[0].matrix
  }

  return {
    x,
    y,
    width,
    height,
    matrix,
  }
}

export function createRectBorder(element) {
  deleteRectBorder()

  const { x, y, width, height, matrix } = getRectCoordinates(element)
  const rect_element = document.createElementNS(
    'http://www.w3.org/2000/svg',
    'rect'
  )

  rect_element.setAttribute('id', 'rect_border')

  if (matrix) {
    rect_element.setAttribute(
      'transform',
      `matrix(${matrix.a},${matrix.b},${matrix.c},${matrix.d},${matrix.e},${matrix.f})`
    )
  } else {
    rect_element.setAttribute('x', x)
    rect_element.setAttribute('y', y)
  }

  rect_element.setAttribute('width', width)
  rect_element.setAttribute('height', height)

  rect_element.setAttribute('fill', 'none')
  rect_element.setAttribute('stroke', '#009fe3')
  rect_element.setAttribute('stroke-width', '1px')
  rect_element.setAttribute('stroke-dasharray', '5')
  rect_element.setAttribute('stroke-dashoffset', '0')

  element.parentNode.appendChild(rect_element)

  const line_element = document.createElementNS(
    'http://www.w3.org/2000/svg',
    'line'
  )

  let element_x = x
  let element_y = y
  if (matrix) {
    element_x = matrix.e // Coordinate X
    element_y = matrix.f // Coordinate Y
  }

  line_element.setAttribute('id', 'center_reference_line')

  const line_x1 = element_x + width / 2
  const line_y1 = element_y - 5
  const line_x2 = element_x + width / 2
  const line_y2 = element_y

  line_element.setAttribute('x1', line_x1)
  line_element.setAttribute('y1', line_y1)
  line_element.setAttribute('x2', line_x2)
  line_element.setAttribute('y2', line_y2)

  line_element.setAttribute('stroke', '#009fe3')
  line_element.setAttribute('stroke-width', '1px')

  element.parentNode.appendChild(line_element)
}

export function updatedRectBorder(element) {
  const rect_border_element =
    element.parentNode.querySelector('rect#rect_border')
  if (rect_border_element) {
    const { x, y, width, height, matrix } = getRectCoordinates(element)

    if (matrix) {
      rect_border_element.setAttribute(
        'transform',
        `matrix(${matrix.a},${matrix.b},${matrix.c},${matrix.d},${matrix.e},${matrix.f})`
      )
    } else {
      rect_border_element.setAttribute('x', x)
      rect_border_element.setAttribute('y', y)
    }

    rect_border_element.setAttribute('width', width)
    rect_border_element.setAttribute('height', height)
  }
}
