import React from 'react'
import { connect } from 'react-redux'

import FieldsComponent from './fields'

import * as apiService from '../../services/apiService'

import * as actionCreators from '../../store/actions'

import './styles.scss'
import apiCallMap from '../../enums/apiCallMap'
import requestTypesMap from '../../enums/requestTypesMap'
import apiRequestTypesMap from '../../enums/apiRequestTypesMap'
import { baseRequestObject, parseEndpoint } from '../../services/servicesHelper'
import sourcesMap from '../../enums/sourcesMap'
import {
  injectComponent,
  mapStateToProps,
  retrieveComponent,
} from '../../utils'
import { createMSBDef, getEmptyMessageBox } from '../message_box/helper'
import JujoLoading from '../loading'

const classNames = require('classnames')

export class PopupComponent extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      initialized: false,
    }
  }

  componentWillUnmount() {
    this.setState = () => {}
  }

  componentDidMount = async () => {
    const {
      dynamicForm,
      environment,
      specialization,
      updateDynamicFormIntoRedux,
      authentication,
    } = this.props
    const { entity, type, parent, source } = dynamicForm

    if (type === requestTypesMap.update || type === requestTypesMap.view) {
      const { entities } = specialization.config
      const config = entities[entity]

      const { form } = config
      const { apis } = form
      const { getData } = apis
      const {
        apiCall,
        defaultFilters,
        placeholderMapping,
        requestType,
        postData,
        relatedEntity,
      } = getData

      const requestData = baseRequestObject(
        apiCallMap[apiCall],
        relatedEntity || entity,
        requestType,
        environment,
        authentication
      )

      requestData.defaultFilters = defaultFilters || []
      requestData.placeholderMapping = placeholderMapping || []
      requestData.parentData = parent
      requestData.dynamicFields = source

      const parsedEp = parseEndpoint(requestData)

      let result = false
      if (requestType === apiRequestTypesMap.get) {
        result = await apiService.httpGet(`${process.env.apiUrl}${parsedEp}`)
      } else if (requestType === apiRequestTypesMap.post) {
        const dataToSend = {}
        for (let i = 0; i !== postData.length; i += 1) {
          const dataField = postData[i]
          const { dataSource, dataKey, dataValue } = dataField
          if (dataSource === sourcesMap.dynamicField) {
            dataToSend[dataKey] =
              dynamicForm.fields[dataValue] ||
              requestData.dynamicFields[dataValue]
          } else if (dataSource === sourcesMap.static) {
            dataToSend[dataKey] = dataValue
          }
        }

        result = await apiService.httpPost(
          `${process.env.apiUrl}${parsedEp}`,
          dataToSend
        )
      }

      if (result) {
        const { status, data } = result
        if (status === 200) {
          const updatedDForm = { ...dynamicForm }
          const { data: entityData } = data
          const entitySource = entityData[0]
          updatedDForm.source = entitySource
          await updateDynamicFormIntoRedux(updatedDForm)
          this.setState({ initialized: true })
        }
      }
    } else {
      this.setState({ initialized: true })
    }
  }

  handleSaveClicked = async () => {
    const { dynamicForm, environment, specialization, authentication } =
      this.props
    const { entity, type, fields, parent, callback } = dynamicForm

    const { entities } = specialization.config
    const config = entities[entity]

    const { form } = config

    const { apis } = form
    let dynamicAction = null
    if (type === requestTypesMap.add) {
      dynamicAction = apis.createData
    } else if (type === requestTypesMap.update) {
      dynamicAction = apis.updateData
    }

    const {
      apiCall,
      requestType,
      defaultFilters,
      placeholderMapping,
      excludeFields,
      relatedEntity,
    } = dynamicAction

    const fieldsToSend = { ...fields }
    if (excludeFields) {
      for (let i = 0; i !== excludeFields.length; i += 1) {
        const fKey = excludeFields[i]
        if (fKey.endsWith('*')) {
          const fKeyList = Object.keys(fieldsToSend)
          for (let j = fKeyList.length - 1; j >= 0; j -= 1) {
            const keyToVerify = fKeyList[j]
            if (keyToVerify.startsWith(fKey.slice(0, -1))) {
              delete fieldsToSend[keyToVerify]
            }
          }
        } else {
          delete fieldsToSend[fKey]
        }
      }
    }

    const requestData = baseRequestObject(
      apiCallMap[apiCall],
      relatedEntity || entity,
      requestType,
      environment,
      authentication
    )

    requestData.defaultFilters = defaultFilters || []
    requestData.placeholderMapping = placeholderMapping || []
    requestData.parentData = parent
    requestData.dynamicFields = fields

    const parsedEp = parseEndpoint(requestData)
    let response = {}

    if (requestType === apiRequestTypesMap.post) {
      response = await apiService.httpPost(
        `${process.env.apiUrl}${parsedEp}`,
        fieldsToSend
      )
    } else if (requestType === apiRequestTypesMap.put) {
      response = await apiService.httpUpdate(
        `${process.env.apiUrl}${parsedEp}`,
        fieldsToSend
      )
    }

    const { status, data } = response

    if (status === 200 || status === 201) {
      const { actions } = specialization
      const { onSave } = callback
      const { action, params } = onSave

      if (action !== null) {
        actions[action](params)
      }

      this.handleClosePopup()
    } else if (status === 400) {
      this.notifyResponseError(response)
      this.parseResponseFieldsErrors(data.fields)
    } else {
      this.notifyResponseError(response)
    }
  }

  handleClosePopup = async () => {
    const { resetDynamicFormIntoRedux } = this.props
    await resetDynamicFormIntoRedux()
  }

  closeMessageBox = params => {
    const { msbox_instance_id } = params

    retrieveComponent(this, msbox_instance_id)
  }

  notifyResponseError = response => {
    const { specialization, environment } = this.props

    const { data } = response

    const { translations } = specialization
    const { texts } = translations
    const { locale } = environment

    const messageBox = getEmptyMessageBox()

    messageBox.icon = 'warning-icon-color'
    messageBox.title = texts[locale].warning

    let { message } = data

    if (data.fields) {
      message += '<div class="fs-7">'
      const kList = Object.keys(data.fields)
      for (let i = 0; i !== kList.length; i += 1) {
        const k = kList[i]
        const KMsgList = data.fields[k]
        const msg = KMsgList[0]
        message += `<div>${msg}</div>`
      }
      message += '</div>'
    }

    messageBox.message = message

    const ok_btn = createMSBDef(
      texts[locale].ok,
      this.closeMessageBox,
      {
        msbox_instance_id: messageBox.id,
      },
      true
    )
    messageBox.buttons.push(ok_btn)

    const component_definition = {
      specialized: false,
      path: 'message_box',
      data: {
        messageBox,
      },
    }

    injectComponent(this, messageBox.id, component_definition)
  }

  parseResponseFieldsErrors = fields => {
    const { dynamicForm, updateDynamicFormIntoRedux } = this.props
    const duplicatedDForm = { ...dynamicForm }

    const errorList = {}
    if (fields) {
      const keys = Object.keys(fields)

      for (let i = 0; i !== keys.length; i += 1) {
        const fieldKey = keys[i]
        const errorsPerField = fields[fieldKey]

        const list = []

        for (let j = 0; j !== errorsPerField.length; j += 1) {
          const errorMessage = errorsPerField[j]
          list.push(errorMessage)
        }

        errorList[fieldKey] = list
      }
    }

    duplicatedDForm.errors = errorList
    updateDynamicFormIntoRedux(duplicatedDForm)
  }

  rendereSaveCloseButton = () => {
    const { dynamicForm, environment, specialization } = this.props
    const { translations } = specialization
    const { texts } = translations
    const { locale } = environment
    const { type } = dynamicForm

    const html = []

    html.push(
      <div
        key="action_buttons"
        className={classNames(
          'd-flex align-items-center justify-content-between me-md-3 mx-auto'
        )}
      >
        {type !== requestTypesMap.view && (
          <div
            className={classNames(
              'btn me-3 py-2 px-4 bg-1 fc-white ffamily-secondary fst-uppercase'
            )}
            role="button"
            tabIndex={0}
            onClick={() => {
              this.handleSaveClicked()
            }}
            onKeyPress={() => {
              this.handleSaveClicked()
            }}
          >
            {texts[locale].save}
          </div>
        )}
        <div
          className={classNames(
            'btn ffamily-secondary fc-1 py-2 px-4 border rounded fst-uppercase'
          )}
          role="button"
          tabIndex={0}
          onClick={() => {
            this.handleClosePopup()
          }}
          onKeyPress={() => {
            this.handleClosePopup()
          }}
        >
          {texts[locale].close}
        </div>
      </div>
    )

    return html
  }

  render() {
    const { initialized } = this.state
    const { dynamicForm, environment, specialization } = this.props
    const { translations } = specialization
    const { texts } = translations
    const { locale } = environment
    const { type, entity } = dynamicForm

    return (
      <div className={classNames({ popup_component: true })}>
        <div className={classNames({ popup_outer: true })}>
          <div
            className={classNames(
              { popup_inner: true },
              'position-absolute d-flex flex-column'
            )}
            style={{
              width: 'min(90%, 900px)',
              left: '50%',
              transform: 'translateX(-50%)',
            }}
          >
            <div
              className={classNames(
                'd-flex justify-content-between align-items-center bg-light'
              )}
              style={{
                backdropFilter: 'blur(14px)',
                border: 'solid 0.5px rgba(0, 0, 0, 0.13)',
                borderRadius: '12px 12px 0px 0px',
                height: '70px',
              }}
            >
              <span
                className={classNames(
                  'd-none d-md-block fs-4 ffamily-secondary ps-3'
                )}
              >
                <span>{texts[locale][type] || type}</span>
                <span> </span>
                <span>{texts[locale][entity] || entity}</span>
              </span>
              {this.rendereSaveCloseButton()}
            </div>
            <div className={classNames({ popup_body: true })}>
              {initialized ? <FieldsComponent /> : <JujoLoading />}
            </div>
            {type !== requestTypesMap.view && (
              <div
                className={classNames(
                  'position-absolute bottom-0 bg-light d-flex align-items-center justify-content-center justify-content-md-end'
                )}
                style={{ width: '100%', height: '100px' }}
              >
                {this.rendereSaveCloseButton()}
              </div>
            )}
          </div>
        </div>
      </div>
    )
  }
}

export default connect(mapStateToProps, actionCreators)(PopupComponent)
