import React from 'react'
import { connect } from 'react-redux'

import * as actionCreators from '../../store/actions'
import { mapStateToProps } from '../../utils'
import './styles.scss'

const classNames = require('classnames')

export class LangSwitcherComponent extends React.Component {
  handleLanguageSelected = lang => {
    const { updateLocaleIntoStorageAndRedux } = this.props
    updateLocaleIntoStorageAndRedux(lang)
  }

  render() {
    const { environment } = this.props
    const { locale } = environment

    const languages = process.env.languages.split(',')

    return (
      <div
        className={classNames('row fs-7')}
        style={{ maxWidth: '70px', margin: 'auto' }}
      >
        <div
          className={classNames('col py-0 px-2 lang-icon white-svg')}
          style={{
            width: '40px',
            height: '30px',
            backgroundSize: '30px',
            backgroundPosition: 'center',
            backgroundRepeat: 'no-repeat',
          }}
        />
        <select
          defaultValue={locale}
          className={classNames(
            'col py-0 px-2 font-family-secondary fc-white fst-uppercase'
          )}
          style={{
            appearance: 'none',
            width: 'fit-content',
            textAlignLast: 'center',
          }}
          onChange={e => {
            this.handleLanguageSelected(e.target.value)
          }}
        >
          {languages.map(lang => (
            <option key={lang} className={classNames('fc-black')} value={lang}>
              {lang}
            </option>
          ))}
        </select>
      </div>
    )
  }
}

export default connect(mapStateToProps, actionCreators)(LangSwitcherComponent)
