import React from 'react'
import classNames from 'classnames'
import { translate } from '../../../../utils'

function JujoSVGSavingMessage() {
  return (
    <div
      className={classNames(
        'position-fixed bg-gray-200 w-100 h-100 top-0 start-0'
      )}
      style={{ zIndex: 99999 }}
    >
      <div
        className={classNames(
          'bg-white position-fixed top-50 start-50 translate-middle zindex-2 rounded-30 shadow'
        )}
      >
        <div
          className={classNames(
            'd-flex flex-column align-items-center text-center p-3'
          )}
        >
          <div className={classNames('fs-2')}>{translate('saving_title')}</div>
          <div className={classNames('fs-5')}>
            {translate('saving_please_wait_message')}
          </div>
          <div
            className={classNames(
              'background-image loading-icon theme-svg mt-2'
            )}
            style={{
              height: 30,
              width: 30,
            }}
          />
        </div>
      </div>
    </div>
  )
}

export default JujoSVGSavingMessage
