import React from 'react'
import { connect } from 'react-redux'

import * as actionCreators from '../../../store/actions'
import { mapStateToProps } from '../../../utils'

export class ECEmailFieldComponent extends React.Component {
  componentWillUnmount() {
    // Fix Warning: Can't perform a React state update on an unmounted component
    this.setState = () => {}
  }

  render() {
    const { field, initialValue, handleValueChanged } = this.props
    const { name, disabled } = field
    const { uppercase, lowercase } = field || false
    let text_transform = uppercase ? 'uppercase' : ''
    text_transform = lowercase ? 'lowercase' : text_transform
    return (
      <input
        type="email"
        defaultValue={initialValue}
        disabled={disabled}
        onChange={e => {
          let { value } = e.target
          value = uppercase ? value.toUpperCase() : value
          value = lowercase ? value.toLowerCase() : value
          handleValueChanged(name, value)
        }}
        style={{ textTransform: text_transform }}
      />
    )
  }
}

export default connect(mapStateToProps, actionCreators)(ECEmailFieldComponent)
