export function updateInfoBox(info_box_obj) {
  const info_box = { ...info_box_obj }
  info_box.visible = true
  return async dispatch => {
    dispatch({
      type: 'UPDATE_INFO_BOX',
      info_box,
    })
  }
}

export function resetInfoBox() {
  return async dispatch => {
    dispatch({
      type: 'RESET_INFO_BOX',
    })
  }
}
