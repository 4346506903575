import React, { useState, useEffect, useRef } from 'react'
import classNames from 'classnames'
import loadable from '@loadable/component'
import { updatedRectBorder } from '../../../../../utils/svg/rect_border'
import { translate } from '../../../../../utils'
import { mqMobileOrTablet } from '../../../../../utils/mediaquery'

const JujoLoading = loadable(() => import('../../../../loading'))

function JujoSVGInteractiveText({ element_key }) {
  const [text_element, setTextElement] = useState(null)
  const [mask, setMaks] = useState(null)
  const [isMobileOrTablet] = useState(mqMobileOrTablet())

  const textareaRef = useRef(null)

  const initData = async () => {
    const group_element = document.getElementById(element_key)
    const t_element = group_element.querySelector('text')

    setTextElement(t_element)
  }

  useEffect(() => {
    const execInitData = async () => {
      await initData()
    }

    execInitData()
  }, [])

  const moveStyle = (from, to) => {
    const node_style = from.getAttribute('style')
    to.setAttribute('style', node_style)
    from.removeAttribute('style')
  }

  const getTextValue = () => {
    const value = Array.from(
      text_element.childNodes,
      ({ textContent }) => textContent
    ).join('\n')
    return value
  }

  const execAddNode = e => {
    const { target } = e
    const { value: new_text, selectionStart } = target

    const rows = new_text.split('\n')

    const modified_index = selectionStart - 1

    const row_to_modify_index =
      new_text.substring(0, modified_index).split('\n').length - 1

    let prev_rows_lenght = 0
    for (let i = 0; i !== row_to_modify_index; i += 1) {
      prev_rows_lenght += rows[i].length
    }

    const new_char = new_text[modified_index]

    const rendered_rows = text_element.childNodes
    if (rendered_rows.length === 0) {
      const new_row = document.createElementNS(
        'http://www.w3.org/2000/svg',
        'tspan'
      )
      new_row.setAttribute('x', text_element.getAttribute('x'))
      new_row.setAttribute('dy', 0)

      const new_node = document.createElementNS(
        'http://www.w3.org/2000/svg',
        'tspan'
      )
      new_node.textContent = new_char
      moveStyle(text_element, new_node)

      new_row.appendChild(new_node)
      text_element.appendChild(new_row)
    } else {
      const row_to_modifiy = rendered_rows[row_to_modify_index]

      const nodes = row_to_modifiy.childNodes
      const cell_index = modified_index - prev_rows_lenght - row_to_modify_index

      const node_to_clone_index = cell_index === 0 ? cell_index : cell_index - 1
      let node_to_clone = nodes[node_to_clone_index]

      if (node_to_clone === undefined) {
        const { previousElementSibling } = row_to_modifiy
        if (previousElementSibling) {
          node_to_clone = previousElementSibling.lastChild
        }
      }

      if (node_to_clone) {
        const new_node = node_to_clone.cloneNode()
        new_node.textContent = new_char

        if (row_to_modifiy.childElementCount === 0) {
          row_to_modifiy.appendChild(new_node)
        } else {
          row_to_modifiy.insertBefore(
            new_node,
            cell_index === 0 ? node_to_clone : node_to_clone.nextSibling
          )
        }
      } else {
        const new_node = document.createElementNS(
          'http://www.w3.org/2000/svg',
          'tspan'
        )

        moveStyle(row_to_modifiy, new_node)

        new_node.textContent = new_char
        row_to_modifiy.appendChild(new_node)
      }
    }
  }

  const execEnter = e => {
    const { target } = e
    const { value: new_text, selectionStart } = target

    const rows = new_text.split('\n')

    const modified_index = selectionStart - 1

    const row_to_modify_index =
      new_text.substring(0, modified_index).split('\n').length - 1

    let prev_rows_lenght = 0
    for (let i = 0; i !== row_to_modify_index; i += 1) {
      prev_rows_lenght += rows[i].length
    }

    const row_to_modifiy = text_element.childNodes[row_to_modify_index]

    if (row_to_modifiy) {
      const nodes = row_to_modifiy.childNodes
      const cell_index = modified_index - prev_rows_lenght - row_to_modify_index

      const nodes_to_move = []
      for (let i = cell_index; i !== nodes.length; i += 1) {
        const node = nodes[i]
        nodes_to_move.push(node)
      }

      const new_row = row_to_modifiy.cloneNode()
      const dy = parseFloat(new_row.getAttribute('dy'))
      if (dy <= 0) new_row.setAttribute('dy', 20)

      for (let i = 0; i !== nodes_to_move.length; i += 1) {
        const node = nodes_to_move[i]
        new_row.appendChild(node)
      }

      text_element.insertBefore(new_row, row_to_modifiy.nextSibling)
    }
  }

  const execSingleDelete = e => {
    const { target } = e
    const { value: new_text, selectionStart } = target

    const rows = new_text.split('\n')
    const row_to_modify_index =
      new_text.substring(0, selectionStart).split('\n').length - 1

    let prev_rows_lenght = 0
    for (let i = 0; i !== row_to_modify_index; i += 1) {
      prev_rows_lenght += rows[i].length
    }

    const row_to_modifiy = text_element.childNodes[row_to_modify_index]

    const nodes = row_to_modifiy.childNodes
    const cell_index =
      selectionStart - 1 - prev_rows_lenght - row_to_modify_index

    const node_to_remove = nodes[cell_index + 1]
    if (node_to_remove) {
      if (text_element.childNodes.length === 1 && nodes.length === 1) {
        moveStyle(node_to_remove, row_to_modifiy)
      }
      node_to_remove.remove()
    } else {
      const next_row = row_to_modifiy.nextSibling
      if (next_row) {
        while (next_row.childNodes.length > 0) {
          row_to_modifiy.appendChild(next_row.childNodes[0])
        }
        next_row.remove()
      }
    }
  }

  const afterMultipleDelete = e => {
    const { target } = e
    const { value: new_text, textLength } = target

    const { childElementCount, textContent } = text_element
    const rows = new_text.split('\n')

    if (rows.length > childElementCount) {
      execEnter(e)
    }

    const chars_difference = textLength - textContent.length - (rows.length - 1)
    if (chars_difference > 0) {
      execAddNode(e)
    }
  }

  const execMultipleDelete = e => {
    const { target } = e

    const selStart = parseInt(target.getAttribute('selStart'), 10)
    const selEnd = parseInt(target.getAttribute('selEnd'), 10)

    const firstSelectedRowIdx = parseInt(
      target.getAttribute('firstSelectedRowIdx'),
      10
    )
    const lastSelectedRowIdx = parseInt(
      target.getAttribute('lastSelectedRowIdx'),
      10
    )

    const delFrom = selStart - firstSelectedRowIdx
    const delTo = selEnd - lastSelectedRowIdx - 1

    const rows_to_remove = []

    let first_selected_row = null
    let last_selected_row = null
    let first_middle_cut = false
    let last_middle_cut = false
    const rendered_rows = text_element.childNodes

    let incremental_count = 0
    for (let i = 0; i !== rendered_rows.length; i += 1) {
      const row = rendered_rows[i]
      const { childElementCount, childNodes } = row

      const start_idx = incremental_count
      const end_idx = start_idx + childElementCount - 1

      incremental_count += childElementCount

      if (end_idx < delFrom || start_idx > delTo) {
        continue
      }

      const delete_from = start_idx > delFrom ? start_idx : delFrom
      const delete_to = end_idx < delTo ? end_idx : delTo

      const relative_from = delete_from - start_idx
      const relative_to = delete_to - start_idx

      if (firstSelectedRowIdx !== lastSelectedRowIdx) {
        if (i === firstSelectedRowIdx) {
          first_selected_row = row
          if (relative_from > 0) {
            first_middle_cut = true
          }
        }

        if (i === lastSelectedRowIdx) {
          last_selected_row = row
          if (relative_to < childElementCount - 1) {
            last_middle_cut = true
          }
        }
      }

      if (relative_from === 0 && relative_to === childElementCount - 1) {
        rows_to_remove.push(row)
      }

      for (let j = relative_to; j >= relative_from; j -= 1) {
        const node = childNodes[j]

        if (childNodes.length === 1) {
          moveStyle(node, row)
        }

        node.remove()
      }
    }

    for (let i = 0; i !== rows_to_remove.length; i += 1) {
      const row = rows_to_remove[i]
      moveStyle(row, text_element)
      row.remove()
    }

    const rendered_characters = text_element.querySelectorAll('tspan>tspan')
    if (rendered_characters.length > 0) {
      text_element.removeAttribute('style')
    }

    if (first_middle_cut && last_middle_cut) {
      first_selected_row.append(...last_selected_row.childNodes)
      last_selected_row.remove()
    }

    afterMultipleDelete(e)
  }

  const updateMask = e => {
    const { target } = e
    const { value: text } = target

    const { clientWidth, clientHeight, scrollTop } = textareaRef.current

    const selstart = target.getAttribute('selstart')
    const selend = target.getAttribute('selend')

    const innerHtml = []
    for (let i = 0; i !== text.length; i += 1) {
      const char = text[i]

      if (char === '\n') {
        innerHtml.push(<br key={`new_line_${i}`} />)
      } else if (i >= selstart && i <= selend - 1 && selstart !== selend) {
        innerHtml.push(
          <span key={`char_${i}`} className={classNames('bg-yellow-500')}>
            {char}
          </span>
        )
      } else {
        innerHtml.push(<span key={`char_${i}`}>{char}</span>)
      }
    }

    const outerHtml = []
    outerHtml.push(
      <div
        className={classNames('position-absolute p-2')}
        key={`mask_${element_key}`}
        style={{
          width: clientWidth,
          height: clientHeight,
          wordBreak: 'break-word',
          overflow: 'hidden',
          color: 'transparent',
        }}
      >
        <div style={{ marginTop: `-${scrollTop}px` }}>{innerHtml}</div>
      </div>
    )

    setMaks(outerHtml)
  }

  const handleValueChanged = e => {
    const { target } = e
    const { value: new_text } = target

    const rendered_rows = text_element.childNodes
    const rendered_rows_count = rendered_rows.length
    const rendered_content_lenght = text_element.textContent.length

    const rows = new_text.split('\n')
    const length_difference = rows.join('').length - rendered_content_lenght

    let key_action = ''
    if (rows.length > rendered_rows_count && rendered_rows_count > 0) {
      key_action = 'Enter'
    }
    if (rows.length < rendered_rows_count) {
      key_action = 'Delete'
    }
    if (length_difference < 0) {
      key_action = 'Delete'
    }

    if (key_action === 'Delete') {
      if (length_difference >= -1) {
        execSingleDelete(e)
      } else if (length_difference < -1) {
        execMultipleDelete(e)
      }
    } else if (key_action === 'Enter') {
      execEnter(e)
    } else {
      execAddNode(e)
    }

    updatedRectBorder(text_element)
  }

  const renderEditor = () => {
    const html = []

    if (isMobileOrTablet) {
      html.push(
        <div key="m_interactive_text_editor">
          <div className={classNames('bg-white')}>
            {mask && <>{mask}</>}
            <textarea
              ref={textareaRef}
              key={element_key}
              name={element_key}
              className={classNames('no-highlight position-relative  p-2')}
              style={{ background: 'transparent', resize: 'auto' }}
              placeholder=""
              rows={3}
              defaultValue={getTextValue()}
              onKeyUp={e => {
                updateMask(e)
              }}
              onMouseUp={e => {
                updateMask(e)
              }}
              onScroll={e => {
                updateMask(e)
              }}
              onChangeCapture={e => {
                handleValueChanged(e)
              }}
            />
          </div>
        </div>
      )
    } else {
      html.push(
        <div key="interactive_text_editor">
          <div
            className={classNames('fw-bold ffamily-secondary fst-uppercase')}
          >
            <div>{translate('edit_your_text_here')}</div>
          </div>
          <div className={classNames('position-relative')}>
            <div className={classNames('bg-light-gray mt-1 mb-2')}>
              {mask && <>{mask}</>}
              <textarea
                ref={textareaRef}
                key={element_key}
                name={element_key}
                className={classNames('no-highlight position-relative  p-2')}
                style={{ background: 'transparent', resize: 'auto' }}
                placeholder=""
                rows={6}
                defaultValue={getTextValue()}
                onKeyUp={e => {
                  updateMask(e)
                }}
                onMouseUp={e => {
                  updateMask(e)
                }}
                onScroll={e => {
                  updateMask(e)
                }}
                onChangeCapture={e => {
                  handleValueChanged(e)
                }}
              />
            </div>
          </div>
        </div>
      )
    }

    return html
  }

  return (
    <>
      {text_element === null && <JujoLoading />}
      {text_element !== null && renderEditor()}
    </>
  )
}

export default JujoSVGInteractiveText
