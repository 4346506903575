import React, { useState, useEffect, useRef } from 'react'
import classNames from 'classnames'
import loadable from '@loadable/component'
import { translate } from '../../../../../utils'
import { updatedRectBorder } from '../../../../../utils/svg/rect_border'

const JujoLoading = loadable(() => import('../../../../loading'))
const JujoSVGNumberSelector = loadable(() => import('../number_selector'))

function ImageSizeManager({ element_key }) {
  const [image_element, setImageElement] = useState(null)

  const initData = async () => {
    const g_element = document.getElementById(element_key)
    const img_el = g_element.getElementsByTagName('image')[0]

    setImageElement(img_el)
  }

  useEffect(() => {
    const execInitData = async () => {
      await initData()
    }

    execInitData()
  }, [])

  const getWidth = () => {
    const width = parseFloat(image_element.getAttribute('width')).toFixed(2)
    return width
  }

  const getHeight = () => {
    const height = parseFloat(image_element.getAttribute('height')).toFixed(2)
    return height
  }

  const handleWidthChanged = new_width => {
    const number = parseFloat(new_width)

    // eslint-disable-next-line no-restricted-globals
    if (isNaN(number)) return
    if (number <= 0) return

    const current_width = getWidth()
    const current_height = getHeight()

    const new_height = parseFloat(
      (current_height / current_width) * new_width
    ).toFixed(2)

    image_element.setAttribute('width', new_width)
    image_element.setAttribute('height', new_height)

    document
      .getElementById(`${element_key}_height`)
      .querySelector('input[type=number]').value = new_height

    updatedRectBorder(image_element)
  }

  const handleHeightChanged = new_height => {
    const number = parseFloat(new_height)

    // eslint-disable-next-line no-restricted-globals
    if (isNaN(number)) return
    if (number <= 0) return

    const current_width = getWidth()
    const current_height = getHeight()

    const new_width = parseFloat(
      (current_width / current_height) * new_height
    ).toFixed(2)

    image_element.setAttribute('width', new_width)
    image_element.setAttribute('height', new_height)

    document
      .getElementById(`${element_key}_width`)
      .querySelector('input[type=number]').value = new_width

    updatedRectBorder(image_element)
  }

  return (
    <>
      {image_element === null && <JujoLoading />}
      {image_element !== null && (
        <div className={classNames('d-flex flex-column flex-md-row mb-md-2')}>
          <div className={classNames('me-md-3 mb-2 mb-md-0')}>
            <div
              className={classNames('fw-bold ffamily-secondary fst-uppercase')}
            >
              <div>{translate('width')}</div>
            </div>
            <div id={`${element_key}_width`}>
              <JujoSVGNumberSelector
                handleChange={handleWidthChanged}
                defaultValue={getWidth()}
              />
            </div>
          </div>
          <div className={classNames('')}>
            <div
              className={classNames('fw-bold ffamily-secondary fst-uppercase')}
            >
              <div>{translate('height')}</div>
            </div>
            <div id={`${element_key}_height`}>
              <JujoSVGNumberSelector
                handleChange={handleHeightChanged}
                defaultValue={getHeight()}
              />
            </div>
          </div>
        </div>
      )}
    </>
  )
}

export default ImageSizeManager
