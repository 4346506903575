import React, { useState, useEffect, useRef } from 'react'
import classNames from 'classnames'
import loadable from '@loadable/component'
import { translate } from '../../../../../utils'

const JujoLoading = loadable(() => import('../../../../loading'))

function ImageRotationManager({ element_key }) {
  const rotationAngleInputRef = useRef(null)

  const [img_element, setImageElement] = useState(null)
  const [rotation_value, setRotationValue] = useState(0)

  const initData = async () => {
    const g_element = document.getElementById(element_key)
    const img_el = g_element.getElementsByTagName('image')[0]

    setImageElement(img_el)
  }

  useEffect(() => {
    const execInitData = async () => {
      await initData()
    }

    execInitData()
  }, [])

  const getRotationAngle = () => 0

  const rotateBase64Image = (src, angle, callback) => {
    const canvas = document.createElement('canvas')
    const ctx = canvas.getContext('2d')
    const image = new Image()

    image.onload = () => {
      canvas.width = image.width
      canvas.height = image.height

      ctx.translate(canvas.width / 2, canvas.height / 2)
      ctx.rotate((angle * Math.PI) / 180)

      ctx.drawImage(image, image.width / -2, image.height / -2)

      callback(canvas.toDataURL())
    }

    image.src = src
  }

  const handleRotationAngleChanged = async e => {
    const { value: new_angle } = e.target

    const difference = new_angle - rotation_value
    await setRotationValue(new_angle)

    rotateBase64Image(img_element.getAttribute('href'), difference, new_src => {
      img_element.setAttribute('href', new_src)
    })
  }

  // eslint-disable-next-line no-unused-vars
  const calulateRotationMatrix = (angle, x, y, cx, cy) => {
    const rad = (angle * Math.PI) / 180
    const c = Math.cos(rad)
    const s = Math.sin(rad)

    const xt = x - cx
    const yt = y - cy

    const xr = xt * c - yt * s
    const yr = xt * s + yt * c

    const xtb = xr + cx
    const ytb = yr + cy

    const rotationMatrix = {
      a: c,
      b: s,
      c: -s,
      d: c,
      e: xtb,
      f: ytb,
    }

    return `transform: matrix(${rotationMatrix.a}, ${rotationMatrix.b}, ${rotationMatrix.c}, ${rotationMatrix.d}, ${rotationMatrix.e}, ${rotationMatrix.f})`
  }

  return (
    <>
      {img_element === null && <JujoLoading />}
      {img_element !== null && (
        <div
          className={classNames(
            'd-flex align-items-center justify-content-between mb-2'
          )}
        >
          <div
            className={classNames('fs-7 me-2 ffamily-secondary fc-gray-500')}
          >
            {translate('rotation_angle')}
          </div>
          <div className={classNames('w-100px rounded-4 px-2 bg-white')}>
            <input
              ref={rotationAngleInputRef}
              type="number"
              //   min="0"
              max="360"
              defaultValue={getRotationAngle()}
              onChange={e => handleRotationAngleChanged(e)}
            />
          </div>
        </div>
      )}
    </>
  )
}

export default ImageRotationManager
