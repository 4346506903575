import uuid from 'react-uuid'

export function getEmptyMessageBox() {
  return {
    id: uuid(),
    icon: null,
    title: '',
    message: '',
    buttons: [],
  }
}

export function createMSBDef(text, handleFunction, params, stylePrimary) {
  const btnDefinition = {
    text,
    handleFunction,
    params,
    stylePrimary,
  }

  return btnDefinition
}
