import lsEntryMap from '../../../enums/lsEntryMap'
import { lsSet } from '../../../services/localStorage'
import initialState from '../../reducers/cart/initialState'

export function resetCart() {
  return async dispatch => {
    lsSet(lsEntryMap.cart, initialState)

    dispatch({
      type: 'UPDATE_CART',
      cart: initialState,
    })
  }
}

export function updateCart(cart) {
  return async dispatch => {
    lsSet(lsEntryMap.cart, cart || initialState)

    dispatch({
      type: 'UPDATE_CART',
      cart,
    })
  }
}
