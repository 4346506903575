export function getSelectedSvgNodes(element_key) {
  const text_editor = document.querySelector(`textarea[name=${element_key}]`)
  const text_svg = document.getElementById(element_key).querySelector('text')
  const svg_nodes = text_svg.querySelectorAll('tspan>tspan')

  if (text_editor === null) return svg_nodes

  const selStart = text_editor.getAttribute('selstart')
  const selEnd = text_editor.getAttribute('selend')
  const firstSelectedRowIdx = text_editor.getAttribute('firstselectedrowidx')
  const lastSelectedRowIdx = text_editor.getAttribute('lastselectedrowidx')

  if (selStart === selEnd) return svg_nodes

  const from = selStart - firstSelectedRowIdx
  const to = selEnd - lastSelectedRowIdx
  const filtered_nodes = Array.from(svg_nodes).slice(from, to)

  return filtered_nodes
}

export function getSelectedSvgRows(element_key) {
  const text_editor = document.querySelector(`textarea[name=${element_key}]`)
  const text_svg = document.getElementById(element_key).querySelector('text')

  const selStart = text_editor.getAttribute('selstart')
  const selEnd = text_editor.getAttribute('selend')
  const firstSelectedRowIdx = parseInt(
    text_editor.getAttribute('firstselectedrowidx'),
    10
  )
  const lastSelectedRowIdx = parseInt(
    text_editor.getAttribute('lastselectedrowidx'),
    10
  )
  const svg_nodes = text_svg.childNodes

  if (selStart === selEnd) return svg_nodes

  if (firstSelectedRowIdx === lastSelectedRowIdx)
    return [svg_nodes[firstSelectedRowIdx]]

  const filtered_nodes = []
  for (let i = firstSelectedRowIdx; i <= lastSelectedRowIdx; i += 1) {
    const row = svg_nodes[i]
    filtered_nodes.push(row)
  }

  return filtered_nodes
}
