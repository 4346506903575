import React, { useState, useEffect } from 'react'
import classNames from 'classnames'
import loadable from '@loadable/component'

const JujoSVGInteractiveText = loadable(() => import('./interactive_text'))

const JujoSVGFontFamilyManager = loadable(() => import('./ffamily_manager'))

const JujoSVGFontSizeManagerComponent = loadable(() =>
  import('./font_size_manager')
)
const JujoSVGLetterSpacingManagerComponent = loadable(() =>
  import('./letter_spacing_manager')
)
const JujoSVGLineHeightManagerComponent = loadable(() =>
  import('./line_height_manager')
)
const JujoSVGTextColorManager = loadable(() => import('./text_color_manager'))
const JujoSVGTextAlignmentManager = loadable(() =>
  import('./text_alignment_manager')
)

function JujoSVGTextPanel({ element_key, curr_nidx, fonts }) {
  const [text_element, setTextElement] = useState(null)

  const initData = async () => {
    const g_element = document.getElementById(element_key)
    if (g_element === null) return

    const text_el = g_element.querySelector('text')
    if (text_el === null) return

    setTextElement(text_el)
  }

  useEffect(() => {
    const execInitData = async () => {
      await initData()
    }

    execInitData()
  }, [element_key])

  return (
    <>
      {text_element === null && <></>}
      {text_element !== null && (
        <div key={element_key} className={classNames('d-flex flex-column')}>
          <div className={classNames('')}>
            <JujoSVGInteractiveText element_key={element_key} />
          </div>
          <div className={classNames('')}>
            <JujoSVGFontFamilyManager
              element_key={element_key}
              curr_nidx={curr_nidx}
              fonts={fonts}
            />
          </div>
          <div className={classNames('d-flex flex-column flex-md-row mb-md-2')}>
            <div className={classNames('me-md-3 mb-2 mb-md-0')}>
              <JujoSVGFontSizeManagerComponent
                element_key={element_key}
                curr_nidx={curr_nidx}
              />
            </div>
            <div className={classNames('')}>
              <JujoSVGTextAlignmentManager element_key={element_key} />
            </div>
          </div>
          <div className={classNames('d-flex flex-column flex-md-row mb-md-2')}>
            <div className={classNames('me-md-3 mb-2 mb-md-0')}>
              <JujoSVGLetterSpacingManagerComponent
                element_key={element_key}
                curr_nidx={curr_nidx}
              />
            </div>
            <div className={classNames('')}>
              <JujoSVGLineHeightManagerComponent
                element_key={element_key}
                curr_nidx={curr_nidx}
              />
            </div>
          </div>

          <div className={classNames('')}>
            <JujoSVGTextColorManager
              element_key={element_key}
              curr_nidx={curr_nidx}
            />
          </div>
        </div>
      )}
    </>
  )
}

export default JujoSVGTextPanel
