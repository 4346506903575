import React from 'react'
import { connect } from 'react-redux'
import { createMSBDef, getEmptyMessageBox } from '../message_box/helper'
import apiCallMap from '../../enums/apiCallMap'
import apiRequestTypesMap from '../../enums/apiRequestTypesMap'
import * as apiService from '../../services/apiService'

import { baseRequestObject, parseEndpoint } from '../../services/servicesHelper'

import * as actionCreators from '../../store/actions'
import { retrieveEntityValue, verifyGenericConditions } from './helper'
import {
  cloneObj,
  defaultHandleGenericBtnClick,
  injectComponent,
  mapStateToProps,
  retrieveComponent,
  slugifyString,
  translate,
} from '../../utils'

import JujoLoading from '../loading'
import JujoButtonComponent from '../jujo_button'

const classNames = require('classnames')

export class JujoGenericFormComponent extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      initialized: false,
      entityDefinition: {},
      localDataSource: {},
      fields: [],
      fieldGroups: {},
    }
  }

  componentWillUnmount() {
    // fix Warning: Can't perform a React state update on an unmounted component
    this.setState = () => {}
  }

  componentDidMount = async () => {
    const { dataSource, entityDefinition, customizations } = this.props
    const { fields } = entityDefinition
    const { fieldGroups } = customizations

    const specialized_fields = this.specializedFields(fields)

    const localDataSource = cloneObj(dataSource)

    const allFieldGroups = this.specializedFieldGroups(fieldGroups || {})
    const parsedFieldGroups = this.verifyGroupsConditions(allFieldGroups)

    await this.setState({
      initialized: true,
      localDataSource,
      entityDefinition,
      fields: specialized_fields,
      fieldGroups: parsedFieldGroups,
    })
  }

  verifyGroupsConditions = groups => {
    const parsed_groups = []
    for (let i = 0; i !== groups.length; i += 1) {
      const group = groups[i]
      const { conditions } = group
      if (conditions) {
        const condition_satisfied = verifyGenericConditions(
          this,
          conditions,
          {}
        )
        if (condition_satisfied) {
          parsed_groups.push(group)
        }
      } else {
        parsed_groups.push(group)
      }
    }
    return parsed_groups
  }

  specializedFields = () => {}

  specializedFieldGroups = () => {}

  specializedApiDef = () => {}

  specializedApiRequestType = () => {}

  checkIfFunctionHasBeenHooked = function_name => {
    const { c_def } = this.props
    const { hooks } = c_def

    if (hooks) {
      return hooks[function_name]
    }

    return undefined
  }

  handleValueChanged = async (key, value) => {
    const hooked_function =
      this.checkIfFunctionHasBeenHooked('handleValueChanged')

    if (hooked_function) {
      hooked_function(key, value)
    } else {
      const { localDataSource } = this.state

      const updatedData = cloneObj(localDataSource)
      updatedData[key] = value

      this.setState({ localDataSource: updatedData })
    }
  }

  handleSaveClicked = async () => {
    const reqObj = this.composeRequest()
    const { parsedEp, data: dataToSend } = reqObj
    const keys = Object.keys(dataToSend)

    for (let i = keys.length - 1; i >= 0; i -= 1) {
      const k = keys[i]
      if (dataToSend[k] == null) {
        delete dataToSend[k]
      }
    }

    const reqType = this.specializedApiRequestType()

    let apiRequestService = ''
    if (reqType === apiRequestTypesMap.put) {
      apiRequestService = 'httpUpdate'
    }
    if (reqType === apiRequestTypesMap.post) {
      apiRequestService = 'httpPost'
    }

    const response = await apiService[apiRequestService](
      `${process.env.apiUrl}${parsedEp}`,
      dataToSend
    )
    await this.manageResponse(response)
  }

  closeMessageBox = params => {
    const { msbox_instance_id } = params
    retrieveComponent(this, msbox_instance_id)
  }

  closeMessageAndExitEdit = async params => {
    const { handleOnComplete } = this.props
    const { msbox_instance_id } = params
    retrieveComponent(this, msbox_instance_id)
    await handleOnComplete()
  }

  handleCancelClicked = () => {
    const { handleCancel } = this.props
    handleCancel()
  }

  manageResponse = async response => {
    const { customizations } = this.props
    const { buttonsBehavior } = customizations

    const messageBox = getEmptyMessageBox()
    if (response) {
      const { status, data } = response
      const { save } = buttonsBehavior || {}
      const { onSuccessCallback, onErrorCallback } = save || {}

      if (status === 200 || status === 201) {
        let callback_function = this.closeMessageAndExitEdit

        if (onSuccessCallback && Object.keys(onSuccessCallback).length > 0) {
          const { actionName, actionPath } = onSuccessCallback
          const specialized_actions =
            await require(`../../../jujo_specializations/src/${process.env.client}/${actionPath}`)
          callback_function = specialized_actions[actionName]
        }

        messageBox.icon = 'confirm-icon-color'
        messageBox.message = translate('update_success_message')

        const continue_btn = createMSBDef(
          translate('continue_browsing'),
          callback_function,
          {
            ...this,
            msbox_instance_id: messageBox.id,
          },
          true
        )
        messageBox.buttons.push(continue_btn)
      } else {
        let callback_function = this.closeMessageBox

        if (onErrorCallback && Object.keys(onErrorCallback).length > 0) {
          const { actionName, actionPath } = onErrorCallback
          const specialized_actions =
            await require(`../../../jujo_specializations/src/${process.env.client}/${actionPath}`)
          callback_function = specialized_actions[actionName]
        }

        messageBox.icon = 'warning-icon-color'
        messageBox.title = translate('warning')

        let { message } = data

        if (data.fields) {
          message += '<div class="fs-7">'
          const kList = Object.keys(data.fields)
          for (let i = 0; i !== kList.length; i += 1) {
            const k = kList[i]
            const KMsgList = data.fields[k]
            const msg = KMsgList[0]
            message += `<div>${msg}</div>`
          }
          message += '</div>'
        }

        messageBox.message = message
        const ok_btn = createMSBDef(
          translate('ok'),
          callback_function,
          {
            ...this,
            msbox_instance_id: messageBox.id,
          },
          true
        )
        messageBox.buttons.push(ok_btn)
      }
    }

    const component_definition = {
      specialized: false,
      path: 'message_box',
      data: {
        messageBox,
      },
    }

    injectComponent(this, messageBox.id, component_definition)
  }

  composeRequest = () => {
    const { entityDefinition, localDataSource } = this.state
    const { environment, authentication } = this.props

    const entity = retrieveEntityValue(this.props)

    const { apis } = entityDefinition
    const apiData = this.specializedApiDef(apis)
    const { apiCall, requestType, placeholderMapping, excludeFields } = apiData

    const requestData = baseRequestObject(
      apiCallMap[apiCall],
      entity,
      requestType,
      environment,
      authentication
    )

    requestData.dynamicFields = localDataSource
    requestData.placeholderMapping = placeholderMapping

    const parsedEp = parseEndpoint(requestData)

    const dataToSend = cloneObj(localDataSource)
    if (excludeFields) {
      for (let i = 0; i !== excludeFields.length; i += 1) {
        const fKey = excludeFields[i]
        delete dataToSend[fKey]
      }
    }

    return {
      parsedEp,
      data: dataToSend,
    }
  }

  renderButtons = () => {
    const { customizations } = this.props
    const { buttonsBehavior } = customizations

    const cancelBtnDisabled =
      (buttonsBehavior &&
        buttonsBehavior.cancel &&
        buttonsBehavior.cancel.disabled) ||
      false
    const saveBtnDisabled =
      (buttonsBehavior &&
        buttonsBehavior.save &&
        buttonsBehavior.save.disabled) ||
      false

    const genericBtnEnabled =
      buttonsBehavior &&
      buttonsBehavior.generic &&
      buttonsBehavior.generic.length > 0

    const hasButtonsToRender =
      genericBtnEnabled || !cancelBtnDisabled || !saveBtnDisabled

    const html = []
    if (hasButtonsToRender) {
      html.push(
        <div
          key="generic_form_btns"
          className={classNames(
            'd-flex flex-column flex-md-row justify-content-center align-items-center my-4'
          )}
        >
          {!cancelBtnDisabled && (
            <JujoButtonComponent
              bstyle={2}
              blabel={translate('cancel')}
              handleClick={this.handleCancelClicked}
            />
          )}
          {!saveBtnDisabled && (
            <JujoButtonComponent
              bstyle={1}
              blabel={translate('save_changes')}
              handleClick={this.handleSaveClicked}
            />
          )}
          {genericBtnEnabled &&
            buttonsBehavior.generic.map(btn => {
              const { blabel, bwidth, bstyle } = btn

              return (
                <JujoButtonComponent
                  key={blabel}
                  bstyle={bstyle}
                  bwidth={bwidth}
                  blabel={translate(blabel)}
                  handleClick={async () => {
                    await defaultHandleGenericBtnClick(btn, this)
                  }}
                />
              )
            })}
        </div>
      )
    }
    return html
  }

  renderFields = fields => {
    const { readonly } = this.props
    const { localDataSource } = this.state

    const html = []

    for (let i = 0; i !== fields.length; i += 1) {
      const field = fields[i]

      const {
        name,
        alias,
        fieldType,
        hidden,
        hiddenTitle,
        noBorder,
        customWidth,
        conditions,
        after,
        customizations,
        required,
      } = field

      const condition_satisfied = verifyGenericConditions(
        this,
        conditions,
        localDataSource
      )

      let label_to_show = translate(alias) || translate(name)
      label_to_show = required ? `* ${label_to_show}` : label_to_show

      if (condition_satisfied) {
        const DynamicField = require(`../common/fields/${fieldType}`).default

        const { titleStyleOverride } = customizations || {}

        html.push(
          <div
            key={name}
            className={classNames(
              'my-3',
              customWidth || 'col-md-6',
              hidden ? 'd-none' : ''
            )}
          >
            {!hiddenTitle && (
              <div
                className={classNames(
                  titleStyleOverride || 'ffamily-secondary fs-9 fc-gray'
                )}
              >
                {label_to_show}
              </div>
            )}

            <div
              className={classNames(
                'p-1',
                noBorder ? '' : 'border border-color-4 rounded bg-white'
              )}
            >
              <DynamicField
                field={field}
                readonly={readonly}
                dataSource={localDataSource}
                initialValue={localDataSource[name]}
                handleValueChanged={this.handleValueChanged}
              />
              {after && (
                <div className={classNames('fs-8 fc-4 fst-italic text-center')}>
                  {translate(after)}
                </div>
              )}
            </div>
          </div>
        )
      }
    }
    return html
  }

  renderGroupActions = actions => {
    const html = []

    for (let i = 0; i !== actions.length; i += 1) {
      const act = actions[i]
      const { label, bstyle, bwidth } = act

      html.push(
        <div key={label}>
          <JujoButtonComponent
            key={label}
            bstyle={bstyle}
            bwidth={bwidth}
            blabel={translate(label)}
            // eslint-disable-next-line no-loop-func
            handleClick={async () => {
              await defaultHandleGenericBtnClick(act, this)
            }}
          />
        </div>
      )
    }

    return html
  }

  renderFieldGroups = () => {
    const { fieldGroups, fields } = this.state

    const html = []

    for (let i = 0; i !== fieldGroups.length; i += 1) {
      const group = fieldGroups[i]
      const {
        title,
        hiddenTitle,
        subtitle,
        actions,
        customContainerClass,
        customWidth,
        required,
        fields: gFields,
      } = group
      const fieldList = []
      if (gFields) {
        for (let j = 0; j !== gFields.length; j += 1) {
          const fName = gFields[j]
          const fDefinition = fields.find(x => x.name === fName)
          if (fDefinition) {
            fieldList.push(fDefinition)
          }
        }
      }

      let title_to_show = translate(title)
      title_to_show = required ? `* ${title_to_show}` : title_to_show

      const key = `${slugifyString(title)}_${i}`

      html.push(
        <div
          key={key}
          className={classNames(
            customContainerClass || 'mb-3',
            customWidth || 'col-12'
          )}
        >
          {(hiddenTitle || false) === false && (
            <div
              className={classNames(
                'd-flex flex-column border-bottom border-2 pt-2 mb-1'
              )}
            >
              <div className={classNames('fw-bold')}>{title_to_show}</div>
            </div>
          )}
          {subtitle && (
            <div className={classNames('fst-italic fs-8')}>
              {translate(subtitle)}
            </div>
          )}
          {actions && actions.length > 0 && (
            <div
              className={classNames(
                'd-flex flex-column flex-md-row justify-content-center justify-content-md-end align-items-center my-2'
              )}
            >
              {this.renderGroupActions(actions)}
            </div>
          )}
          <div className={classNames('row')}>
            {this.renderFields(fieldList)}
          </div>
        </div>
      )
    }

    return html
  }

  render() {
    const { initialized, fields, fieldGroups } = this.state

    return (
      <>
        {initialized === false && <JujoLoading />}
        {initialized && (
          <div>
            {fieldGroups.length === 0 && (
              <div className={classNames('row p-3')}>
                {this.renderFields(fields)}
              </div>
            )}
            {fieldGroups.length > 0 && (
              <div className={classNames('row')}>
                {this.renderFieldGroups()}
              </div>
            )}
            {this.renderButtons()}
          </div>
        )}
      </>
    )
  }
}

export default connect(
  mapStateToProps,
  actionCreators
)(JujoGenericFormComponent)
