import classNames from 'classnames'
import React from 'react'
import { connect } from 'react-redux'

import * as actionCreators from '../../../store/actions'
import { mapStateToProps } from '../../../utils'

export class ECBooleanFieldComponent extends React.Component {
  componentWillUnmount() {
    // Fix Warning: Can't perform a React state update on an unmounted component
    this.setState = () => {}
  }

  render() {
    const {
      specialization,
      environment,
      field,
      initialValue,
      handleValueChanged,
      dataSource,
    } = this.props

    const { translations } = specialization
    const { texts } = translations
    const { locale } = environment

    const { name, labels } = field

    const fieldValue = parseInt(dataSource[name] || initialValue || 0, 10)
    return (
      <div
        key={name}
        className={classNames(
          'rounded-4 bg-3 text-center d-flex justify-content-center align-items-center'
        )}
      >
        {[0, 1].map(val => (
          <div
            key={`boolean_${val}`}
            className={classNames(
              fieldValue === val
                ? 'selected bg-1 fc-white shadow-sm rounded-4'
                : ''
            )}
            style={{
              width: 'calc(50%)',
            }}
            role="button"
            tabIndex={0}
            onClick={() => {
              handleValueChanged(name, val)
            }}
            onKeyPress={() => {
              handleValueChanged(name, val)
            }}
          >
            {texts[locale][val === 0 ? labels.false : labels.true]}
          </div>
        ))}
      </div>
    )
  }
}

export default connect(mapStateToProps, actionCreators)(ECBooleanFieldComponent)
