import loadable from '@loadable/component'
import React from 'react'
import { connect } from 'react-redux'

import * as actionCreators from '../../../../store/actions'
import { cloneObj, mapStateToProps } from '../../../../utils'

const JujoLoading = loadable(() => import('../../../loading'))
const JujoEditableComponent = loadable(() =>
  import('../../../editable_component')
)

const classNames = require('classnames')

export class EditVariantComponent extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      loading: true,
      definition: {},
    }
  }

  componentWillUnmount() {
    // Fix Warning: Can't perform a React state update on an unmounted component
    this.setState = () => {}
  }

  componentDidMount = async () => {
    const { transitionaData } = this.props

    const definition = cloneObj(transitionaData)
    this.setState({ definition, loading: false })
  }

  handleEditVariantCompleted = async data => {
    const { updateFlowState, updateFlowData, injections } = this.props
    const { updateVariant } = injections

    const flow_data = cloneObj(data)

    await updateFlowData(flow_data)
    await updateFlowState('view_variant')

    updateVariant(flow_data)
  }

  renderVariantFields = () => {
    const { definition } = this.state
    const { key } = definition

    const html = []

    html.push(
      <div key={key}>
        <div>
          <JujoEditableComponent
            c_def={{
              comp: 'editable_component',
              entity: {
                source: 'static',
                defValue: 'jujo_font_variant',
              },
              injectedData: definition,
              renderer: {},
              injections: {
                handleEditVariantCompleted: this.handleEditVariantCompleted,
              },
            }}
          />
        </div>
      </div>
    )

    return html
  }

  render() {
    const { loading } = this.state

    return (
      <>
        {loading === true && <JujoLoading />}
        {loading === false && (
          <div className={classNames('')}>{this.renderVariantFields()}</div>
        )}
      </>
    )
  }
}

export default connect(mapStateToProps, actionCreators)(EditVariantComponent)
