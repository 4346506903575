import React, { Component } from 'react'
import { connect } from 'react-redux'

import * as actionCreators from '../../../../../../src/store/actions'
import { mapStateToProps } from '../../../../../../src/utils'

const classNames = require('classnames')

class FieldShell extends Component {
  constructor(props) {
    super(props)
    this.state = {}
  }

  render() {
    const { specialization, environment, fieldName, children, withBorder } =
      this.props
    const { translations } = specialization
    const { texts } = translations
    const { locale } = environment
    return (
      <div key={fieldName}>
        <div className={classNames('ffamily-secondary fs-9 fc-gray')}>
          {texts[locale][fieldName]}
        </div>
        <div
          className={classNames(
            withBorder ? 'p-1 border border-color-4 rounded bg-white' : ''
          )}
        >
          {children}
        </div>
      </div>
    )
  }
}

export default connect(mapStateToProps, actionCreators)(FieldShell)
