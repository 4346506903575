import React from 'react'
import { connect } from 'react-redux'
import apiCallMap from '../../../enums/apiCallMap'
import * as apiService from '../../../services/apiService'

import {
  baseRequestObject,
  parseEndpoint,
} from '../../../services/servicesHelper'

import * as actionCreators from '../../../store/actions'
import { mapStateToProps } from '../../../utils'
import JujoLoading from '../../loading'

const classNames = require('classnames')

export class ECDataListFieldComponent extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      loading: true,
      selected_label: '',
      options: [],
    }
  }

  componentWillUnmount() {
    // Fix Warning: Can't perform a React state update on an unmounted component
    this.setState = () => {}
  }

  componentDidMount = async () => {
    await this.initializeOptions()
    await this.initializeSelectedLabel()

    this.setState({ loading: false })
  }

  initializeOptions = async () => {
    const { field } = this.props
    const { options, apis } = field

    let options_list = options || []
    // datalist can be populated both statically and via apis
    // if static data is not there then try by apis
    if (options_list.length === 0 && apis) {
      const reqObj = this.composeRequest()
      const { parsedEp } = reqObj

      const result = await apiService.httpGet(
        `${process.env.apiUrl}${parsedEp}`
      )
      if (result) {
        const { status, data } = result
        if (status === 200) {
          options_list = data.data
        }
      }
    }
    await this.setState({ options: options_list })
  }

  initializeSelectedLabel = async () => {
    const { initialValue } = this.props

    let parsed_value = ''
    if (initialValue) {
      parsed_value = this.findLabelFromValue(initialValue)
    }

    this.setState({
      selected_label: parsed_value,
    })
  }

  composeRequest = () => {
    const { field, environment, authentication } = this.props
    const { apis } = field

    const { getData } = apis
    const { apiCall, requestType, relatedEntity } = getData

    const requestData = baseRequestObject(
      apiCallMap[apiCall],
      relatedEntity,
      requestType,
      environment,
      authentication
    )

    const parsedEp = parseEndpoint(requestData)

    return {
      parsedEp,
      data: [],
    }
  }

  findLabelFromValue = value => {
    const { options } = this.state
    const { field } = this.props
    const { renderField, valueField } = field

    let label = '' // value
    for (let i = 0; i !== options.length; i += 1) {
      const curr_opt = options[i]
      if (curr_opt[valueField].toString() === value.toString()) {
        label = curr_opt[renderField]
        break
      }
    }

    return label
  }

  findValueFromLabel = label => {
    const { options } = this.state
    const { field } = this.props
    const { renderField, valueField } = field

    let value = null
    for (let i = 0; i !== options.length; i += 1) {
      const curr_opt = options[i]
      if (curr_opt[renderField] === label) {
        value = curr_opt[valueField]
        break
      }
    }
    return value
  }

  render() {
    const { loading, options, selected_label } = this.state
    const { specialization, environment, field, handleValueChanged } =
      this.props

    const { name, renderField, valueField } = field

    const { translations } = specialization
    const { texts } = translations
    const { locale } = environment

    const datalist_id = `${name}_list`

    return (
      <>
        {loading && <JujoLoading />}
        {!loading && (
          <>
            <input
              className={classNames('ffamily-primary')}
              name={name}
              defaultValue={selected_label}
              type="text"
              list={datalist_id}
              onChange={e => {
                const { value } = e.target
                const parsed_value = this.findValueFromLabel(value)
                handleValueChanged(name, parsed_value)
              }}
            />
            <datalist
              id={datalist_id}
              className={classNames('ffamily-primary')}
            >
              <option key={`${name}_empty`}>--</option>
              {options.map(opt => (
                <option
                  key={opt[valueField]}
                  label={opt[renderField]}
                  data-value={opt[valueField]}
                  value={texts[locale][opt[renderField]] || opt[renderField]}
                />
              ))}
            </datalist>
          </>
        )}
      </>
    )
  }
}

export default connect(
  mapStateToProps,
  actionCreators
)(ECDataListFieldComponent)
