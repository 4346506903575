import React from 'react'
import { connect } from 'react-redux'
import * as actionCreators from '../../../../../../../../src/store/actions'
import { cloneObj, mapStateToProps } from '../../../../../../../../src/utils'
import ECSelectFieldComponent from '../../../../../../../../src/components/common/fields/selectField'
import FieldShell from '../../../../common/field_shell'
import { saveSessionToServer } from '../../../../../../../../src/services/sessionHelper'
import JujoButtonComponent from '../../../../../../../../src/components/jujo_button'

const classNames = require('classnames')

export class OssigenoWizardStepStart extends React.Component {
  constructor(props) {
    super(props)
    this.state = {}
  }

  componentWillUnmount() {
    // fix Warning: Can't perform a React state update on an unmounted component
    this.setState = () => {}
  }

  componentDidMount = async () => {}

  handlePickerChange = async (item, value) => {
    const { session, updateSession } = this.props
    const session_copy = cloneObj(session)
    const { session_id, session_data } = session_copy
    const { wizard } = session_data
    wizard[item] = value
    if (item === 'client_id') {
      wizard.import_id = 0
    }
    const returned_data = await saveSessionToServer(session_id, session_data)
    session_copy.session_data = returned_data
    await updateSession(session_copy)
  }

  handleNextStep = async () => {
    await this.handlePickerChange('active_step', 2)
  }

  render() {
    const { session } = this.props
    const { session_data } = session
    const { clients, imports, wizard } = session_data
    const { client_id, import_id } = wizard

    const clientField = {
      name: 'client_id',
      options: clients,
    }

    const importField = {
      name: 'import_id',
      options: imports,
    }

    return (
      <div className={classNames('d-flex flex-column text-center')}>
        <div className={classNames('my-2')}>Cliente</div>
        <div className={classNames('bg-light-grey p-3 px-5')}>
          <FieldShell fieldName="client_id" withBorder>
            <ECSelectFieldComponent
              field={clientField}
              handleValueChanged={this.handlePickerChange}
              initialValue={parseInt(client_id, 10)}
            />
          </FieldShell>
        </div>
        {client_id > 0 && (
          <>
            <div className={classNames('mt-5 mb-2')}>Importazione</div>
            <div className={classNames('bg-light-grey p-3 px-5')}>
              <FieldShell fieldName="import_id" withBorder>
                <ECSelectFieldComponent
                  field={importField}
                  handleValueChanged={this.handlePickerChange}
                  initialValue={parseInt(import_id, 10)}
                />
              </FieldShell>
            </div>
          </>
        )}
        {import_id > 0 && (
          <div className={classNames('mt-5')}>
            <JujoButtonComponent
              bstyle={3}
              bwidth="200px"
              blabel="Avanti"
              handleClick={() => {
                this.handleNextStep()
              }}
            />
          </div>
        )}
      </div>
    )
  }
}
export default connect(mapStateToProps, actionCreators)(OssigenoWizardStepStart)
