import React from 'react'
import { connect } from 'react-redux'

import * as actionCreators from '../../../../store/actions'
import { mapStateToProps } from '../../../../utils'
import { GenericFilter } from './genericFilter'

const classNames = require('classnames')

export class TextFilter extends GenericFilter {
  renderFilter = () => {
    const { filter } = this.props
    const { filterValue } = this.state

    const html = []
    html.push(
      <div
        key={`filter_${filter.label}`}
        className={classNames('form-control rounded-4 shadow-sm border-0')}
      >
        <input
          type="text"
          className={classNames('')}
          defaultValue={filterValue}
          onBlur={e => this.updateFilter(e.target.value)}
          onKeyDown={async e => {
            if (e.key === 'Enter') {
              this.updateFilter(e.target.value)
            }
          }}
        />
      </div>
    )
    return html
  }
}

export default connect(mapStateToProps, actionCreators)(TextFilter)
