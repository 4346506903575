import requestTypesMap from '../../../enums/requestTypesMap'

export async function actCreateNewEntity(props) {
  const { dynamicForm, updateDynamicFormIntoRedux, entity } = props
  const dForm = { ...dynamicForm }
  dForm.entity = entity
  dForm.type = requestTypesMap.add
  dForm.visible = true
  await updateDynamicFormIntoRedux({ ...dForm })
}

export async function navigateTo(props) {
  const { action } = props
  const { params, sourceData } = action
  const { relatedField, target } = params

  if (typeof window !== 'undefined') {
    if (sourceData.length > 0) {
      const firstRecord = sourceData[0]
      const destination = firstRecord[relatedField]
      if (destination) {
        window.open(destination, target).focus()
      }
    }
  }
}
