import React from 'react'
import { connect } from 'react-redux'

import Parser from 'html-react-parser'
import * as actionCreators from '../../../store/actions'
import { mapStateToProps } from '../../../utils'

const classNames = require('classnames')

export class ECSelectButtonsFieldComponent extends React.Component {
  componentWillUnmount() {
    // Fix Warning: Can't perform a React state update on an unmounted component
    this.setState = () => {}
  }

  render() {
    const {
      specialization,
      environment,
      field,
      initialValue,
      handleValueChanged,
    } = this.props

    const { name, customizations, options, inlineText, required } = field
    const { elementWidth, borderBottom } = customizations || {}

    const { translations } = specialization
    const { texts } = translations
    const { locale } = environment

    let label_to_show = texts[locale][inlineText] || inlineText
    label_to_show = required ? `* ${label_to_show}` : label_to_show

    return (
      <div
        className={classNames(
          'd-flex justify-content-between align-items-center',
          inlineText ? 'flex-column flex-md-row' : 'flex-column',
          borderBottom
            ? 'border border-end-0 border-top-0 border-start-0 pb-2'
            : ''
        )}
      >
        {inlineText && (
          <div
            className={classNames(
              'text-start fw-bold fs-7 col-md-6 text-break'
            )}
          >
            {label_to_show}
          </div>
        )}
        <div className={classNames('d-flex flex-wrap justify-content-between')}>
          {options.map(opt => (
            <div
              key={opt.label}
              className={classNames(
                'd-flex flex-fill align-items-center justify-content-center mx-2 my-1 ffamily-secondary fs-8 p-2 rounded-4 text-break',
                opt.value === initialValue
                  ? 'bg-1 fc-white'
                  : 'bshadow-c1 bg-white fc-1'
              )}
              style={{ width: elementWidth || '' }}
              role="button"
              tabIndex={0}
              onClick={() => {
                handleValueChanged(name, opt.value)
              }}
              onKeyPress={() => {
                handleValueChanged(name, opt.value)
              }}
            >
              <div className={classNames('px-2 text-center ')}>
                {Parser(texts[locale][opt.label] || opt.label, {})}
              </div>
            </div>
          ))}
        </div>
      </div>
    )
  }
}

export default connect(
  mapStateToProps,
  actionCreators
)(ECSelectButtonsFieldComponent)
