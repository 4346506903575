import React from 'react'
import { connect } from 'react-redux'
import { JSONEditor } from 'svelte-jsoneditor/dist/jsoneditor'
import { mapStateToProps } from '../../../../utils'
import * as actionCreators from '../../../../store/actions'

export class JujoJsonEditor extends React.Component {
  constructor(props) {
    super(props)
    this.state = {}

    this.containerRef = React.createRef()
    this.editorRef = React.createRef()
  }

  componentWillUnmount() {
    // Fix Warning: Can't perform a React state update on an unmounted component
    this.setState = () => {}
  }

  componentDidMount = async () => {
    this.editorRef.current = new JSONEditor({
      target: this.containerRef.current,
      props: this.props,
    })
  }

  render() {
    return <div className="svelte-jsoneditor-react" ref={this.containerRef} />
  }
}

export default connect(mapStateToProps, actionCreators)(JujoJsonEditor)
