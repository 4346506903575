/* eslint-disable no-nested-ternary */
/* eslint-disable react/jsx-props-no-spreading */
import classNames from 'classnames'
import React, { useEffect, useState } from 'react'
import { connect, useSelector } from 'react-redux'
import { translate } from '../../../../utils'

function JujoFontMapping({ definition, onChange }) {
  const [f_families, setFontFamilies] = useState([])
  const [families_mapping, setFamiliesMapping] = useState([])

  const { jujo_fonts: fonts } = useSelector(state => state.session.session_data)

  const handleSelectChange = (event, fontFamily) => {
    const updatedFamilyMapping = families_mapping.filter(
      mapping => mapping.family !== fontFamily
    )

    const selectedFontId = event.target.value
    if (selectedFontId !== '-') {
      const selectedFont = fonts.find(
        font => font.id === parseInt(selectedFontId, 10)
      )
      const { src } = selectedFont.data[0]
      const newMapping = { family: fontFamily, mapped_id: selectedFontId, src }
      setFamiliesMapping([...updatedFamilyMapping, newMapping])
    } else {
      setFamiliesMapping(updatedFamilyMapping)
    }
  }

  const findAllFontFamilies = code => {
    const parser = new DOMParser()
    const svgDoc = parser.parseFromString(code, 'image/svg+xml')
    const fontFamilies = []
    const elements = svgDoc.querySelectorAll('[font-family]')
    for (let i = 0; i < elements.length; i += 1) {
      const fontFamily = elements[i].getAttribute('font-family')
      if (fontFamilies.indexOf(fontFamily) === -1) {
        fontFamilies.push(fontFamily)
      }
    }

    setFontFamilies(fontFamilies)
  }

  useEffect(() => {
    if (definition !== null) {
      const { svg } = definition
      findAllFontFamilies(svg)
    }
  }, [definition])

  useEffect(() => {
    onChange(families_mapping)
  }, [families_mapping])

  return (
    <>
      {f_families.length > 0 && (
        <div className={classNames('bg-white mb-2 p-3')}>
          <div className={classNames('mb-2')}>
            {translate('font_mapping_instruction')}
          </div>
          <div className={classNames('px-2')}>
            {f_families.map((fontFamily, index) => (
              <div key={index} className={classNames('row fs-7 mb-1')}>
                <div className={classNames('col-6 p-1 fw-bold')}>
                  {fontFamily}
                </div>
                <div className={classNames('col-6 border p-1')}>
                  <select onChange={e => handleSelectChange(e, fontFamily)}>
                    <option value="-" label="Seleziona" />
                    {fonts.map(font => (
                      <option key={font.id} value={font.id}>
                        {font.name}
                      </option>
                    ))}
                  </select>
                </div>
              </div>
            ))}
          </div>
        </div>
      )}
    </>
  )
}

export default connect()(JujoFontMapping)
