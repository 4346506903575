/* eslint-disable jsx-a11y/control-has-associated-label */
import React from 'react'
import { connect } from 'react-redux'

import * as actionCreators from '../../../../store/actions'
import { mapStateToProps, translate } from '../../../../utils'
import { GenericFilter } from './genericFilter'

const classNames = require('classnames')

export class SelectFilter extends GenericFilter {
  renderFilter = () => {
    const { filter } = this.props
    const { filterValue } = this.state

    const { label, list } = filter

    const html = []
    html.push(
      <div
        key={`filter_${label}`}
        className={classNames('form-control rounded-4 shadow-sm border-0')}
      >
        <select
          defaultValue={filterValue}
          onChange={e => this.updateFilter(e.target.value)}
        >
          <option value="" />
          {list.map(opt => {
            let lab = ''
            let val = ''

            if (typeof opt === 'object') {
              lab = translate(opt.label)
              val = opt.value
            } else {
              lab = translate(opt.label)
              val = opt
            }
            return (
              <option key={val} value={val}>
                {lab}
              </option>
            )
          })}
        </select>
      </div>
    )
    return html
  }
}

export default connect(mapStateToProps, actionCreators)(SelectFilter)
