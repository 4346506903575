import React from 'react'
import { connect } from 'react-redux'
import uuid from 'react-uuid'
import * as actionCreators from '../../../../../../../../src/store/actions'
import { cloneObj, mapStateToProps } from '../../../../../../../../src/utils'
import ECSelectFieldComponent from '../../../../../../../../src/components/common/fields/selectField'
import FieldShell from '../../../../common/field_shell'

import ECTextFieldComponent from '../../../../../../../../src/components/common/fields/textField'
import JujoLoading from '../../../../../../../../src/components/loading'
import * as apiService from '../../../../../../../../src/services/apiService'
import { saveSessionToServer } from '../../../../../../../../src/services/sessionHelper'
import JujoButtonComponent from '../../../../../../../../src/components/jujo_button'

const classNames = require('classnames')

export class OssigenoWizardStepStores extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      working: false,
      recognition_rows: false,
    }
  }

  componentWillUnmount() {
    // fix Warning: Can't perform a React state update on an unmounted component
    this.setState = () => {}
  }

  componentDidMount = async () => {
    const { session } = this.props
    const { session_data } = session
    const { wizard } = session_data
    const { unrecognized_fee_stores } = wizard

    const recognition_rows = []
    if (unrecognized_fee_stores.length > 0) {
      for (let i = 0; i < unrecognized_fee_stores.length; i += 1) {
        const store = unrecognized_fee_stores[i]
        recognition_rows.push({
          i,
          unrecognized_store: store,
          existing_store: '',
          new_store: '',
        })
      }
    }

    this.setState({ recognition_rows })
  }

  handleNextStep = async () => {
    await this.handlePickerChange('active_step', 4)
  }

  handleExistingStoreSelected = (item, value) => {
    const { recognition_rows } = this.state
    const updated_recognition_rows = cloneObj(recognition_rows)
    updated_recognition_rows[item.i].existing_store = value
    updated_recognition_rows[item.i].new_store = ''
    this.setState({ recognition_rows: updated_recognition_rows })
  }

  drawStoreSelect = i => {
    const { recognition_rows } = this.state
    const { session } = this.props
    const { session_data } = session
    const { stores } = session_data
    const html = []

    const field = {
      name: { i },
      options: stores,
    }

    if (recognition_rows) {
      let initialValue = recognition_rows[i].existing_store
      initialValue = initialValue !== '' ? parseInt(initialValue, 10) : 0

      html.push(
        <div key={uuid()}>
          <FieldShell fieldName="" withBorder>
            <ECSelectFieldComponent
              field={field}
              handleValueChanged={this.handleExistingStoreSelected}
              initialValue={initialValue}
            />
          </FieldShell>
        </div>
      )
    }

    return html
  }

  handleNewSstoreChanged = (item, value) => {
    const { recognition_rows } = this.state
    const updated_recognition_rows = cloneObj(recognition_rows)
    updated_recognition_rows[item.i].existing_store = ''
    updated_recognition_rows[item.i].new_store = value
    this.setState({ recognition_rows: updated_recognition_rows })
  }

  drawNewStore = i => {
    const { recognition_rows } = this.state
    const html = []

    if (recognition_rows) {
      const { existing_store } = recognition_rows[i]
      const field = {
        name: { i },
        value: recognition_rows[i].new_store,
        existing_store,
      }
      html.push(
        <div key={`drawNewStore_${i}`}>
          <FieldShell fieldName="" withBorder>
            <ECTextFieldComponent
              initialValue={recognition_rows[i].new_store}
              field={field}
              handleValueChanged={this.handleNewSstoreChanged}
            />
          </FieldShell>
        </div>
      )
    }

    return html
  }

  drawUnrecognizedStores = () => {
    const { working } = this.state
    const { session } = this.props
    const { session_data } = session
    const { wizard } = session_data
    const { unrecognized_fee_stores } = wizard
    const html = []

    html.push(
      <span key="drawUnrecognizedStores_columns">
        <div className={classNames('row my-2 mt-3 text-start')}>
          <div className={classNames('col-12 col-md-4')}>
            Negozio sconosciuto
          </div>
          <div className={classNames('col-12 col-md-4')}>Negozio esistente</div>
          <div className={classNames('col-12 col-md-4')}>Nuovo negozio</div>
        </div>
        <hr />
      </span>
    )

    for (let i = 0; i < unrecognized_fee_stores.length; i += 1) {
      const store = unrecognized_fee_stores[i]
      html.push(
        <div
          key={`drawUnrecognizedStores${i}`}
          className={classNames('row my-2 py-2')}
          style={{ backgroundColor: '#fafafa' }}
        >
          <div
            className={classNames(
              'col-12 col-md-4 d-flex align-items-center text-danger'
            )}
          >
            {store}
          </div>
          <div
            className={classNames('col-12 col-md-4 d-flex align-items-center ')}
          >
            {this.drawStoreSelect(i)}
          </div>
          <div
            className={classNames('col-12 col-md-4 d-flex align-items-center ')}
          >
            {this.drawNewStore(i)}
          </div>
        </div>
      )
    }

    const { recognition_rows } = this.state
    let disable_apply_button = false
    for (let i = 0; i < recognition_rows.length; i += 1) {
      const { existing_store, new_store } = recognition_rows[i]
      if (
        (existing_store === undefined ||
          existing_store === 0 ||
          existing_store === '') &&
        (new_store === undefined || new_store === '')
      ) {
        disable_apply_button = true
        break
      }
    }

    html.push(
      <span key="drawUnrecognizedStores_apply_button">
        {working && <JujoLoading />}
        {!working && (
          <div className={classNames('row my-2 mt-3 text-start')}>
            <div className={classNames('col-12 col-md-4')} />
            <div className={classNames('col-12 col-md-4')} />
            <div className={classNames('col-12 col-md-4')}>
              <JujoButtonComponent
                bstyle={8}
                bwidth="200px"
                blabel="Applica normalizzazione"
                bDisabled={disable_apply_button}
                handleClick={() => {
                  this.handleApplyNormalizzations()
                }}
              />
            </div>
          </div>
        )}
        <hr />
      </span>
    )

    return html
  }

  handleApplyNormalizzations = async () => {
    const { recognition_rows } = this.state
    const { session } = this.props
    const { session_data } = session
    const { wizard } = session_data
    const { import_id, client_id } = wizard

    const parsedEp = 'action/post/fee/apply-store-normalizations'
    const data = {
      recognition_rows: cloneObj(recognition_rows),
      import_id,
      client_id,
    }
    await this.setState({ working: true })
    const response = await apiService.httpPost(
      `${process.env.apiUrl}${parsedEp}`,
      data
    )
    const { status } = response
    if (status === 200 || status === 201) {
      if (typeof window !== 'undefined') {
        window.location.reload()
        await this.setState({ recognition_rows: false })
      }
    }
    await this.setState({ working: false })
  }

  handlePickerChange = async (item, value) => {
    const { session, updateSession } = this.props
    const session_copy = cloneObj(session)
    const { session_id, session_data } = session_copy
    const { wizard } = session_data
    wizard[item] = value
    const returned_data = await saveSessionToServer(session_id, session_data)
    session_copy.session_data = returned_data
    await updateSession(session_copy)
  }

  render() {
    const { recognition_rows } = this.state
    return (
      <div
        className={classNames('d-flex flex-column text-center w-100 w-md-50')}
      >
        <div className={classNames('mb-5 fw-bold')}>Normalizzazione negozi</div>
        <div className={classNames('bg-light-grey p-3 px-5 fs-7')}>
          {recognition_rows &&
            recognition_rows.length > 0 &&
            this.drawUnrecognizedStores()}
          {recognition_rows && recognition_rows.length === 0 && (
            <div
              className={classNames(
                'w-100 py-4 d-flex align-items-center justify-content-center'
              )}
            >
              <div
                className={classNames('d-flex align-items-center text-center ')}
              >
                <div
                  className={classNames('green-check-icon me-2')}
                  style={{
                    width: '25px',
                    height: '25px',
                    backgroundPosition: 'center',
                    backgroundSize: 'contain',
                    backgroundRepeat: 'no-repeat',
                  }}
                />
                Tutti i negozi sono normalizzati
              </div>
            </div>
          )}
        </div>
        {recognition_rows && recognition_rows.length === 0 && (
          <div
            className={classNames(
              'mt-5 w-100 d-flex align-items-center justify-content-center'
            )}
          >
            <JujoButtonComponent
              bstyle={3}
              bwidth="200px"
              blabel="Avanti"
              handleClick={() => {
                this.handleNextStep()
              }}
            />
          </div>
        )}
      </div>
    )
  }
}
export default connect(
  mapStateToProps,
  actionCreators
)(OssigenoWizardStepStores)
