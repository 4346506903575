import initialState from './initialState'

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case 'UPDATE_GENERIC_DATA_BOX_3':
      return action.genericData
    default:
      return state
  }
}

export default reducer
