import React from 'react'
import { connect } from 'react-redux'
import * as actionCreators from '../../../../../../../../src/store/actions'
import { cloneObj, mapStateToProps } from '../../../../../../../../src/utils'
import { saveSessionToServer } from '../../../../../../../../src/services/sessionHelper'

import * as apiService from '../../../../../../../../src/services/apiService'
import JujoLoading from '../../../../../../../../src/components/loading'
import { showMessageBox } from '../../../../common/message_box'
import JujoButtonComponent from '../../../../../../../../src/components/jujo_button'

const classNames = require('classnames')

export class OssigenoWizardStepReport extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      working: false,
      send_data: false,
    }
  }

  componentWillUnmount() {
    // fix Warning: Can't perform a React state update on an unmounted component
    this.setState = () => {}
  }

  componentDidMount = async () => {}

  handleExportStats = async () => {
    const { session } = this.props
    const { session_data } = session
    const { wizard } = session_data
    const { import_id, client_id } = wizard
    const data = {
      client_id,
      import_id,
    }
    const parsedEp = 'action/post/fee/export-stats'
    await this.setState({ working: true })
    const response = await apiService.httpPost(
      `${process.env.apiUrl}${parsedEp}`,
      data
    )
    const { status } = response
    if (status === 200 || status === 201) {
      showMessageBox(this, response, 'success')
    } else {
      showMessageBox(this, response, 'error')
    }
    await this.setState({ working: false })
  }

  handleSendData = async b => {
    await this.setState({ send_data: b })
  }

  handleGoSendData = async () => {
    const { session, updateSession } = this.props
    const { session_data, session_id } = session
    const { wizard } = session_data
    const { import_id, client_id } = wizard
    const data = {
      client_id,
      import_id,
    }

    const session_data_updated = cloneObj(session_data)
    const session_updated = cloneObj(session)
    session_data_updated.import_data = {}
    session_data_updated.wizard = {}
    session_data_updated.wizard.active_step = 1
    session_data_updated.wizard.client_id = 0
    session_data_updated.wizard.import_id = 0
    const returned_data = await saveSessionToServer(
      session_id,
      session_data_updated
    )
    session_updated.session_data = returned_data
    await updateSession(session_updated)

    const parsedEp = 'action/post/fee/migrate-data-to-v1'
    await this.setState({ working: true })
    const response = await apiService.httpPost(
      `${process.env.apiUrl}${parsedEp}`,
      data
    )
    const { status } = response
    if (status === 200 || status === 201) {
      showMessageBox(this, response, 'success')
      setTimeout(() => {
        window.location.href = '/admin/importations/'
      }, 4000)
    }
  }

  render() {
    const { working, send_data } = this.state
    const { session } = this.props
    const { session_data } = session
    const { wizard } = session_data
    const { import_closed } = wizard
    return (
      <div className={classNames('d-flex flex-column text-center')}>
        <div className={classNames('my-2')}>Report finali</div>
        <div
          className={classNames(
            'bg-light-grey p-5 px-5 d-flex align-items-center justify-content-center'
          )}
        >
          {working && <JujoLoading />}
          {!working && (
            <>
              <JujoButtonComponent
                bstyle={8}
                bwidth="260px"
                blabel="Esporta statistiche (xls)"
                handleClick={() => {
                  this.handleExportStats()
                }}
              />
            </>
          )}
        </div>
        {!import_closed && !send_data && (
          <div
            className={classNames(
              'mt-5 w-100 d-flex flex-column align-items-center justify-content-center'
            )}
          >
            <JujoButtonComponent
              bstyle={10}
              bwidth="200px"
              blabel="Invia dati alla V1"
              handleClick={() => {
                this.handleSendData(true)
              }}
            />
            <div className={classNames('fc-red py-4')}>
              Attenzione! Dopo aver cliccato su questo pulsante verrà chiesta
              una conferma
            </div>
          </div>
        )}
        {!import_closed && send_data && (
          <div
            className={classNames(
              'mt-5 w-100 d-flex align-items-center justify-content-center'
            )}
          >
            <div
              className={classNames('d-flex flex-column')}
              style={{ width: '600px' }}
            >
              <div className={classNames('fc-red fs-4')}>
                !!! INVIO DATI ALLA V1 !!!
              </div>
              <div className={classNames('fc-red py-4')}>
                Attenzione! Confermando questa procedura invierai i dati in modo
                definitivo e saranno consultabili nella V1. Questa procedura è
                irreversibile. Desideri realmente procedere?
              </div>
              <div className={classNames('d-flex py-4')}>
                <div className={classNames('me-5')}>
                  <JujoButtonComponent
                    bstyle={10}
                    bwidth="280px"
                    blabel="Conferma invio dati alla V1"
                    handleClick={() => {
                      this.handleGoSendData()
                    }}
                  />
                </div>
                <div className={classNames('ms-5')}>
                  <JujoButtonComponent
                    bstyle={2}
                    bwidth="200px"
                    blabel="Annulla"
                    handleClick={() => {
                      this.handleSendData(false)
                    }}
                  />
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    )
  }
}
export default connect(
  mapStateToProps,
  actionCreators
)(OssigenoWizardStepReport)
