import loadable from '@loadable/component'
import classNames from 'classnames'
import React, { useState, useEffect } from 'react'
import { randomString, translate } from '../../../../utils'

const JujoDropSvg = loadable(() => import('./drop_svg'))
const JujoFontMapping = loadable(() => import('./font_mapping'))

function FilesSelection({ onSvgFilesChanged, onFontFamiliesMappingChanged }) {
  const [frontFile, setFrontFile] = useState(null)
  const [backFile, setBackFile] = useState(null)
  const [frontFamiliesMapping, setFrontFamiliesMapping] = useState(null)
  const [backFamiliesMapping, setBackFamiliesMapping] = useState(null)

  useEffect(() => {
    const svg_data = {
      front: frontFile,
      back: backFile,
    }
    onSvgFilesChanged(svg_data)
  }, [frontFile, backFile])

  useEffect(() => {
    const family_mappings_data = {
      front: frontFamiliesMapping,
      back: backFamiliesMapping,
    }
    onFontFamiliesMappingChanged(family_mappings_data)
  }, [frontFamiliesMapping, backFamiliesMapping])

  const readFile = async file =>
    new Promise((resolve, reject) => {
      const reader = new FileReader()
      reader.onload = () => resolve(reader.result)
      reader.onerror = reject
      reader.readAsText(file)
    })

  const onFileSelected = async (file, callback) => {
    if (file === null) return

    const fileContents = await readFile(file)

    let svg_code = fileContents

    svg_code = svg_code.replace(/id="/g, 'label="')

    svg_code = svg_code.replace(/<g/g, () => {
      const gid = randomString(16)
      return `<g id="g-${gid}"`
    })

    svg_code = svg_code.replace(/xlink:href/g, 'href')

    const parser = new DOMParser()
    const svgDoc = parser.parseFromString(svg_code, 'image/svg+xml')
    const svg = svgDoc.querySelector('svg')

    const svg_id = randomString(16)
    svg.setAttribute('id', svg_id)

    let svg_size = {}
    if (svg.viewBox.baseVal) {
      const viewBox = svg.viewBox.baseVal
      const { width: w_px, height: h_px } = viewBox

      const px_to_mm_relation = 0.353 // 0.264583333

      const w_mm = parseFloat(w_px * px_to_mm_relation).toFixed(2)
      const h_mm = parseFloat(h_px * px_to_mm_relation).toFixed(2)

      svg_size = {
        px: {
          width: w_px,
          height: h_px,
        },
        mm: {
          width: w_mm,
          height: h_mm,
        },
      }
    }

    const definition = {
      svg: svg.outerHTML,
      svg_size,
      svg_id,
    }

    callback(definition)
  }

  const handleFrontFileChanged = file => {
    onFileSelected(file, setFrontFile)
  }
  const handleBackFileChanged = file => {
    onFileSelected(file, setBackFile)
  }

  return (
    <div>
      <div
        className={classNames(
          'd-flex flex-column flex-md-row row justify-content-start'
        )}
      >
        <div className={classNames('col-12 col-md-6')}>
          <div className={classNames('ffamily-secondary mb-2 fs-5')}>
            {translate('svg_front')}
          </div>
          <JujoDropSvg onChange={handleFrontFileChanged} />
          <JujoFontMapping
            definition={frontFile}
            onChange={setFrontFamiliesMapping}
          />
        </div>
        <div className={classNames('col-12 col-md-6')}>
          <div className={classNames('ffamily-secondary mb-2 fs-5')}>
            {translate('svg_back')}
          </div>
          <JujoDropSvg onChange={handleBackFileChanged} />
          <JujoFontMapping
            definition={backFile}
            onChange={setBackFamiliesMapping}
          />
        </div>
      </div>
    </div>
  )
}

export default FilesSelection
