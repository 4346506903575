/* eslint-disable array-callback-return */
/* eslint-disable consistent-return */
/* eslint-disable global-require */
/* eslint-disable import/no-dynamic-require */
import React from 'react'
import { connect } from 'react-redux'
import uuid from 'react-uuid'
import requestTypesMap from '../../../../enums/requestTypesMap'

import * as actionCreators from '../../../../store/actions'
import { mapStateToProps, translate } from '../../../../utils'
import { verifyConditions } from '../../flow'

const classNames = require('classnames')

export class RepeaterField extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      initialized: false,
      fieldValue: [],
      instances: [],
      deletedInstancesIndexList: [],
      conditionsMet: false,
    }
  }

  shouldComponentUpdate = (nextProps, nextState) => {
    const { dynamicForm, field } = this.props
    const { initialized, instances, deletedInstancesIndexList, conditionsMet } =
      this.state
    if (initialized !== nextState.initialized) {
      return true
    }
    if (deletedInstancesIndexList !== nextState.deletedInstancesIndexList) {
      return true
    }
    if (instances.length !== nextState.instances.length) {
      return true
    }
    if (
      dynamicForm.fieldsToUpdate.includes(field.name) === false &&
      nextProps.dynamicForm.fieldsToUpdate.includes(field.name)
    ) {
      return true
    }
    if (conditionsMet !== nextState.conditionsMet) {
      return true
    }
    return false
  }

  componentWillUnmount() {
    // fix Warning: Can't perform a React state update on an unmounted component
    this.setState = () => {}
  }

  componentDidMount = async () => {
    const { dynamicForm, field } = this.props
    const { source } = dynamicForm
    const fValueList = source[field.name]

    if (fValueList && fValueList.length > 0) {
      await this.recursivelyInitializeDefValues(fValueList)
    }

    const conditionsMet = await this.checkConditions()

    this.setState({ initialized: true, conditionsMet })
  }

  componentDidUpdate = async () => {
    const conditionsMet = await this.checkConditions()

    // eslint-disable-next-line react/no-did-update-set-state
    this.setState({ conditionsMet })
  }

  checkConditions = async () => {
    const { field } = this.props
    const { conditions } = field

    const conditionsMet = conditions
      ? await verifyConditions(conditions, this.props)
      : true

    return conditionsMet
  }

  recursivelyInitializeDefValues = async list => {
    if (list.length <= 0) return
    const val = list.pop()
    await this.handleAddNew(val)
    await this.recursivelyInitializeDefValues(list)
  }

  subFieldValueChanged = async (key, value) => {
    const { handleValueChanged, field } = this.props
    const { fieldValue } = this.state

    const keyParts = key.replace(`${field.name}_`, '').split('_')
    const idx = keyParts[0]
    keyParts.splice(0, 1)
    const fieldKey = keyParts.join('_')

    const updatedFieldValue = [...fieldValue]
    if (!updatedFieldValue[idx]) {
      updatedFieldValue[idx] = {}
    }
    updatedFieldValue[idx][fieldKey] = value
    this.setState({ fieldValue: updatedFieldValue })

    await handleValueChanged(key, value)
    handleValueChanged(field.name, updatedFieldValue)
  }

  handleAddNew = async initialValue => {
    const { instances } = this.state
    const { field, addFieldsInformationToDynamicForm } = this.props
    const { subFields } = field

    const updatedInstances = [...instances]
    const newInstanceComponents = []
    for (let i = 0; i !== subFields.length; i += 1) {
      let fieldInstance = { ...subFields[i] }
      let fieldInstanceString = JSON.stringify(fieldInstance)

      const expression = new RegExp('{idx}', 'g')
      fieldInstanceString = fieldInstanceString.replace(
        expression,
        updatedInstances.length
      )

      fieldInstance = JSON.parse(fieldInstanceString)
      fieldInstance.parentComponent = field.fieldType

      if (initialValue) {
        const prefix = `${field.name}_${updatedInstances.length}_`
        const mappingValue = fieldInstance.name.replace(prefix, '')
        fieldInstance.defValue = initialValue[mappingValue]
      }

      newInstanceComponents.push(fieldInstance)
    }
    await addFieldsInformationToDynamicForm(newInstanceComponents)

    updatedInstances.push(newInstanceComponents)

    this.setState({ instances: updatedInstances })
  }

  handleDeleteInstance = async index => {
    const { fieldValue, instances, deletedInstancesIndexList } = this.state
    const {
      field,
      deleteFieldsInformationFromDynamicForm,
      handleValueChanged,
    } = this.props

    const updatedDeletedInstancesIndexList = [...deletedInstancesIndexList]
    updatedDeletedInstancesIndexList.push(index)
    this.setState({
      deletedInstancesIndexList: updatedDeletedInstancesIndexList,
    })

    const fieldsToRemove = instances[index]
    await deleteFieldsInformationFromDynamicForm(fieldsToRemove)

    const updatedFieldValue = [...fieldValue]
    updatedFieldValue[index] = null
    this.setState({ fieldValue: updatedFieldValue })
    handleValueChanged(field.name, updatedFieldValue)
  }

  render() {
    const { initialized, instances, deletedInstancesIndexList, conditionsMet } =
      this.state
    const { field, dynamicForm } = this.props

    const { type } = dynamicForm

    const { alias, name } = field

    return (
      <>
        {initialized === true && conditionsMet === true && (
          <div className={classNames('m1')}>
            <div className={classNames('fs-7 text-secondary ps-2')}>
              {alias ? translate(alias) : translate(name)}
            </div>
            <div
              className={classNames(
                'mt-1 shadow-sm container-fluid rounded-5 p-3 bg-white'
              )}
            >
              <div
                key={uuid()}
                className={classNames('d-flex flex-row-reverse ms-1 me-1')}
              >
                {type !== requestTypesMap.view && (
                  <div
                    className={classNames('btn add-icon theme-svg')}
                    style={{
                      backgroundRepeat: 'no-repeat',
                      backgroundPosition: 'center',
                      width: '25px',
                      height: '25px',
                      backgroundSize: '20px',
                    }}
                    label="btn"
                    role="button"
                    tabIndex={0}
                    onClick={() => {
                      this.handleAddNew()
                    }}
                    onKeyPress={() => {
                      this.handleAddNew()
                    }}
                  />
                )}
              </div>
              {instances
                .map((instance, i) => {
                  if (!deletedInstancesIndexList.includes(i)) {
                    return (
                      <div
                        // eslint-disable-next-line react/no-array-index-key
                        key={`instance_${i}`}
                        className={classNames(
                          'row border mt-2 ms-1 me-1 ps-1 pe-1 pt-2 pb-2 rounded-4'
                        )}
                      >
                        <div
                          className={classNames(
                            'col-12 py-1 border-bottom d-flex justify-content-end'
                          )}
                        >
                          {type !== requestTypesMap.view && (
                            <div
                              className={classNames(
                                'btn cancel-icon theme-svg'
                              )}
                              style={{
                                backgroundRepeat: 'no-repeat',
                                backgroundPosition: 'center',
                                backgroundSize: '10px 10px',
                                width: '20px',
                                height: '20px',
                              }}
                              label="btn"
                              role="button"
                              tabIndex={0}
                              onClick={() => {
                                this.handleDeleteInstance(i)
                              }}
                              onKeyPress={() => {
                                this.handleDeleteInstance(i)
                              }}
                            />
                          )}
                        </div>
                        {instance.map((subField, idx) => {
                          const { fieldType } = subField
                          const DynamicField = require(`./${fieldType}`).default

                          return (
                            <DynamicField
                              // eslint-disable-next-line react/no-array-index-key
                              key={`${i}_${idx}_${subField.name}`}
                              field={subField}
                              handleValueChanged={this.subFieldValueChanged}
                            />
                          )
                        })}
                      </div>
                    )
                  }
                })
                .reverse()}
            </div>
          </div>
        )}
      </>
    )
  }
}

export default connect(mapStateToProps, actionCreators)(RepeaterField)
