/* eslint-disable import/no-cycle */
import React from 'react'
import loadable from '@loadable/component'

import { connect } from 'react-redux'

import Parser from 'html-react-parser'
import * as actionCreators from '../../../store/actions'
import jujoPageComponentTypesMap from '../../../enums/jujoPageComponentTypesMap'
import { mapStateToProps } from '../../../utils'

const JujoPageContentComponent = loadable(() => import('./page_content'))
const JujoPageElementComponent = loadable(() => import('./page_element'))

const classNames = require('classnames')

export class JujoParsableContentComponent extends React.Component {
  constructor(props) {
    super(props)
    this.state = {}
  }

  componentWillUnmount() {
    // fix Warning: Can't perform a React state update on an unmounted component
    this.setState = () => {}
  }

  render() {
    const { html } = this.props

    return (
      <div className={classNames('')}>
        {Parser(html, {
          // eslint-disable-next-line consistent-return
          replace: domNode => {
            if (domNode.name === jujoPageComponentTypesMap.jujo_component) {
              const { attribs, children } = domNode
              const { specialized, comp } = attribs

              const injectedComponentDataAttrs = {
                content: children,
              }
              const attr_keys = Object.keys(attribs)
              for (let i = 0; i !== attr_keys.length; i += 1) {
                const curr_key = attr_keys[i]
                if (
                  curr_key === 'specialized' ||
                  curr_key === 'comp' ||
                  curr_key === 'c_key'
                ) {
                  continue
                }

                let curr_value = ''
                try {
                  curr_value = JSON.parse(attribs[curr_key])
                } catch (e) {
                  curr_value = attribs[curr_key]
                }

                injectedComponentDataAttrs[curr_key] = curr_value
              }

              let InjectedComponent = <></>
              if (specialized && JSON.parse(specialized) === true) {
                InjectedComponent =
                  require(`../../../../jujo_specializations/src/${process.env.client}/components/${comp}`).default
              } else {
                InjectedComponent = require(`../../${comp}`).default
              }

              const component_key = attribs.c_key || undefined
              return (
                <div key={component_key}>
                  {React.createElement(
                    InjectedComponent,
                    injectedComponentDataAttrs
                  )}
                </div>
              )
            }
            if (domNode.name === jujoPageComponentTypesMap.jujo_content) {
              return <JujoPageContentComponent />
            }
            if (domNode.name === jujoPageComponentTypesMap.jujo_element) {
              const { attribs } = domNode
              const { slug } = attribs
              return <JujoPageElementComponent slug={slug} />
            }
          },
        })}
      </div>
    )
  }
}

export default connect(
  mapStateToProps,
  actionCreators
)(JujoParsableContentComponent)
