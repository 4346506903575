import React from 'react'
import { connect } from 'react-redux'
import uuid from 'react-uuid'
import * as actionCreators from '../../../../store/actions'
import { mapStateToProps } from '../../../../utils'
import { GenericField } from './genericField'

const classNames = require('classnames')

export class MatrixField extends GenericField {
  processOnBlur = (key, k, value) => {
    const { fieldValue } = this.state
    const updatedField = { ...fieldValue }

    updatedField[key].values[k] = value

    this.saveOnRedux(updatedField)
  }

  renderField = () => {
    const { data, fieldValue } = this.state
    const { field, environment, specialization } = this.props
    const { name, editable, dataList, translateAxes } = field
    const { translations } = specialization
    const { texts } = translations

    const { locale } = environment

    const dataToRender =
      editable === false ? data.data[0][dataList] : fieldValue
    const dataKeys = dataToRender ? Object.keys(dataToRender) : []

    const html = []
    html.push(
      <div
        key={uuid()}
        className={classNames(
          'd-flex flex-column flex-md-row py-2 justify-content-end position-relative'
        )}
      >
        {editable === false && (
          <input
            style={{ display: 'none' }}
            type="text"
            id={`matrix_hidden_${name}`}
            name={`matrix_hidden_${name}`}
            defaultValue={JSON.stringify(data)}
            onChange={e => {
              const { value } = e.target
              this.processOnChange(value)
            }}
          />
        )}

        <div className={classNames('col d-none d-md-block')} />
        {dataKeys.map((key, i) => {
          const { type, values } = dataToRender[key]
          const vKeys = Object.keys(values)

          return (
            <div className={classNames('col-12 col-md mb-2 pb-2')} key={uuid()}>
              <div
                className={classNames(
                  'fw-bold fs-8 d-flex justify-content-center fst-uppercase'
                )}
              >
                {translateAxes ? texts[locale][key] : key}
              </div>
              {vKeys.map(k => (
                <div
                  key={uuid()}
                  className={classNames(
                    'd-flex justify-content-md-center justify-content-between'
                  )}
                >
                  <span
                    className={classNames(
                      i > 0 ? 'visually-hidden' : '',
                      'position-absolute start-0 ps-3 d-none d-md-block fs-8'
                    )}
                  >
                    {translateAxes ? texts[locale][k] : k}
                  </span>
                  <span className={classNames('d-flex d-md-none')}>
                    {translateAxes ? texts[locale][k] : k}
                  </span>
                  {editable === false && (
                    <span
                      className={classNames(
                        'fs-7 d-flex justify-content-center'
                      )}
                      style={{ minHeight: '24px' }}
                    >
                      {values[k]}
                    </span>
                  )}
                  {editable === true && (
                    <input
                      type="text"
                      className={classNames(
                        {
                          val: true,
                          editable_val: true,
                        },
                        `val_${type}`
                      )}
                      defaultValue={values[k]}
                      onBlur={e => {
                        const { value } = e.target
                        this.processOnBlur(key, k, value)
                      }}
                    />
                  )}
                </div>
              ))}
            </div>
          )
        })}
      </div>
    )
    return html
  }
}

export default connect(mapStateToProps, actionCreators)(MatrixField)
