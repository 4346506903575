/* eslint-disable no-param-reassign */
import React from 'react'

import { connect } from 'react-redux'

import loadable from '@loadable/component'
import classNames from 'classnames'
import Parser from 'html-react-parser'

import uuid from 'react-uuid'
import * as actionCreators from '../../../../store/actions'
import { mapStateToProps } from '../../../../utils'
import {
  bindSvgDragActionToImageElement,
  bindSvgDragActionToTextElement,
} from '../../../../utils/svg'
import { bindDoubleClickToElement } from '../../../../utils/svg/double_click'
import { mqMobileOrTablet } from '../../../../utils/mediaquery'

const JujoLoading = loadable(() => import('../../../loading'))

export class JujoSVGPreviewComponent extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      loading: true,
      isMobileOrTablet: mqMobileOrTablet(),
      zoom_pixel_value: '200',
    }
    this.divElement = null
  }

  componentWillUnmount() {
    // Fix Warning: Can't perform a React state update on an unmounted component
    this.setState = () => {}
  }

  componentDidMount = async () => {
    setTimeout(() => {
      // const { offsetWidth } = this.divElement.parentElement.parentElement
      // this.setState({ zoom_pixel_value: offsetWidth })

      this.calculateZoomPixelValue()

      this.delayedActionsAfterRendering()
    }, '1')
    this.setState({ loading: false })
  }

  calculateZoomPixelValue = () => {
    const { isMobileOrTablet } = this.state
    if (isMobileOrTablet) {
      const screenWidth = window.innerWidth
      const width = 0.95 * screenWidth
      this.setState({ zoom_pixel_value: parseFloat(width) })
    } else {
      const { svg_id } = this.props
      const svg_element = document.getElementById(svg_id)
      const viewBox = svg_element.getAttribute('viewBox')
      const [, , width] = viewBox.split(' ')
      this.setState({ zoom_pixel_value: parseFloat(width) })
    }
  }

  delayedActionsAfterRendering = async () => {
    const { svg_id, handleSelectedNodeChange, setFocusedElement } = this.props
    const svg_element = document.getElementById(svg_id)
    if (svg_element) {
      const text_elements = svg_element.getElementsByTagName('text')
      const image_elements = svg_element.getElementsByTagName('image')

      for (let i = 0; i !== text_elements.length; i += 1) {
        const element = text_elements[i]
        bindSvgDragActionToTextElement(svg_id, element)
        bindDoubleClickToElement(svg_id, element, setFocusedElement)
      }
      for (let i = 0; i !== image_elements.length; i += 1) {
        const element = image_elements[i]
        bindSvgDragActionToImageElement(svg_id, element)
        bindDoubleClickToElement(svg_id, element, setFocusedElement)
      }

      document.addEventListener('selectionchange', () => {
        const { baseNode } = document.getSelection()
        if (baseNode && baseNode.nodeName === 'DIV') {
          const text_area = baseNode.querySelector('textarea')
          if (text_area) {
            const { nodeName, selectionStart, selectionEnd, value } = text_area
            if (nodeName === 'TEXTAREA') {
              const first_selected_row_index =
                value.substring(0, selectionStart).split('\n').length - 1
              const last_selected_row_index =
                value.substring(0, selectionEnd).split('\n').length - 1

              text_area.setAttribute('selStart', selectionStart)
              text_area.setAttribute('selEnd', selectionEnd)
              text_area.setAttribute(
                'firstSelectedRowIdx',
                first_selected_row_index
              )
              text_area.setAttribute(
                'lastSelectedRowIdx',
                last_selected_row_index
              )

              const node_idx =
                selectionEnd === 0
                  ? selectionEnd - last_selected_row_index
                  : selectionEnd - last_selected_row_index - 1

              handleSelectedNodeChange(node_idx)
            }
          }
        }
      })
    }
  }

  extractChars = row => {
    const { data } = row
    const chars = []
    for (let i = 0; i < data.length; i += 1) {
      const char = data.charAt(i)
      chars.push(char)
    }
    return chars
  }

  extractRows = node => {
    const { children } = node
    const rows = []

    if (children.length === 1) {
      const row = children[0]
      rows.push(row)
    } else {
      for (let i = 0; i !== children.length; i += 1) {
        const row_container = children[i]
        const row = row_container.children[0]
        rows.push(row)
      }
    }

    const parsed_rows = []
    for (let i = 0; i !== rows.length; i += 1) {
      const row = rows[i]
      const row_chars = this.extractChars(row)
      parsed_rows[i] = row_chars
    }

    return parsed_rows
  }

  drawSvg = () => {
    const { isMobileOrTablet } = this.state
    const { svg_code } = this.props
    const { zoom_pixel_value } = this.state
    const html = []

    html.push(
      <div
        // eslint-disable-next-line no-return-assign
        ref={el => (this.divElement = el)}
        key="svg_container"
        className={classNames(
          'd-flex flex-column align-items-center mb-4 mb-md-unset'
        )}
      >
        <div
          className={classNames('shadow p-2')}
          style={{
            width: zoom_pixel_value,
            height: isMobileOrTablet ? 'calc(100vh - 200px)' : '',
          }}
        >
          {svg_code &&
            Parser(svg_code, {
              // eslint-disable-next-line consistent-return
              replace: domNode => {
                if (domNode.name === 'svg') {
                  domNode.attribs.width = '100%'
                  domNode.attribs.height = '100%'

                  const gChildren = domNode.children.filter(
                    child => child.name === 'g'
                  )
                  const otherChildren = domNode.children.filter(
                    child => child.name !== 'g'
                  )
                  if (gChildren.length > 1) {
                    const mainG = { ...gChildren[0] }
                    mainG.attribs = {}
                    mainG.children = gChildren
                    domNode.children = [...otherChildren, mainG]
                  }
                }
                if (domNode.name === 'path') {
                  const { attribs } = domNode
                  const { fill } = attribs
                  if (fill === undefined) {
                    attribs.fill = '#000000'
                  }
                }
                if (domNode.name === 'text') {
                  const { attribs, children } = domNode

                  const { parsed, transform } = attribs
                  if (!parsed && transform && transform.startsWith('matrix')) {
                    const parsed_rows = this.extractRows(domNode)

                    const coordinates = transform
                      .match(/\((.*?)\)/)[1]
                      .split(' ')

                    const x_pos = coordinates[4]
                    const y_pos = coordinates[5]

                    let f_family = attribs['font-family']
                    let f_size = attribs['font-size']
                    let l_spacing = attribs['letter-spacing']
                    let line_height = 20
                    let f_color = attribs.fill
                    if (children.length > 1) {
                      f_family = children[0].attribs['font-family']
                      f_size = children[0].attribs['font-size']
                      l_spacing = children[0].attribs['letter-spacing']
                      line_height = (
                        parseFloat(children[1].attribs.y) -
                        parseFloat(children[0].attribs.y)
                      ).toFixed(2)
                      f_color = children[0].attribs.fill
                    }

                    return (
                      <text
                        x={x_pos}
                        y={y_pos}
                        fill={f_color}
                        // fontFamily={f_family}
                        // fontSize={f_size}
                        letterSpacing={l_spacing}
                        line-height={line_height}
                        parsed="true"
                      >
                        {parsed_rows.map((row_chars, i) => {
                          const row_key = uuid()
                          return (
                            <tspan
                              key={row_key}
                              x={coordinates[4]}
                              dy={i === 0 ? 0 : line_height}
                            >
                              {row_chars.map((char, cidx) => (
                                <tspan
                                  key={`${row_key}_char_${cidx}`}
                                  style={{
                                    fontSize: f_size,
                                    fontFamily: f_family,
                                  }}
                                >
                                  {char}
                                </tspan>
                              ))}
                            </tspan>
                          )
                        })}
                      </text>
                    )
                  }
                }
                if (domNode.name === 'image') {
                  const { attribs } = domNode
                  const { parsed, transform } = attribs
                  if (!parsed && transform && transform.startsWith('matrix')) {
                    const coordinates = transform
                      .match(/\((.*?)\)/)[1]
                      .split(' ')

                    const matrix_scale_x = coordinates[0]
                    const matrix_scale_y = coordinates[3]
                    const matrix_translate_x = coordinates[4]
                    const matrix_translate_y = coordinates[5]

                    const parsed_width =
                      parseFloat(Math.abs(matrix_scale_x)) *
                      parseFloat(attribs.width)
                    const parsed_height =
                      parseFloat(Math.abs(matrix_scale_y)) *
                      parseFloat(attribs.height)

                    const parsed_matrix_scale_x = matrix_scale_x >= 0 ? 1 : -1
                    const parsed_matrix_scale_y = matrix_scale_y >= 0 ? 1 : -1

                    attribs.width = parsed_width
                    attribs.height = parsed_height

                    attribs.transform = `matrix(${parsed_matrix_scale_x},0,0,${parsed_matrix_scale_y},${matrix_translate_x},${matrix_translate_y})`

                    attribs.parsed = 'true'
                  }
                }
              },
            })}
        </div>
      </div>
    )

    return html
  }

  render() {
    const { loading } = this.state
    return (
      <>
        {loading === true && <JujoLoading />}
        {loading === false && (
          <div className={classNames('')}>{this.drawSvg()}</div>
        )}
      </>
    )
  }
}

export default connect(mapStateToProps, actionCreators)(JujoSVGPreviewComponent)
