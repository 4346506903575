import opentype from 'opentype.js'
import { httpPost } from '../../services/apiService'
import { mqDesktop } from '../mediaquery'
import { updatedRectBorder } from './rect_border'

export async function parseSvg(_sender, field, file) {
  const { defValue } = field.fileOwner
  const owner_id = defValue

  const formData = new FormData()
  formData.append('file', file)
  formData.append('entity', field.relatedEntity)
  formData.append('visibility', field.visibility)
  formData.append('owner_id', owner_id)

  const result = await httpPost(`${process.env.apiUrl}svg/parse`, formData)

  return result
}

export function bindSvgDragActionToImageElement(_svg_id, imageElement) {
  const isDesktop = mqDesktop()
  if (!isDesktop) return
  const transform = imageElement.transform.baseVal

  let currentX
  let currentY
  let isDragging = false

  function startDrag(event) {
    if (event.type === 'mousedown') {
      currentX = event.clientX
      currentY = event.clientY
    } else if (event.type === 'touchstart') {
      currentX = event.targetTouches[0].clientX
      currentY = event.targetTouches[0].clientY
    }

    isDragging = true
    document.addEventListener('mousemove', drag)
    document.addEventListener('touchmove', drag)
  }

  function drag(event) {
    if (!isDragging) return

    let diffX
    let diffY

    if (event.type === 'mousemove') {
      diffX = event.clientX - currentX
      diffY = event.clientY - currentY
      currentX = event.clientX
      currentY = event.clientY
    } else if (event.type === 'touchmove') {
      diffX = event.targetTouches[0].clientX - currentX
      diffY = event.targetTouches[0].clientY - currentY
      currentX = event.targetTouches[0].clientX
      currentY = event.targetTouches[0].clientY
    }

    // Get the matrix for the first transform in the list
    const { matrix } = transform[0]

    // Calculate the new x and y position of the image
    const x = matrix.e + diffX
    const y = matrix.f + diffY

    // Update the e and f values of the matrix
    matrix.e = x
    matrix.f = y

    // Set the matrix of the transform
    transform[0].setMatrix(matrix)

    updatedRectBorder(imageElement)
  }

  function endDrag() {
    isDragging = false
    document.removeEventListener('mousemove', drag)
    document.removeEventListener('touchmove', drag)
  }

  imageElement.addEventListener('mousedown', startDrag)
  imageElement.addEventListener('touchstart', e => {
    const isPinch = e.touches.length >= 2
    if (!isPinch) {
      startDrag(e)
    }
  })

  document.addEventListener('mouseup', endDrag)
  document.addEventListener('touchend', endDrag)
}

export function bindSvgDragActionToTextElement(svg_id, element) {
  const isDesktop = mqDesktop()
  if (!isDesktop) return
  function onDragStart(e) {
    const { target } = e
    const { nodeName } = target

    const drag_element = nodeName === 'tspan' ? target.closest('text') : target

    const svg_element = document.getElementById(svg_id)
    const ctm = svg_element.getScreenCTM().inverse()

    // eslint-disable-next-line no-inner-declarations
    function getMousePosition(ev) {
      const event = ev.touches ? ev.touches[0] : ev
      const svg_pt = svg_element.createSVGPoint()
      svg_pt.x = event.clientX
      svg_pt.y = event.clientY
      return svg_pt.matrixTransform(ctm)
    }

    const offset = getMousePosition(e)
    offset.x -= parseFloat(drag_element.getAttributeNS(null, 'x') || 0)
    offset.y -= parseFloat(drag_element.getAttributeNS(null, 'y') || 0)

    let selection_locked = true

    // eslint-disable-next-line no-inner-declarations
    function onDrag(event) {
      // event.preventDefault()

      if (selection_locked) {
        const coord = getMousePosition(event)
        if (drag_element) {
          const new_x = coord.x - offset.x
          drag_element.setAttributeNS(null, 'x', new_x)
          drag_element.setAttributeNS(null, 'y', coord.y - offset.y)

          drag_element.childNodes.forEach(item => {
            item.setAttributeNS(null, 'x', new_x)
          })

          updatedRectBorder(drag_element)
        }
      }
    }
    document.addEventListener('mousemove', onDrag)
    document.addEventListener('touchmove', onDrag)

    // eslint-disable-next-line no-inner-declarations
    function onEndDrag() {
      if (selection_locked) {
        drag_element.removeEventListener('mousemove', onDrag)
        drag_element.onmouseup = null

        selection_locked = false
      }
    }
    drag_element.addEventListener('mouseup', onEndDrag)
    drag_element.addEventListener('touchend', onEndDrag)
    drag_element.addEventListener('touchleave', onEndDrag)
    drag_element.addEventListener('touchcancel', onEndDrag)
  }

  element.classList.add('cu-move')
  element.addEventListener('mousedown', onDragStart)

  element.addEventListener('touchstart', e => {
    const isPinch = e.touches.length >= 2
    if (!isPinch) {
      onDragStart(e)
    }
  })
}

export function getSvgTextSelection(element) {
  const selected_nodes = element.querySelectorAll('.selected-node')
  if (selected_nodes.length === 0) return element.querySelectorAll('span')

  return selected_nodes
}

export function parseFontFace(fface) {
  const { nodeValue } = fface

  const splitted = nodeValue
    .replace('@font-face {', '')
    .replace('}', '')
    .replaceAll('\n', '')
    .replaceAll(/\s/g, '')
    .slice(0, -1)
    .split(';')

  const parsed_fface = {}
  for (let i = 0; i !== splitted.length; i += 1) {
    const record = splitted[i]
    const divider_index = record.indexOf(':')
    const parts = [
      record.slice(0, divider_index),
      record.slice(divider_index + 1),
    ]
    const key = parts[0]
    let value = parts[1]

    if (key === 'font-family') {
      value = value.slice(1, -1)
    } else if (key === 'src') {
      value = value.replace("url('", '').replace("')", '')
    }

    parsed_fface[key] = value
  }

  return parsed_fface
}

export function extractFontFace(style) {
  const fface = style ? parseFontFace(style.childNodes[0]) : null
  return fface
}

export function textWidth(font, text, fsize, lspacing) {
  const fontSize = fsize || 72
  const kerning = true
  const fontScale = (1 / font.unitsPerEm) * fontSize

  let width = 0

  const glyphs = font.stringToGlyphs(text)
  for (let i = 0; i < glyphs.length; i += 1) {
    const glyph = glyphs[i]

    if (glyph.advanceWidth) {
      width += glyph.advanceWidth * fontScale
    }

    if (kerning && i < glyphs.length - 1) {
      const kerningValue = font.getKerningValue(glyph, glyphs[i + 1])
      width += kerningValue * fontScale
    }

    if (lspacing) {
      width += lspacing * fontSize
    }
  }
  return width
}

export async function textToPath(textContent, x, y, font, fsize, fill) {
  const { unitsPerEm } = font
  const ratio = fsize / unitsPerEm

  const [ascender, descender] = [font.ascender, Math.abs(font.descender)]
  const ascenderAbs = Math.ceil(ascender * ratio)
  const lineHeight = (ascender + descender) * ratio

  const height = (ascender + descender) * ratio
  const font2CanvasRatio = (1 / lineHeight) * height

  const baselineY = y + ascenderAbs * font2CanvasRatio

  const path = font.getPath(textContent, x, baselineY, fsize, {
    kerning: true,
  })

  path.fill = fill || '#000'
  // eslint-disable-next-line no-await-in-loop
  const rasted = await path.toSVG()
  return rasted
}

export async function rasterize(svg_obj) {
  const text_list = Array.from(svg_obj.querySelectorAll('text'))

  const loaded_fonts = {}
  for (let k = 0; k !== text_list.length; k += 1) {
    const text_element = text_list[k]
    const rows = text_element.childNodes

    const rasted_list = []

    for (let i = 0; i !== rows.length; i += 1) {
      const row = rows[i]
      const chars = row.childNodes

      for (let j = 0; j !== chars.length; j += 1) {
        const char = chars[j]
        const { textContent, style } = char
        if (textContent.trim() !== '' && style) {
          const { fontFamily, fontSize } = style
          if (fontFamily) {
            const char_bbox = char.getBBox()
            const { x, y } = char_bbox

            const fsize = parseFloat(fontSize.replace('px', '')).toFixed(2)
            const fill = char.getAttribute('fill')

            const style_obj = document.getElementById(fontFamily)
            const fface = extractFontFace(style_obj)
            if (fface) {
              const { src } = fface

              let font = loaded_fonts[fontFamily]
              if (font === undefined) {
                // eslint-disable-next-line no-await-in-loop
                font = await opentype.load(src)
                loaded_fonts[fontFamily] = font
              }

              // eslint-disable-next-line no-await-in-loop
              const rasted = await textToPath(
                textContent,
                x,
                y,
                font,
                fsize,
                fill
              )
              rasted_list.push(rasted)
            }
          }
        }
      }
    }

    text_element.parentNode.innerHTML = rasted_list.join('')
  }
}

export function extractBaseAttributes(element) {
  const x = element.getAttribute('x')
  const y = element.getAttribute('y')
  const f_family = element.getAttribute('font-family')
  const f_size = element.getAttribute('font-size')
  const l_spacing = element.getAttribute('letter-spacing')

  return {
    x,
    y,
    f_family,
    f_size,
    l_spacing,
  }
}

export function addMissingAttributes(element, row_attributes) {
  const y = element.getAttribute('y')

  const line_height = (parseFloat(y) - parseFloat(row_attributes.y)).toFixed(2)
  // eslint-disable-next-line no-param-reassign
  row_attributes.l_height = line_height

  return row_attributes
}

export function execToForeignObject(element) {
  // eslint-disable-next-line no-use-before-define
  const foreign_object = toForeignObject(element)
  element.parentNode.replaceChild(foreign_object, element)
}

export function toSvgText(foreign_object, row_attributes) {
  const { x, y, l_height, f_family, f_size, l_spacing } = row_attributes

  const text_area = foreign_object.getElementsByTagName('textarea')[0]
  const { value } = text_area
  const rows = value.split('\n')

  const text_element = document.createElementNS(
    'http://www.w3.org/2000/svg',
    'text'
  )
  text_element.setAttribute('x', x)
  text_element.setAttribute('y', y)
  text_element.setAttribute('parsed', true)
  text_element.classList.add('cu-pointer')

  for (let i = 0; i !== rows.length; i += 1) {
    const row_value = rows[i]
    const tspan = document.createElementNS(
      'http://www.w3.org/2000/svg',
      'tspan'
    )
    tspan.setAttribute('x', x)
    tspan.setAttribute(
      'y',
      (parseFloat(l_height) * i + parseFloat(y)).toFixed(2)
    )
    tspan.setAttribute('font-family', f_family)
    tspan.setAttribute('font-size', f_size)
    tspan.setAttribute('letter-spacing', l_spacing)
    tspan.textContent = row_value

    text_element.appendChild(tspan)
  }
  text_element.addEventListener('click', () => {
    execToForeignObject(text_element)
  })
  foreign_object.parentNode.replaceChild(text_element, foreign_object)
}

export function toForeignObject(element) {
  let row_attributes = {}

  function onSaveClicked(foreign_object) {
    toSvgText(foreign_object, row_attributes)
  }

  const g_coordinates = element.parentNode.getBBox()

  const { children } = element
  const rows = []
  for (let i = 0; i !== children.length; i += 1) {
    const tspan = children[i]
    const { textContent } = tspan
    rows.push(textContent)
    if (i === 0) {
      row_attributes = extractBaseAttributes(tspan)
    } else if (i === 1) {
      addMissingAttributes(tspan, row_attributes)
    }
  }

  const foreign_object = document.createElementNS(
    'http://www.w3.org/2000/svg',
    'foreignObject'
  )

  const foreign_padding = 20
  foreign_object.style.x = (
    parseFloat(g_coordinates.x) - foreign_padding
  ).toFixed(2)
  foreign_object.style.y = (
    parseFloat(g_coordinates.y) - foreign_padding
  ).toFixed(2)
  foreign_object.style.width = (
    parseFloat(g_coordinates.width) +
    2 * foreign_padding
  ).toFixed(2)
  foreign_object.style.height = (
    parseFloat(g_coordinates.height) +
    2 * foreign_padding
  ).toFixed(2)

  const body_element = document.createElement('body')
  body_element.setAttribute('xmlns', 'http://www.w3.org/1999/xhtml')
  body_element.style.background = 'transparent'

  const container = document.createElement('div')

  const text_area = document.createElement('textarea')
  text_area.value = rows.join('\n')
  text_area.style.width = `${(parseFloat(g_coordinates.width) - 2).toFixed(
    2
  )}px`
  text_area.style.height = `${parseFloat(g_coordinates.height).toFixed(2)}px`
  text_area.style.background = 'transparent'
  text_area.style.fontFamily = row_attributes.f_family
  text_area.style.fontSize = row_attributes.f_size
  text_area.style.letterSpacing = `${row_attributes.l_spacing}px`
  text_area.style.lineHeight = `${row_attributes.l_height}px`
  text_area.style.boxShadow = 'rgb(0 0 0 / 20%) 0px 0px 0px 1px'
  text_area.style.position = 'absolute'
  text_area.style.left = `${foreign_padding}px`
  text_area.style.top = `${foreign_padding}px`
  text_area.style.margin = '-2px 1px'
  text_area.style.padding = 0

  const save_btn = document.createElement('div')
  save_btn.classList.add(
    'background-image',
    'check-icon',
    'theme-svg',
    'cu-pointer',
    'position-absolute',
    'border',
    'border-color-1'
  )
  save_btn.style.width = '20px'
  save_btn.style.height = '20px'
  save_btn.style.bottom = '0'
  save_btn.style.right = '0'
  save_btn.addEventListener('click', () => {
    onSaveClicked(foreign_object)
  })

  container.appendChild(text_area)
  container.appendChild(save_btn)

  body_element.appendChild(container)
  foreign_object.appendChild(body_element)

  return foreign_object
}

export function bindClickActionToTextAreaElement(svg_id, element) {
  element.classList.add('cu-pointer')
  element.addEventListener('click', () => {
    execToForeignObject(element)
  })
}
