import React from 'react'
import loadable from '@loadable/component'

import { connect } from 'react-redux'

import * as actionCreators from '../../../store/actions'
import { mapStateToProps } from '../../../utils'

const JujoParsableContentComponent = loadable(() =>
  // eslint-disable-next-line import/no-cycle
  import('./parsable_content')
)

export class JujoPageElementComponent extends React.Component {
  constructor(props) {
    super(props)
    this.state = {}
  }

  componentWillUnmount() {
    // fix Warning: Can't perform a React state update on an unmounted component
    this.setState = () => {}
  }

  render() {
    const { session, slug } = this.props
    const { session_data } = session
    const { jujo_page } = session_data
    const { elements } = jujo_page

    const el = elements[slug] || ''

    return <JujoParsableContentComponent html={el} />
  }
}

export default connect(
  mapStateToProps,
  actionCreators
)(JujoPageElementComponent)
