const customActionTypesMap = {
  info_box: 'info_box',
  interactive_action: 'interactive_action',
  injectable_action: 'injectable_action',
  server_action: 'server_action',
  open_window_action: 'open_window_action',
  open_external_url_action: 'open_external_url_action',
}

export default customActionTypesMap
