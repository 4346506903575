import React from 'react'
import { connect } from 'react-redux'
import * as actionCreators from '../../../../../../src/store/actions'
import { mapStateToProps } from '../../../../../../src/utils'
import JujoLoading from '../../../../../../src/components/loading'

const classNames = require('classnames')

export class OssigenoPurges extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      initialized: false,
    }
  }

  componentWillUnmount() {
    // fix Warning: Can't perform a React state update on an unmounted component
    this.setState = () => {}
  }

  componentDidMount = async () => {}

  handleModify = row => {
    console.log('row', row)
  }

  drawPurges = () => {
    const { session } = this.props
    const { session_data } = session
    const { purges } = session_data || []

    const html = []
    html.push(
      <div className={classNames('row p-2 my-1 bg-light')} key="purges_columns">
        <div className={classNames('col-12 col-md-1')} />
        <div className={classNames('col-12 col-md-1')}>Barcode</div>
        <div className={classNames('col-12 col-md-2')}>Isrc</div>
        <div className={classNames('col-12 col-md-4')}>Info</div>
        <div className={classNames('col-12 col-md-3')}>Titolo traccia</div>
        <div className={classNames('col-12 col-md-1')}>Negozio</div>
      </div>
    )

    for (let i = 0; i < purges.length; i += 1) {
      const { barcode, isrc, track_title, match_info, store } = purges[i] || {}

      html.push(
        <div className={classNames('row my-1 bg-light')} key={`purges_${i}`}>
          <div className={classNames('col-12 col-md-1')}>
            <div
              className={classNames('edit-icon theme-svg')}
              label="edit"
              role="button"
              tabIndex="0"
              onClick={() => {
                this.handleModify(purges[i])
              }}
              onKeyPress={() => {
                this.handleModify(purges[i])
              }}
              style={{
                backgroundSize: '20px',
                height: '28px',
                width: '20px',
                backgroundPosition: 'center center',
                backgroundRepeat: 'no-repeat',
              }}
            />
          </div>
          <div className={classNames('col-12 col-md-1')}>{barcode}</div>
          <div className={classNames('col-12 col-md-2')}>{isrc}</div>
          <div className={classNames('col-12 col-md-4')}>{match_info}</div>
          <div className={classNames('col-12 col-md-3')}>{track_title}</div>
          <div className={classNames('col-12 col-md-1')}>{store}</div>
        </div>
      )
    }

    return html
  }

  render() {
    const { initialized } = this.state
    const { session } = this.props
    const { session_data } = session
    const { purges } = session_data || []
    console.log('initialized', initialized)
    if (!initialized) {
      ;<JujoLoading />
    }

    if (purges.length === 0) {
      return (
        <div
          className={classNames('text-center my-4 text-secondary fst-italic')}
        >
          Non ci sono elementi da epurare...
        </div>
      )
    }

    return <div className={classNames('')}>{this.drawPurges()}</div>
  }
}
export default connect(mapStateToProps, actionCreators)(OssigenoPurges)
