import React from 'react'
import { connect } from 'react-redux'
import uuid from 'react-uuid'

import * as actionCreators from '../../../../store/actions'
import { mapStateToProps } from '../../../../utils'
import { GenericField } from './genericField'

const classNames = require('classnames')

export class ComputedField extends GenericField {
  renderField = () => {
    const { fieldValue } = this.state
    const { field } = this.props
    const { prefix } = field

    const html = []
    html.push(
      <div key={uuid()} className={classNames('d-flex')}>
        {prefix && (
          <div className={classNames('bg-1 fc-white px-2 mx-1')}>{prefix}</div>
        )}
        <div>{fieldValue || field.defValue}</div>
      </div>
    )
    return html
  }
}

export default connect(mapStateToProps, actionCreators)(ComputedField)
