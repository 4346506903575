import React from 'react'
import { connect } from 'react-redux'

import * as actionCreators from '../../../../store/actions'
import { mapStateToProps, translate } from '../../../../utils'
import { GenericFilter } from './genericFilter'

const classNames = require('classnames')

export class DateFilter extends GenericFilter {
  constructor(props) {
    super(props)
    this.fromDateRef = React.createRef()
    this.toDateRef = React.createRef()
  }

  formatDate = date => {
    const year = date.getFullYear()
    const month = (date.getMonth() + 1).toString().padStart(2, '0')
    const day = date.getDate().toString().padStart(2, '0')
    return `${year}-${month}-${day}`
  }

  assignDate = (from, to) => {
    this.fromDateRef.current.value = from
    this.toDateRef.current.value = to
    this.composeValue()
  }

  onPrevMonthClick = () => {
    const today = new Date()
    const year = today.getFullYear()
    const month = today.getMonth() - 1
    const firstDay = new Date(year, month, 1)
    const lastDay = new Date(year, month + 1, 0)

    const fromDateString = this.formatDate(firstDay)
    const toDateString = this.formatDate(lastDay)

    this.assignDate(fromDateString, toDateString)
  }

  onThisMonthClick = () => {
    const today = new Date()
    const firstDayOfMonth = new Date(today.getFullYear(), today.getMonth(), 1)

    const fromDateString = this.formatDate(firstDayOfMonth)
    const toDateString = this.formatDate(today)

    this.assignDate(fromDateString, toDateString)
  }

  onTodayClick = () => {
    const today = new Date()

    const todayDateString = this.formatDate(today)

    this.assignDate(todayDateString, todayDateString)
  }

  composeValue = () => {
    const updatedFieldArrayValue = []
    const fromDateString = this.fromDateRef.current.value
    const toDateString = this.toDateRef.current.value

    if (fromDateString) {
      updatedFieldArrayValue.push(fromDateString)
    }
    if (toDateString) {
      updatedFieldArrayValue.push(toDateString)
    }

    this.updateFilter(updatedFieldArrayValue.join(','))
  }

  renderFilter = () => {
    const { filter } = this.props
    const { filterValue } = this.state

    const parsedFilterValue = filterValue ? filterValue.split(',') : []

    return (
      <div key={`filter_${filter.label}`} className={classNames('date-field')}>
        <input
          type="date"
          className={classNames('form-control rounded-4 shadow-sm border-0')}
          defaultValue={parsedFilterValue[0] || ''}
          onBlur={this.composeValue}
          ref={this.fromDateRef}
        />
        <input
          type="date"
          className={classNames(
            'form-control rounded-4 shadow-sm border-0 mt-2'
          )}
          defaultValue={parsedFilterValue[1] || ''}
          onBlur={this.composeValue}
          ref={this.toDateRef}
        />
        <div className={classNames('py-3 border-bottom')}>
          <div
            className={classNames(
              'mt-2 bg-1 rounded-5 fc-white text-center p-1'
            )}
            role="button"
            tabIndex={0}
            onClick={this.onTodayClick}
            onKeyPress={this.onTodayClick}
          >
            {translate('today')}
          </div>
          <div
            className={classNames(
              'mt-2 bg-1 rounded-5 fc-white text-center p-1'
            )}
            role="button"
            tabIndex={0}
            onClick={this.onThisMonthClick}
            onKeyPress={this.onThisMonthClick}
          >
            {translate('this_month')}
          </div>
          <div
            className={classNames(
              'mt-2 bg-1 rounded-5 fc-white text-center p-1'
            )}
            role="button"
            tabIndex={0}
            onClick={this.onPrevMonthClick}
            onKeyPress={this.onPrevMonthClick}
          >
            {translate('prev_month')}
          </div>
        </div>
      </div>
    )
  }
}

export default connect(mapStateToProps, actionCreators)(DateFilter)
