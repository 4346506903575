import React from 'react'
import { connect } from 'react-redux'
import uuid from 'react-uuid'
import gridCustomComponentsMap from '../../../../enums/gridCustomComponentsMap'

import * as apiService from '../../../../services/apiService'

import * as actionCreators from '../../../../store/actions'
import CustomButton from '../../components/customButton'
import ViewButton from '../../components/viewButton'
import EditButton from '../../components/editButton'
import DeleteButton from '../../components/deleteButton'

import countries from '../../../../translations/countries.json'

import {
  expandCollapseRow,
  getColumnWidth,
  getGridDefinition,
  getGridEditor,
} from '../../helper/gridFunctions'
import { getStoredGridProps } from '../../helper/lsInteractionFunctions'
import sourcesMap from '../../../../enums/sourcesMap'
import conditionCheckTypesMap from '../../../../enums/conditionCheckTypesMap'
import requestTypesMap from '../../../../enums/requestTypesMap'
import {
  baseRequestObject,
  parseEndpoint,
} from '../../../../services/servicesHelper'
import apiCallMap from '../../../../enums/apiCallMap'
import gridEditorTypesMap from '../../../../enums/gridEditorTypesMap'
import {
  checkGenericCondition,
  cloneObj,
  mapStateToProps,
  mysqlDateToIta,
  translate,
} from '../../../../utils'
import customActionTypesMap from '../../../../enums/customActionTypesMap'
import { overridedFunction } from '../../../../utils/overrides'
import { JujoLoading } from '../../../loading'

const classNames = require('classnames')

export class GridRowComponent extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      loading: true,
      overrided_functions: {},
    }
  }

  componentWillUnmount() {
    // fix Warning: Can't perform a React state update on an unmounted component
    this.setState = () => {}
  }

  componentDidMount = async () => {
    this.getOverridedFunctions()
  }

  componentDidUpdate = async () => {}

  getOverridedFunctions = async () => {
    const gridDefinition = getGridDefinition(this.props)
    const overridable_functions = ['getColumnBgColor']
    const overrided_functions = {}

    for (let i = 0; i !== overridable_functions.length; i += 1) {
      const funcName = overridable_functions[i]

      const { override } = gridDefinition

      // eslint-disable-next-line no-await-in-loop
      const o_func = await overridedFunction(override, funcName)

      if (overridable_functions) {
        overrided_functions[funcName] = o_func
      }
    }
    this.setState({ overrided_functions, loading: false })
  }

  verifyCellConditions = (row, conditions) => {
    const { authentication } = this.props
    const { user } = authentication
    if (!conditions) return true

    let errors = false
    for (let j = 0; j !== conditions.length; j += 1) {
      const condition = conditions[j]
      const { source, relatedField, checkType, check } = condition

      let relFieldValue = null
      if (source === sourcesMap.dynamicField) {
        relFieldValue = row[relatedField]
      } else if (source === sourcesMap.user) {
        relFieldValue = user[relatedField]
      }

      if (checkType === conditionCheckTypesMap.equals_to) {
        if (Array.isArray(check)) {
          let meet = false
          for (let x = 0; x !== check.length; x += 1) {
            const currentCheck = check[x]
            if (currentCheck === relFieldValue) {
              meet = true
              break
            }
          }

          errors = !meet
        } else {
          errors = check !== relFieldValue
        }
      } else if (checkType === conditionCheckTypesMap.not_equals_to) {
        const relFieldValueString = relFieldValue
          ? relFieldValue.toString()
          : ''
        errors = relFieldValueString === check.toString()
      } else if (checkType === conditionCheckTypesMap.contains) {
        errors = !check.includes(relFieldValue)
      } else if (checkType === conditionCheckTypesMap.greater_than) {
        errors = parseInt(relFieldValue, 10) <= parseInt(check, 10)
      }

      if (errors === true) {
        break
      }
    }

    return !errors
  }

  handleEdit = async row => {
    const {
      entity,
      dynamicForm,
      parentData,
      updateDynamicFormIntoRedux,
      editableComponentProps,
    } = this.props

    const editor = getGridEditor(this.props)

    if (editor === gridEditorTypesMap.form) {
      const dForm = cloneObj(dynamicForm)
      dForm.entity = entity
      dForm.type = requestTypesMap.update
      dForm.source = row
      dForm.parent = parentData ? parentData.data : {}
      dForm.visible = true
      await updateDynamicFormIntoRedux(dForm)
    }
    if (editor === gridEditorTypesMap.editable_component) {
      const { enterEditMode } = editableComponentProps
      enterEditMode(row, false)
    }
  }

  handleView = async row => {
    const {
      entity,
      dynamicForm,
      parentData,
      updateDynamicFormIntoRedux,
      editableComponentProps,
    } = this.props

    const editor = getGridEditor(this.props)

    if (editor === gridEditorTypesMap.form) {
      const dForm = { ...dynamicForm }
      dForm.entity = entity
      dForm.type = requestTypesMap.view
      dForm.source = row
      dForm.parent = parentData ? parentData.data : {}
      dForm.visible = true
      await updateDynamicFormIntoRedux(dForm)
    }
    if (editor === gridEditorTypesMap.editable_component) {
      const { enterEditMode } = editableComponentProps
      enterEditMode(row, true)
    }
  }

  handleDelete = async row => {
    const { gridComponent } = this.props
    const { loadGrid } = gridComponent
    const reqObj = this.composeRequest(row)
    const { parsedEp } = reqObj

    const response = await apiService.httpDelete(
      `${process.env.apiUrl}${parsedEp}`
    )

    const { status } = response
    if (status === 200 || status === 201) {
      loadGrid()
    }
  }

  executeRowAction = async (row, action) => {
    const {
      type,
      specialized,
      actionName,
      actionPath,
      relatedEntity,
      relatedField,
      customizations,
      actName,
    } = action

    if (type === customActionTypesMap.info_box) {
      const { updateInfoBox } = this.props

      const info_box = {
        relatedEntity,
        relatedField,
        actName,
        customizations,
        subject_id: row[relatedField],
      }

      updateInfoBox(info_box)
    } else if (type === customActionTypesMap.injectable_action) {
      let injected_actions
      if (specialized) {
        injected_actions =
          await require(`../../../../../jujo_specializations/src/${process.env.client}/${actionPath}`)
      }
      injected_actions[actionName](this)
    }
  }

  composeRequest = row => {
    const { specialization, entity, environment, authentication } = this.props
    const { entities } = specialization.config
    const config = entities[entity]
    const { jujo_grid } = config
    const { apis } = jujo_grid

    const { deleteData } = apis

    const { apiCall, requestType, defaultFilters, placeholderMapping } =
      deleteData

    const requestData = baseRequestObject(
      apiCallMap[apiCall],
      entity,
      requestType,
      environment,
      authentication
    )
    requestData.defaultFilters = defaultFilters || []
    requestData.placeholderMapping = placeholderMapping || []
    requestData.dynamicFields = row
    const parsedEp = parseEndpoint(requestData)

    return {
      parsedEp,
      data: [],
    }
  }

  getComponent = (c_def, row) => {
    const { environment } = this.props
    const { permissions } = environment || {}

    const { component: cName, conditions } = c_def

    let conditionsMet = true
    if (conditions) {
      conditionsMet = this.verifyCellConditions(row, conditions)
    }

    if (conditionsMet) {
      if (
        cName === gridCustomComponentsMap.view_button &&
        permissions.includes('r')
      ) {
        return (
          <div className={classNames('pe-2')}>
            <ViewButton row={row} handleView={this.handleView} />
          </div>
        )
      }
      if (
        cName === gridCustomComponentsMap.edit_button &&
        permissions.includes('u')
      ) {
        return (
          <div className={classNames('pe-2')}>
            <EditButton row={row} handleEdit={this.handleEdit} />
          </div>
        )
      }
      if (
        cName === gridCustomComponentsMap.delete_button &&
        permissions.includes('d')
      ) {
        return (
          <div className={classNames('pe-2')}>
            <DeleteButton
              row={row}
              params={c_def}
              handleDelete={this.handleDelete}
            />
          </div>
        )
      }
      if (
        cName === gridCustomComponentsMap.custom_button &&
        permissions.includes('x')
      ) {
        const { gridComponent } = this.props
        return (
          <div className={classNames('pe-2')}>
            <CustomButton
              row={row}
              params={c_def}
              gridComponent={gridComponent}
            />
          </div>
        )
      }
    }
    return <></>
  }

  handleCheckboxClicked = (e, id) => {
    const { selectedRows, gridComponent } = this.props
    const { updatedSelectedRows } = gridComponent
    const { target } = e
    const { checked } = target

    const updated_selected_rows = cloneObj(selectedRows)
    const { idList } = updated_selected_rows

    const id_idx = idList.indexOf(id)

    if (checked === true && id_idx < 0) {
      idList.push(id)
    }
    if (checked === false && id_idx >= 0) {
      idList.splice(id_idx, 1)
    }

    updatedSelectedRows(updated_selected_rows)
  }

  renderSelectFeature = () => {
    const { row, specialization, environment, selectedRows } = this.props
    const gridDefinition = getGridDefinition(this.props)
    const { identifier, selectableRows } = gridDefinition

    const { translations } = specialization
    const { texts } = translations
    const { locale } = environment

    const { idList } = selectedRows

    const default_selected = idList.indexOf(row[identifier]) >= 0

    const html = []

    if (selectableRows && selectableRows.enabled === true) {
      html.push(
        <div
          key={`${row.id}_${default_selected}`}
          className={classNames(
            'd-flex justify-content-between align-items-center bg-light py-2'
          )}
        >
          <div className={classNames('d-inline-flex me-1 d-md-none')}>
            {texts[locale].select || 'select'}
          </div>
          <div style={{ width: '50px' }}>
            <input
              type="checkbox"
              defaultChecked={default_selected}
              onClick={e => this.handleCheckboxClicked(e, row[identifier])}
            />
          </div>
        </div>
      )
    }

    return html
  }

  verifyColumnAction = col => {
    const { action } = col

    let has_action = false

    if (action && Object.keys(action).length > 0) {
      const { conditions } = action
      const conditionMet = conditions
        ? checkGenericCondition(this, conditions)
        : true

      has_action = conditionMet
    }

    return has_action
  }

  getColumnBgColor = (idx, custom_bg_color) => {
    const { overrided_functions } = this.state
    const o_func = overrided_functions.getColumnBgColor

    let bg_color = idx % 2 !== 0 ? 'bg-light' : 'bg-white'
    if (o_func) {
      const { injected_actions, actionName } = o_func
      bg_color = injected_actions[actionName](this, idx)
    }
    bg_color =
      custom_bg_color === undefined || custom_bg_color === ''
        ? bg_color
        : custom_bg_color
    return bg_color
  }

  render() {
    const { loading } = this.state
    const { specialization, filteredColumns, environment, row } = this.props
    const { translations } = specialization
    const { texts } = translations
    const { locale } = environment

    const gridDefinition = getGridDefinition(this.props)
    const gridProps = getStoredGridProps(this.props)

    const { expandableRowContent } = gridDefinition
    const identifier = row[gridDefinition.identifier]

    const expanded =
      expandableRowContent && gridProps.rows && gridProps.rows[identifier]
        ? gridProps.rows[identifier].expanded
        : false

    return (
      <>
        {loading === true && <JujoLoading />}
        {loading === false && (
          <>
            {this.renderSelectFeature()}
            {expandableRowContent && (
              <div
                key={`expandable_${identifier}`}
                className={classNames(
                  'd-none d-md-flex justify-content-between align-self-center'
                )}
              >
                <div
                  className={classNames(
                    'btn theme-svg',
                    expanded ? 'collapse-icon' : 'expand-icon'
                  )}
                  style={{
                    backgroundRepeat: 'no-repeat',
                    backgroundPosition: 'center',
                    backgroundSize: '15px',
                    height: '15px',
                  }}
                  label="expande collapse"
                  role="button"
                  tabIndex={0}
                  onClick={async () => {
                    await expandCollapseRow(identifier, !expanded, this.props)
                  }}
                  onKeyPress={async () => {
                    await expandCollapseRow(identifier, !expanded, this.props)
                  }}
                />
              </div>
            )}
            {filteredColumns.map((col, idx) => {
              const {
                name,
                alias,
                component,
                conditions,
                subComponents,
                prefix,
                font_color,
                class_font_color,
                bg_color,
                custom_class,
              } = col
              const renderName = alias || name
              const columnWidth = getColumnWidth(filteredColumns, name)
              let conditionsMet = true
              if (conditions) {
                conditionsMet = this.verifyCellConditions(row, conditions)
              }

              let cellValue = row[name] || ''

              if (typeof cellValue === 'object') {
                cellValue = JSON.stringify(cellValue)
              }

              const has_action = this.verifyColumnAction(col)

              return (
                <div
                  key={uuid()}
                  className={classNames(
                    'py-2 d-flex justify-content-between ',
                    this.getColumnBgColor(idx, bg_color),
                    has_action === true ? (class_font_color !== undefined ? class_font_color : 'fc-2') : '',
                    custom_class
                  )}
                  style={{
                    cursor: has_action === true ? 'pointer' : 'default',
                    color:
                      font_color !== undefined
                        ? `${font_color}`
                        : '',
                  }}
                  role="button"
                  tabIndex={0}
                  onClick={
                    has_action === true
                      ? () => this.executeRowAction(row, col.action)
                      : null
                  }
                  onKeyPress={
                    has_action === true
                      ? () => this.executeRowAction(row, col.action)
                      : null
                  }
                >
                  <div className={classNames('d-inline-flex me-1 d-md-none')}>
                    {texts[locale][renderName] || renderName}
                  </div>
                  <div
                    className={classNames(
                      'd-inline-flex align-self-center text-end text-md-start px-2'
                    )}
                    // style={{ width: '100%' }}
                  >
                    {conditionsMet && (
                      <>
                        {!component && (
                          <div
                            className={classNames(
                              'overflow-hidden wsp-breakspace wp-md-nowrap'
                            )}
                            style={{
                              width: `min(${columnWidth}, 95%)`,
                              textOverflow: 'ellipsis',
                            }}
                            title={translate(cellValue)}
                          >
                            {prefix}
                            {translate(cellValue)}
                          </div>
                        )}
                        {component &&
                          component ===
                            gridCustomComponentsMap.server_translated && (
                            <div>{prefix + cellValue[locale]}</div>
                          )}
                        {component &&
                          component === gridCustomComponentsMap.date && (
                            <div>{prefix + mysqlDateToIta(cellValue)}</div>
                          )}
                        {component &&
                          component === gridCustomComponentsMap.boolean && (
                            <div
                              className={classNames(
                                cellValue === 1
                                  ? 'true-icon green-svg'
                                  : 'false-icon red-svg'
                              )}
                              style={{
                                height: '20px',
                                width: '20px',
                                backgroundSize: 'cover',
                                backgroundPosition: 'center',
                                backgroundRepeat: 'no-repeat',
                              }}
                            />
                          )}
                        {component &&
                          component === gridCustomComponentsMap.country && (
                            <div>{countries[locale][cellValue]}</div>
                          )}
                        {component &&
                          component === gridCustomComponentsMap.image && (
                            <>
                              {cellValue && (
                                <img
                                  alt="img"
                                  src={cellValue}
                                  style={{ maxHeight: '50px' }}
                                />
                              )}
                              {!cellValue && (
                                <div
                                  className={classNames(
                                    'img-placeholder rounded'
                                  )}
                                  style={{
                                    width: '80px',
                                    height: '45px',
                                    backgroundSize: '30px',
                                    backgroundPosition: 'center',
                                    backgroundRepeat: 'no-repeat',
                                    backgroundColor: '#e2e2e2',
                                  }}
                                />
                              )}
                            </>
                          )}
                        {component &&
                          (component === gridCustomComponentsMap.view_button ||
                            component === gridCustomComponentsMap.edit_button ||
                            component ===
                              gridCustomComponentsMap.delete_button ||
                            component ===
                              gridCustomComponentsMap.custom_button) &&
                          this.getComponent(col, row)}
                        {component &&
                          component ===
                            gridCustomComponentsMap.grouped_components && (
                            <div className={classNames('d-flex')}>
                              {subComponents.map(sc => (
                                <div key={uuid()}>
                                  {this.getComponent(sc, row)}
                                </div>
                              ))}
                            </div>
                          )}
                      </>
                    )}
                    {conditionsMet === false && <></>}
                  </div>
                </div>
              )
            })}
            {expandableRowContent && (
              <div
                key={`mobile_expandable_${identifier}`}
                className={classNames(
                  'expand-mobile-icon theme-svg d-md-none d-flex mt-2 shadow-sm'
                )}
                style={{
                  height: '30px',
                  backgroundSize: '20px',
                  backgroundRepeat: 'no-repeat',
                  backgroundPosition: 'center',
                }}
                label="expande collapse"
                role="button"
                tabIndex={0}
                onClick={async () => {
                  await expandCollapseRow(identifier, !expanded, this.props)
                }}
                onKeyPress={async () => {
                  await expandCollapseRow(identifier, !expanded, this.props)
                }}
              />
            )}
          </>
        )}
      </>
    )
  }
}

export default connect(mapStateToProps, actionCreators)(GridRowComponent)
