import React from 'react'
import { connect } from 'react-redux'
import * as actionCreators from '../../../../../../../../src/store/actions'
import { cloneObj, mapStateToProps } from '../../../../../../../../src/utils'
import * as apiService from '../../../../../../../../src/services/apiService'
import JujoLoading from '../../../../../../../../src/components/loading'
import ECSelectFieldComponent from '../../../../../../../../src/components/common/fields/selectField'
import FieldShell from '../../../../common/field_shell'

import { saveSessionToServer } from '../../../../../../../../src/services/sessionHelper'
import JujoButtonComponent from '../../../../../../../../src/components/jujo_button/index'

const classNames = require('classnames')

export class OssigenoWizardStepPurges extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      working: true,
      recognition_rows: false,
      contracts: false,
      spalmen: '',
    }
  }

  componentWillUnmount() {
    // fix Warning: Can't perform a React state update on an unmounted component
    this.setState = () => {}
  }

  fillRecognitionRows = async () => {
    const { session } = this.props
    const { session_data } = session
    const { wizard } = session_data
    const { purges } = wizard

    const recognition_rows = []
    if (purges.length > 0) {
      for (let i = 0; i < purges.length; i += 1) {
        const {
          id,
          file_type,
          isrc,
          barcode,
          custom_id,
          channel_id,
          asset_id,
          video_id,
          video_title,
          channel_name,
          store,
          label_name,
          artist_name,
          album_name,
          track_title

        } = purges[i]
        recognition_rows.push({
          i,
          fee_id: id,
          file_type,
          contract_id: 0,
          custom_id,
          channel_id,
          asset_id,
          video_id,
          video_title,
          channel_name,
          barcode,
          isrc,
          store,
          label_name,
          artist_name,
          album_name,
          track_title
        })
      }
    }
    await this.setState({ recognition_rows })
  }

  getContracts = async () => {
    const parsedEp = 'action/get/contract/get'
    const response = await apiService.httpGet(
      `${process.env.apiUrl}${parsedEp}`
    )
    const { status } = response
    if (status === 200 || status === 201) {
      const contracts = response.data
      await this.setState({ contracts })
    }
  }

  componentDidMount = async () => {
    await this.getContracts()
    await this.fillRecognitionRows()
    await this.setState({ working: false })
  }

  handlePickerChange = (name, value) => {
    const { recognition_rows } = this.state
    if (!(recognition_rows && recognition_rows.length > 0)) {
      return
    }
    const updated_recognition_rows = cloneObj(recognition_rows)

    const v_name = name.split('_')
    const i = v_name[1]

    updated_recognition_rows[i].contract_id = value
    this.setState({ recognition_rows: updated_recognition_rows })
  }

  handleSpalmenChange = (name, value) => {
    this.setState({ spalmen: value })
  }

  allPurged = () => {
    const { recognition_rows } = this.state
    let flag = true
    for (let i = 0; i < recognition_rows.length; i += 1) {
      if (recognition_rows[i].contract_id === 0) {
        flag = false
        break
      }
    }
    return flag
  }

  handleApplyPurges = async () => {
    const { recognition_rows } = this.state
    const { session } = this.props
    const { session_data } = session
    const { wizard } = session_data
    const { import_id, client_id } = wizard

    const parsedEp = 'action/post/fee/apply-purges'
    const data = {
      recognition_rows: cloneObj(recognition_rows),
      import_id,
      client_id,
    }
    await this.setState({ working: true })
    const response = await apiService.httpPost(
      `${process.env.apiUrl}${parsedEp}`,
      data
    )
    const { status } = response || 400
    if (status === 200 || status === 201) {
      if (typeof window !== 'undefined') {
        window.location.reload()
      }
      await this.setState({ recognition_rows: false })
    }
    await this.setState({ working: false })
  }

  drawSpalmen = () => {
    const { contracts, spalmen } = this.state
    const html = []
    const contractField = {
      name: `contracts_spalmen`,
      options: contracts,
    }
    html.push(
      <div
        className={classNames(
          'my-2 d-flex justify-content-end border rounded-2 p-3'
        )}
        key="draw_spalmen"
      >
        <div className="d-flex align-items-center">
          <div>
            Seleziona un contratto e clicca sui pulsanti ABBINA per abbinarlo
            gli orfani ancora da epurare:
          </div>
          <div className={classNames('text-start mx-3')}>
            <FieldShell fieldName="" withBorder>
              <ECSelectFieldComponent
                field={contractField}
                handleValueChanged={this.handleSpalmenChange}
                initialValue={spalmen}
                custom_key="spalmen"
              />
            </FieldShell>
          </div>
        </div>
      </div>
    )
    return html
  }

  handleMatch = (rowIndex, action) => {
    const { spalmen, recognition_rows } = this.state
    if (!(recognition_rows && recognition_rows.length > 0)) {
      return
    }
    const updated_recognition_rows = cloneObj(recognition_rows)
    updated_recognition_rows[rowIndex].contract_id =
      action === 'match' ? spalmen : 0
    this.setState({ recognition_rows: updated_recognition_rows })
  }

  drawPurges = () => {
    const { contracts, recognition_rows } = this.state
    const { session } = this.props
    const { session_data } = session
    const { wizard } = session_data
    const { purges, purge_grid_cols } = wizard

    const html = []
    if (!contracts) {
      return html
    }

    html.push(
      <span key="purges_columns">
        <div className={classNames('row my-2 mt-3 text-start')}>
          {purge_grid_cols.map((col, index) => (
            <div className={classNames(col.class)} key={index}>
              {col.label}
            </div>
          ))}
        </div>
        <hr />
      </span>
    )

    for (let i = 0; i < purges.length; i += 1) {
      // const {
      //   isrc,
      //   barcode,
      //   track_title,
      //   label_name,
      //   album_name,
      //   artist_name,
      // } = purges[i] || {}

      const purge_row = purges[i]
      const recognition_row = recognition_rows[i]
      const { contract_id } = recognition_row
      const contract_id_for_ui = contract_id === undefined ? 0 : contract_id
      let contract_label = ''
      if (contract_id_for_ui > 0) {
        const contract = contracts.filter(
          item => parseInt(item.value, 10) === parseInt(contract_id_for_ui, 10)
        )
        if (contract.length > 0) {
          const { label } = contract[0] || ''
          contract_label = label
        }
      }

      html.push(
        <div
          key={`purges_${i}`}
          className={classNames('row my-2 py-2')}
          style={{ backgroundColor: '#fafafa' }}
        >
          {purge_grid_cols.map((col, index) => {
            if (col.field === '*') {
              return (
                <div
                  className={classNames('col-12 col-md-1 text-start')}
                  key={index}
                >
                  {contract_label}
                </div>
              )
            }
            if (col.field === '!') {
              return (
                <div
                  className={classNames('col-12 col-md-1 text-start')}
                  key={index}
                >
                  {contract_id_for_ui === 0 && (
                    <JujoButtonComponent
                      bstyle={2}
                      bwidth="120px"
                      blabel="Abbina"
                      handleClick={() => {
                        this.handleMatch(i, 'match')
                      }}
                    />
                  )}
                  {contract_id_for_ui > 0 && (
                    <JujoButtonComponent
                      bstyle={3}
                      bwidth="120px"
                      blabel="Annulla"
                      handleClick={() => {
                        this.handleMatch(i, 'unmatch')
                      }}
                    />
                  )}
                </div>
              )
            }
            return (
              <div
                key={`youtube_watch_${index}`}
                className={classNames(`${col.class} text-start`)}
                style={{
                  overflow: 'hidden',
                  whiteSpace: 'nowrap',
                  textOverflow: 'ellipsis',
                }}
              >
                <label title={purge_row[col.field]} htmlFor="row">
                  {col.field === 'video_id' && (
                    <a
                      href={`https://www.youtube.com/watch?v=${
                        purge_row[col.field]
                      }`}
                      target="_blank"
                      rel="noreferrer"
                    >
                      {purge_row[col.field]}
                    </a>
                  )}
                  {col.field === 'channel_id' && (
                    <a
                      href={`https://www.youtube.com/channel/${
                        purge_row[col.field]
                      }`}
                      target="_blank"
                      rel="noreferrer"
                    >
                      {purge_row[col.field]}
                    </a>
                  )}

                  {col.field !== 'video_id' && col.field !== 'channel_id' && (
                    <>{purge_row[col.field]}</>
                  )}
                </label>
              </div>
            )
          })}
        </div>
      )
    }

    html.push(
      <div
        key="purges_action_button"
        className={classNames('row my-2 py-2 w-100 d-flex justify-content-end')}
      >
        <JujoButtonComponent
          bstyle={8}
          bwidth="200px"
          blabel="Applica epurazioni"
          bDisabled={!this.allPurged()}
          handleClick={() => {
            this.handleApplyPurges()
          }}
        />
      </div>
    )

    return html
  }

  handleNextStep = async () => {
    const { session, updateSession } = this.props
    const session_copy = cloneObj(session)
    const { session_id, session_data } = session_copy
    const { wizard } = session_data
    wizard.active_step = 5
    const returned_data = await saveSessionToServer(session_id, session_data)
    session_copy.session_data = returned_data
    await updateSession(session_copy)
  }

  render() {
    const { working, recognition_rows } = this.state

    return (
      <div className={classNames('d-flex flex-column text-center w-100')}>
        <div className={classNames('mb-5 fw-bold')}>Epurazione opere</div>
        <div className={classNames('bg-light-grey p-3 px-5 fs-7')}>
          {!working &&
            recognition_rows &&
            recognition_rows.length > 0 &&
            this.drawSpalmen()}
          {!working &&
            recognition_rows &&
            recognition_rows.length > 0 &&
            this.drawPurges()}
          {working && <JujoLoading />}
          {!working &&
            (!recognition_rows ||
              (recognition_rows && recognition_rows.length === 0 && (
                <div
                  className={classNames(
                    'w-100 my-4 d-flex align-items-center justify-content-center '
                  )}
                >
                  <div
                    className={classNames('green-check-icon me-2')}
                    style={{
                      width: '25px',
                      height: '25px',
                      backgroundPosition: 'center',
                      backgroundSize: 'contain',
                      backgroundRepeat: 'no-repeat',
                    }}
                  />
                  Tutte le opere risultano epurate
                </div>
              )))}
        </div>
        {recognition_rows && recognition_rows.length === 0 && (
          <div
            className={classNames(
              'mt-5 w-100 d-flex align-items-center justify-content-center'
            )}
          >
            <JujoButtonComponent
              bstyle={3}
              bwidth="200px"
              blabel="Avanti"
              handleClick={() => {
                this.handleNextStep()
              }}
            />
          </div>
        )}
      </div>
    )
  }
}
export default connect(
  mapStateToProps,
  actionCreators
)(OssigenoWizardStepPurges)
