import React from 'react'
import { connect } from 'react-redux'

import PageComponent from '../_parts/page'
import LangSwitcherComponent from '../../../lang_switcher'

import * as actionCreators from '../../../../store/actions'
import { handleChangePageClicked } from '../../helper/pageFunctions'

import './styles.scss'
import { mapStateToProps } from '../../../../utils'

const classNames = require('classnames')

export class LeftSideControllerLayout extends React.Component {
  componentWillUnmount() {
    // fix Warning: Can't perform a React state update on an unmounted component
    this.setState = () => {}
  }

  handleExpandCollapseSideController = async () => {
    const { environment, updateEnvironment } = this.props
    const { sideControllerExpanded } = environment

    const newEnvironment = { ...environment }
    newEnvironment.sideControllerExpanded = !sideControllerExpanded
    updateEnvironment(newEnvironment)
  }

  render() {
    const { environment, specialization, pageDef } = this.props
    const { sideControllerExpanded, path, locale } = environment
    const { menu } = specialization.config
    const { translations } = specialization
    const { texts } = translations

    const isMobile = !!(
      typeof window !== 'undefined' && window.outerWidth < 560
    )

    return (
      <div
        className={classNames('container-fluid d-flex p-0 m-0')}
        style={{ minHeight: '100vh' }}
      >
        <div
          className={classNames('px-0 border-end shadow position-fixed bg-1')}
          style={{
            width: sideControllerExpanded === true ? '200px' : '45px',
            transition: 'all 0.5s ease-in-out 0s',
            height: '100vh',
            zIndex: '1',
          }}
        >
          <div
            className={classNames(
              'expand-collapse-icon rounded-0 position-absolute start-100 bg-1'
            )}
            style={{
              backgroundRepeat: 'no-repeat',
              backgroundPosition: 'center',
              width: '36px',
              height: '18px',
              backgroundSize: '36px, 18px',
              zIndex: 1,
            }}
            label="clear grid"
            role="button"
            tabIndex={0}
            onClick={async () => {
              this.handleExpandCollapseSideController()
            }}
            onKeyPress={async () => {
              this.handleExpandCollapseSideController()
            }}
          />
          <div
            className={classNames('logo-white d-flex m-auto p-2 my-2')}
            label="logo"
            style={{
              maxWidth: '80%',
              height: '80px',
              backgroundRepeat: 'no-repeat',
              backgroundSize: 'contain',
              backgroundPosition: 'center',
              opacity: sideControllerExpanded ? 1 : 0,
              transition: 'all 0.5s ease-in-out 0s',
            }}
          />
          <div className={classNames('')}>
            {pageDef.menu &&
              menu[pageDef.menu].map(item => {
                const { static_name, title, url, icon } = item
                return (
                  // eslint-disable-next-line jsx-a11y/control-has-associated-label
                  <div
                    key={static_name}
                    className={classNames(
                      'd-flex fc-white ffamily-secondary align-items-center p-2 border-bottom',
                      path === url ? 'bg-dark' : ''
                    )}
                    role="button"
                    tabIndex={0}
                    onClick={() => handleChangePageClicked(url, this.props)}
                    onKeyPress={() => handleChangePageClicked(url, this.props)}
                  >
                    <div
                      className={classNames('me-1  white-svg', icon)}
                      style={{
                        backgroundRepeat: 'no-repeat',
                        backgroundPosition: 'center',
                        backgroundSize: '18px 18px',
                        // width: sideControllerExpanded ? '20px' : '100%',
                        minWidth: sideControllerExpanded ? '20px' : '100%',
                        height: '20px',
                        transition: 'all 0.5s ease 0s',
                      }}
                      label={static_name}
                    />
                    <div
                      style={{
                        opacity: sideControllerExpanded ? 1 : 0,
                        height: sideControllerExpanded ? 'auto' : 0,
                        transition: 'opacity 0.5s ease 0s',
                      }}
                    >
                      {texts[locale][title] || title}
                    </div>
                  </div>
                )
              })}
          </div>
          <div
            className={classNames(
              'position-absolute start-50 translate-middle'
            )}
            style={{ bottom: '15px', width: '100%' }}
          >
            <LangSwitcherComponent />
          </div>
        </div>
        <div
          className={classNames('p-4 p-md-5')}
          style={{
            width:
              sideControllerExpanded && !isMobile
                ? 'calc(100vw - 200px)'
                : 'calc(100vw - 45px)',
            marginLeft: sideControllerExpanded && !isMobile ? '200px' : '45px',
            transition: 'all 0.5s ease 0s',
          }}
        >
          <PageComponent />
        </div>
        <div
          className={classNames(
            'position-fixed fixed-bottom d-flex justify-content-center fs-9 shadow p-1 bg-light'
          )}
          style={{ color: '#b3b3b3' }}
        >
          <i>
            {`${texts[locale].copyright_string} ${texts[locale].version}: ${process.env.version}`}
          </i>
        </div>
      </div>
    )
  }
}

export default connect(
  mapStateToProps,
  actionCreators
)(LeftSideControllerLayout)
