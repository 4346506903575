const apiCallMap = {
  getMy: 'my/{entity}?&sort={sort}&search={search}',
  updateMy: 'my/{entity}/{id}',
  actionMy: 'my/{entity}/{action}',
  publicGetAll:
    'public/crud/{entity}?page={page}&per_page={perPage}&search={search}&sort={sort}&{advancedSearch}&',
  publicGetAllKeyValue: 'public/key-value-list/{entity}?',
  publicServerAction: 'public/action/{reqType}/{entity}/{action}?',
  publicSession: 'public/session/{id}',
  publicSessionAction: 'public/session/action/{id}',
  getAll:
    'crud/{entity}?page={page}&per_page={perPage}&search={search}&sort={sort}&{advancedSearch}&',
  getSingle: 'crud/{entity}/{id}?',
  getAllKeyValue: 'key-value-list/{entity}?',
  createRecord: 'crud/{entity}',
  deleteRecord: 'crud/{entity}/{id}',
  updateRecord: 'crud/{entity}/{id}',
  serverAction: 'action/{reqType}/{entity}/{action}?search={search}&',
  export:
    'export/{fileType}/{entity}?&search={search}&sort={sort}&{advancedSearch}&',
  genericExport: 'generic-export/{action}',
}

export default apiCallMap
