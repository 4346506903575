import React from 'react'
import { connect } from 'react-redux'
import classNames from 'classnames'

import * as actionCreators from '../../../../store/actions'
import { GenericField } from './genericField'
import { mapStateToProps } from '../../../../utils'
import requestTypesMap from '../../../../enums/requestTypesMap'

export class MultiPickerButtonsField extends GenericField {
  handleElementClicked = value => {
    const { dynamicForm } = this.props
    const { type } = dynamicForm
    if (type === requestTypesMap.view) return

    const { fieldValue } = this.state

    const selectedList = fieldValue ? fieldValue.toString().split(/[ ,]+/) : []

    const valueIndex = selectedList.indexOf(value.toString())
    if (valueIndex >= 0) selectedList.splice(valueIndex, 1)
    else selectedList.push(value.toString())

    const updatedValue = selectedList.join(',')
    this.processOnChange(updatedValue)
  }

  renderField = () => {
    const { fieldValue, data } = this.state
    const { field } = this.props
    const { renderField, valueField, customizations } = field
    const { elementWidth } = customizations || {}

    const selectedValues = fieldValue
      ? fieldValue.toString().split(/[ ,]+/)
      : []

    const parsedData = data.data
    const html = []
    if (parsedData && parsedData.length > 0) {
      html.push(
        <div
          key={`multiPickerButtonsField_${field.name}`}
          className={classNames('d-flex flex-wrap justify-content-between')}
        >
          {parsedData.map(item => {
            const value = item[valueField]
            const label = item[renderField]

            const selected = selectedValues.indexOf(value.toString()) >= 0

            return (
              <div
                key={value}
                className={classNames(
                  'd-flex flex-fill align-items-center justify-content-center m-2 ffamily-secondary fs-6 p-2 rounded-4 text-break text-center',
                  selected ? 'bg-1 fc-white' : 'bshadow-c1 bg-white fc-1'
                )}
                style={{ width: elementWidth || '' }}
                role="button"
                tabIndex={0}
                onClick={() => this.handleElementClicked(value)}
                onKeyPress={() => this.handleElementClicked(value)}
              >
                {label}
              </div>
            )
          })}
        </div>
      )
    }

    return html
  }
}

export default connect(mapStateToProps, actionCreators)(MultiPickerButtonsField)
