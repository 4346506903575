import { defaultManageResponse } from '../../../../../src/utils'

export function customHelmetJSCode() {
  return ``
}

export function handleImportResponse(sender, response) {
  const { props } = sender

  const { gridComponent } = props
  const { loadGrid } = gridComponent

  defaultManageResponse(sender, {}, response)

  loadGrid()
}
