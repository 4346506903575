import axios from 'axios'
import { lsEmpty } from './localStorage'

axios.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest'
axios.defaults.headers.common['Content-Type'] =
  'application/x-www-form-urlencoded'

axios.interceptors.response.use('', error => {
  const { response } = error
  if (response && response.status && response.status === 401) {
    lsEmpty()
    window.location.href = ''
  }

  return Promise.reject(response)
})

function setJwt(jwt) {
  axios.defaults.headers.common['x-auth-token'] = jwt
}

export default {
  get: axios.get,
  post: axios.post,
  put: axios.put,
  patch: axios.patch,
  delete: axios.delete,
  setJwt,
}
