import React from 'react'
import { connect } from 'react-redux'

import * as actionCreators from '../../../store/actions'
import { mapStateToProps } from '../../../utils'

const classNames = require('classnames')

export class ECPasswordFieldComponent extends React.Component {
  constructor(props) {
    super(props)
    this.state = {}

    this.fieldRef = React.createRef()
  }

  componentWillUnmount() {
    // Fix Warning: Can't perform a React state update on an unmounted component
    this.setState = () => {}
  }

  handleShowPassFieldValue = fieldReferenceObj => {
    const { current } = fieldReferenceObj
    const { type } = current
    current.type = type === 'text' ? 'password' : 'text'
  }

  render() {
    const { field, initialValue, handleValueChanged } = this.props
    const { name } = field
    return (
      <div className={classNames('position-relative')}>
        <input
          ref={this.fieldRef}
          type="password"
          defaultValue={initialValue}
          style={{ width: 'calc(100% - 22px)' }}
          onChange={e => {
            const { value } = e.target
            handleValueChanged(name, value)
          }}
        />
        <div
          className={classNames(
            'eye-icon position-absolute top-50 end-0 translate-middle-y'
          )}
          style={{
            backgroundPosition: 'center',
            backgroundSize: 'contain',
            backgroundRepeat: 'no-repeat',
            height: '20px',
            width: '20px',
          }}
          label="show/hide"
          role="button"
          tabIndex={0}
          onClick={() => {
            this.handleShowPassFieldValue(this.fieldRef)
          }}
          onKeyPress={() => {
            this.handleShowPassFieldValue(this.fieldRef)
          }}
        />
      </div>
    )
  }
}

export default connect(
  mapStateToProps,
  actionCreators
)(ECPasswordFieldComponent)
