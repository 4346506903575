import classNames from 'classnames'
import React from 'react'
import { connect } from 'react-redux'

import * as actionCreators from '../../../store/actions'
import { getBase64, mapStateToProps } from '../../../utils'
import JujoLoading from '../../loading'
import { getFileTypeClass } from './helper'

export class ECBase64FileFieldComponent extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      initialized: false,
      fieldValue: '',
      ext: '',
      error_message: '',
    }
  }

  componentWillUnmount() {
    // Fix Warning: Can't perform a React state update on an unmounted component
    this.setState = () => {}
  }

  componentDidMount = async () => {
    const { initialValue } = this.props
    this.setState({ initialized: true, fieldValue: initialValue })
  }

  viewFile = () => {
    if (typeof window !== 'undefined') {
      const { fieldValue } = this.state
      const w = window.open('about:blank')
      w.document.write(
        `<iframe width='100%' height='100%' frameBorder='0' src='${fieldValue}'></iframe>`
      )
    }
  }

  handleFileChange = async e => {
    const { field, handleValueChanged } = this.props

    const { files } = e.target
    const { name } = field

    const { maxFileSize } = field
    const max_file_size = maxFileSize || 30000000

    if (files && files.length > 0) {
      const file = files[0]

      if (file.size > max_file_size) {
        this.setState({ error_message: 'file_too_large' })
      } else {
        const base64 = await getBase64(file)
        const file_ext = file.name.split('.').pop()

        this.setState({ fieldValue: base64, ext: file_ext, error_message: '' })
        handleValueChanged(name, base64)
      }
    }
  }

  renderFileField = () => {
    const { fieldValue, ext, error_message } = this.state
    const { field, handleValueChanged, environment, specialization } =
      this.props

    const { locale } = environment
    const { translations } = specialization
    const { texts } = translations

    const { name, acceptedTypes } = field

    const html = []

    const typeClass = getFileTypeClass(ext)

    html.push(
      <div key={`file_${name}`}>
        <div
          className={classNames('d-flex align-items-center')}
          style={{ minHeight: '26px' }}
        >
          {fieldValue && fieldValue.length > 0 && (
            <div
              className={classNames(
                'me-2 theme-svg',
                fieldValue ? typeClass : ''
              )}
              style={{
                backgroundSize: 'contain',
                backgroundRepeat: 'no-repeat',
                backgroundPosition: 'left',
                height: '24px',
                width: '24px',
              }}
            />
          )}

          {error_message.length > 0 && (
            <div className={classNames('fs-8 fw-bold fc-red')}>
              {texts[locale][error_message] || error_message}
            </div>
          )}

          <div className={classNames('d-flex align-items-center ms-auto')}>
            <div
              className={classNames(
                'theme-svg mx-1',
                fieldValue ? 'edit-icon' : 'upload-icon'
              )}
              style={{
                backgroundPosition: 'center',
                backgroundSize: 'contain',
                backgroundRepeat: 'no-repeat',
                height: '18px',
                width: '18px',
              }}
              label="file_action_btn"
              role="button"
              tabIndex={0}
              onClick={() => {
                const inputFile = document.getElementById(name)
                inputFile.click()
              }}
              onKeyPress={() => {
                const inputFile = document.getElementById(name)
                inputFile.click()
              }}
            />

            {fieldValue && (
              <>
                <div
                  className={classNames('trash-icon theme-svg mx-1')}
                  style={{
                    backgroundPosition: 'center',
                    backgroundSize: 'contain',
                    backgroundRepeat: 'no-repeat',
                    height: '18px',
                    width: '18px',
                  }}
                  label="delete"
                  role="button"
                  tabIndex={0}
                  onClick={() => {
                    this.setState({ fieldValue: '' })
                    handleValueChanged(name, '')
                  }}
                  onKeyPress={() => {
                    this.setState({ fieldValue: '' })
                    handleValueChanged(name, '')
                  }}
                />
                <div
                  className={classNames('eye-icon theme-svg mx-1')}
                  style={{
                    backgroundPosition: 'center',
                    backgroundSize: 'contain',
                    backgroundRepeat: 'no-repeat',
                    height: '18px',
                    width: '18px',
                  }}
                  label="download"
                  role="button"
                  tabIndex={0}
                  onClick={() => {
                    if (typeof window !== 'undefined') {
                      this.viewFile()
                    }
                  }}
                  onKeyPress={() => {
                    if (typeof window !== 'undefined') {
                      this.viewFile()
                    }
                  }}
                />
              </>
            )}
          </div>
        </div>
        <input
          id={name}
          type="file"
          accept={acceptedTypes}
          className={classNames('d-none')}
          onChange={e => {
            this.handleFileChange(e)
          }}
        />
      </div>
    )

    return html
  }

  render() {
    const { initialized } = this.state
    return (
      <>
        {initialized === false && <JujoLoading />}
        {initialized === true && <div> {this.renderFileField()}</div>}
      </>
    )
  }
}

export default connect(
  mapStateToProps,
  actionCreators
)(ECBase64FileFieldComponent)
