import {
  applyMiddleware,
  combineReducers,
  compose,
  createStore as reduxCreateStore,
} from 'redux'
import thunk from 'redux-thunk'
import authentication from './reducers/authentication'
import cart from './reducers/cart'
import dynamicForm from './reducers/dynamic_form'
import environment from './reducers/environment'
import genericData from './reducers/generic_data'
import genericDataBox2 from './reducers/generic_data_box_2'
import genericDataBox3 from './reducers/generic_data_box_3'
import injectedComponents from './reducers/injected_components'
import infoBox from './reducers/info_box'
import session from './reducers/session'
import specialization from './reducers/specialization'

const rootReducer = combineReducers({
  authentication,
  cart,
  dynamicForm,
  environment,
  genericData,
  genericDataBox2,
  genericDataBox3,
  injectedComponents,
  infoBox,
  session,
  specialization,
})

const composeEnhancers =
  typeof window === 'object' && window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
    ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
    : compose

const createStore = () =>
  reduxCreateStore(rootReducer, composeEnhancers(applyMiddleware(thunk)))
export default createStore
