import React, { useState, useEffect, useRef } from 'react'
import classNames from 'classnames'
import loadable from '@loadable/component'

const JujoLoading = loadable(() => import('../../../../loading'))

function JujoSVGGrid({ svg_id }) {
  const rowsInputRef = useRef(null)
  const colsInputRef = useRef(null)

  const [svg, setSvg] = useState(null)

  const initData = async () => {
    setTimeout(() => {
      const svg_element = document.getElementById(svg_id)
      setSvg(svg_element)
    }, 1)
  }

  useEffect(() => {
    const execInitData = async () => {
      await initData()
    }

    execInitData()
  }, [])

  const isVisible = () => {
    const grid_pattern = svg.getElementById('grid_pattern')
    return grid_pattern !== null
  }

  const removeGrid = () => {
    const grid_pattern = svg.getElementById('grid_pattern')
    const grid_rect = svg.getElementById('grid_rect')
    if (grid_pattern && grid_rect) {
      grid_pattern.remove()
      grid_rect.remove()
    }
  }

  const drawGrid = () => {
    const { viewBox } = svg
    const { baseVal } = viewBox

    const { width, height } = baseVal

    const { value: rows } = rowsInputRef.current
    const { value: cols } = colsInputRef.current

    // eslint-disable-next-line no-restricted-globals
    if (isNaN(rows) || rows <= 0 || isNaN(cols) || cols <= 0) return

    const pattern_width = ((parseFloat(width) - 1) / rows).toFixed(2)
    const pattern_height = ((parseFloat(height) - 1) / cols).toFixed(2)

    const grid_code = `
    <pattern id="grid_pattern" width="${pattern_width}" height="${pattern_height}" patternUnits="userSpaceOnUse">
        <path d="M ${pattern_width} 0 L 0 0 0 ${pattern_height}" fill="none" stroke="gray" stroke-width="1"></path>
    </pattern>
    <rect id="grid_rect" width="100%" height="100%" fill="url(#grid_pattern)"></rect>`

    removeGrid()
    svg.insertAdjacentHTML('afterbegin', grid_code)
  }

  const handleGridClicked = () => {
    if (isVisible()) {
      removeGrid()
    } else {
      drawGrid()
    }
  }

  const handleGridRowsChanged = () => {
    if (isVisible()) {
      drawGrid()
    }
  }

  const handleGridColsChanged = () => {
    if (isVisible()) {
      drawGrid()
    }
  }

  return (
    <>
      {svg === null && <JujoLoading />}
      {svg !== null && (
        <div
          className={classNames(
            'd-flex align-items-center fs-6 bg-white px-2 mx-1 my-1 my-md-0'
          )}
        >
          <div
            className={classNames('background-image grid-icon')}
            style={{ width: 25, height: 25 }}
            role="button"
            tabIndex={0}
            label="grid"
            onClick={() => {
              handleGridClicked()
            }}
            onKeyPress={() => {
              handleGridClicked()
            }}
          />
          <input
            ref={rowsInputRef}
            className={classNames('rounded-3 text-center no-arrow')}
            style={{ width: 35 }}
            type="number"
            min={0}
            defaultValue={5}
            onChange={() => {
              handleGridRowsChanged()
            }}
          />
          <span className={classNames('mx-1')}>|</span>
          <input
            ref={colsInputRef}
            className={classNames('rounded-3 text-center no-arrow')}
            style={{ width: 35 }}
            type="number"
            min={0}
            defaultValue={5}
            onChange={() => {
              handleGridColsChanged()
            }}
          />
        </div>
      )}
    </>
  )
}

export default JujoSVGGrid
