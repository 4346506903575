import React from 'react'
import { connect } from 'react-redux'
import DropdownTreeSelect from 'react-tree-selector'

import * as actionCreators from '../../../store/actions'
import { mapStateToProps } from '../../../utils'

import '../../../assets/css/jujo/dropdown_tree_select.scss'
import JujoLoading from '../../loading'
import {
  baseRequestObject,
  parseEndpoint,
} from '../../../services/servicesHelper'
import apiCallMap from '../../../enums/apiCallMap'
import { httpGet } from '../../../services/apiService'

export class ECDropdownTreeSelectorFieldComponent extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      loading: true,
    }
  }

  componentWillUnmount() {
    // Fix Warning: Can't perform a React state update on an unmounted component
    // https://npm.io/package/react-tree-selector
    this.setState = () => {}
  }

  shouldComponentUpdate = (nextProps, nextState) => {
    const { loading } = this.state
    return loading !== nextState.loading
  }

  /* Setting the initial value of the dropdown tree selector. */
  componentDidMount = async () => {
    const { initialValue } = this.props

    const parsed_data = await this.parse_data()

    const selected_nodes_id_list = initialValue || []
    if (selected_nodes_id_list.length > 0) {
      this.recursivelySetSelectedNodes(selected_nodes_id_list, parsed_data)
    }

    this.setState({ loading: false, data: parsed_data })
  }

  /* Parsing the data from the API call to the format that the dropdown tree selector component
  expects. */
  parse_data = async () => {
    const { field } = this.props
    const { labelField, valueField } = field

    const data = await this.initData()
    const string_data = JSON.stringify(data)

    const component_label_field = 'label'
    const component_value_field = 'value'

    let parsed_data_string = string_data

    /* Replacing the label field of the data with the label field 
    that the dropdown tree selector component expects. */
    parsed_data_string =
      labelField && labelField !== component_label_field
        ? parsed_data_string.replaceAll(
            `"${labelField}"`,
            `"${component_label_field}"`
          )
        : parsed_data_string

    /* Replacing the value field of the data with the value field 
    that the dropdown tree selector component expects. */
    parsed_data_string =
      valueField && valueField !== component_value_field
        ? parsed_data_string.replaceAll(
            `"${valueField}"`,
            `"${component_value_field}"`
          )
        : parsed_data_string

    const parsed_data = JSON.parse(parsed_data_string)
    return parsed_data
  }

  /* Making an API call to get the data for the dropdown tree selector. */
  initData = async () => {
    const { field, environment, authentication } = this.props
    const { apis } = field
    const { getData } = apis

    const {
      apiCall,
      relatedEntity,
      requestType,
      defaultFilters,
      placeholderMapping,
    } = getData

    const requestData = baseRequestObject(
      apiCallMap[apiCall],
      relatedEntity,
      requestType,
      environment,
      authentication
    )

    requestData.defaultFilters = defaultFilters
    requestData.placeholderMapping = placeholderMapping
    const parsedEp = parseEndpoint(requestData)

    const result = await httpGet(`${process.env.apiUrl}${parsedEp}`)

    if (result) {
      const { status, data } = result
      if (status === 200) {
        return data.data
      }
    }

    return []
  }

  /* A recursive function that is used to set the initial value of the dropdown tree selector. */
  recursivelySetSelectedNodes = (selected_nodes_id_list, data) => {
    for (let i = 0; i !== data.length; i += 1) {
      const node = data[i]
      const { value, children } = node
      if (selected_nodes_id_list.indexOf(value) >= 0) node.checked = true
      if (children && children.length > 0) {
        this.recursivelySetSelectedNodes(selected_nodes_id_list, children)
      }
    }
  }

  /* A function that is called when the user selects a node in the dropdown tree selector. */
  handleSelectionChanged = async (currentNode, selectedNodes) => {
    const { field, handleValueChanged } = this.props
    const { name } = field

    const selected_nodes_id_list = selectedNodes.map(c => c.value)
    await handleValueChanged(name, selected_nodes_id_list)
  }

  render() {
    const { loading, data } = this.state
    const { specialization, environment } = this.props

    const { translations } = specialization
    const { texts } = translations
    const { locale } = environment
    return (
      <>
        {loading === true && <JujoLoading />}
        {loading === false && (
          <div key="statica">
            <DropdownTreeSelect
              data={data}
              onChange={this.handleSelectionChanged}
              onBlur={this.handleOnBlur}
              texts={{ placeholder: texts[locale].search }}
              className="jujo-dropdown-tree-select"
            />
          </div>
        )}
      </>
    )
  }
}

export default connect(
  mapStateToProps,
  actionCreators
)(ECDropdownTreeSelectorFieldComponent)
