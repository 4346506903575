/* eslint-disable consistent-return */
/* eslint-disable array-callback-return */
/* eslint-disable global-require */
/* eslint-disable import/no-dynamic-require */
import { connect } from 'react-redux'
import apiRequestTypesMap from '../../enums/apiRequestTypesMap'

import * as actionCreators from '../../store/actions'
import { mapStateToProps } from '../../utils'
import { JujoGenericFormComponent } from './generic_form'

export class JujoAddComponent extends JujoGenericFormComponent {
  specializedFields = fields => fields.add

  specializedFieldGroups = fieldsGroup => fieldsGroup.add || []

  specializedApiDef = apis => apis.createData

  specializedApiRequestType = () => apiRequestTypesMap.post
}

export default connect(mapStateToProps, actionCreators)(JujoAddComponent)
