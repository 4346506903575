import React from 'react'
import classNames from 'classnames'
import { translate } from '../../../../../utils'

function JujoSVGMoveBackwardBtn({ element_key }) {
  const handleMoveBackward = () => {
    if (element_key) {
      const element = document.getElementById(element_key)
      const svg_element = element.closest('svg')

      const group_list = svg_element.querySelector('g').querySelectorAll('g')

      const parsed_groups = Array.from(group_list).filter(
        x => ['fustella', 'guida'].indexOf(x.getAttribute('label')) < 0
      )

      const element_idx = parsed_groups.indexOf(element)

      const prev_idx = element_idx - 1
      if (prev_idx < 0) return

      const prev_element = parsed_groups[prev_idx]

      svg_element.querySelector('g').insertBefore(element, prev_element)
    }
  }

  return (
    <div>
      <div
        className={classNames(
          'd-flex flex-column align-items-center w-80px w-md-70px border border-md-unset p-1 p-md-0 m-1 m-md-0',
          element_key === null ? 'opacity-5' : ''
        )}
        role="button"
        tabIndex={0}
        onClick={() => handleMoveBackward()}
        onKeyPress={() => handleMoveBackward()}
      >
        <div className={classNames('fs-8')}>{translate('backward')}</div>
        <div
          className={classNames('background-image move-backward-icon')}
          style={{ width: 25, height: 25 }}
        />
      </div>
    </div>
  )
}

export default JujoSVGMoveBackwardBtn
