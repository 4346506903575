/* eslint-disable jsx-a11y/label-has-associated-control */
import React from 'react'
import { connect } from 'react-redux'

import * as actionCreators from '../../../../store/actions'
import { mapStateToProps, translate } from '../../../../utils'
import { GenericFilter } from './genericFilter'

const classNames = require('classnames')

export class CheckBoxFilter extends GenericFilter {
  handleCheckboxChanged = e => {
    const { checked } = e.target
    if (checked) {
      const { filter } = this.props
      this.updateFilter(filter.check_value)
    } else {
      this.clearFilter()
    }
  }

  filterTitleEnabled = () => false

  renderFilter = () => {
    const { filter } = this.props
    const { filterValue } = this.state

    const is_checked = filterValue !== null

    const html = []
    html.push(
      <div
        key={`filter_${filter.label}`}
        className={classNames('form-control rounded-4 shadow-sm border-0')}
      >
        <label className={classNames('d-flex align-items-center')}>
          <input
            type="checkbox"
            defaultChecked={is_checked}
            className={classNames('w-20px me-2')}
            onChange={e => {
              this.handleCheckboxChanged(e)
            }}
          />
          {translate(filter.label)}
        </label>
      </div>
    )
    return html
  }
}

export default connect(mapStateToProps, actionCreators)(CheckBoxFilter)
