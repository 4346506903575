const conditionCheckTypesMap = {
  contains: 'contains',
  equals_to: 'equals_to',
  lower_than_or_equal_to: 'lower_than_or_equal_to',
  greater_than: 'greater_than',
  greater_than_or_equal_to: 'greater_than_or_equal_to',
  not_equals_to: 'not_equals_to',
}

export default conditionCheckTypesMap
