import React, { useState, useEffect } from 'react'
import classNames from 'classnames'
import loadable from '@loadable/component'

const JujoLoading = loadable(() => import('../../../../loading'))

function JujoSVGZoomBtn({ svg_id }) {
  const [svg, setSvg] = useState(null)

  const initData = async () => {
    setTimeout(() => {
      const svg_element = document.getElementById(svg_id)
      setSvg(svg_element)
    }, 1)
  }

  useEffect(() => {
    const execInitData = async () => {
      await initData()
    }

    execInitData()
  }, [])

  const handleZoom = () => {
    const { parentElement } = svg
    const { style } = parentElement
    const { width } = style

    const size = parseFloat(width.replace('px', ''))
    const new_size = (parseFloat(size) + 50).toFixed(2)

    parentElement.style.width = `${new_size}px`
  }

  // const handleZoom = () => {
  //   const viewBox = svg.getAttribute('viewBox').split(' ')
  //   const width = parseFloat(viewBox[2])
  //   const height = parseFloat(viewBox[3])
  //   const newWidth = width / 1.1
  //   const newHeight = height / 1.1
  //   const newX = viewBox[0] - (newWidth - width) / 2
  //   const newY = viewBox[1] - (newHeight - height) / 2
  //   svg.setAttribute('viewBox', `${newX} ${newY} ${newWidth} ${newHeight}`)
  // }

  return (
    <>
      {svg === null && <JujoLoading />}
      {svg !== null && (
        <div
          className={classNames('bg-white p-2 mx-1 my-1 my-md-0')}
          role="button"
          tabIndex={0}
          label="zoom"
          onClick={() => {
            handleZoom()
          }}
          onKeyPress={() => {
            handleZoom()
          }}
        >
          <div
            className={classNames('background-image zoom-plus-icon')}
            style={{ width: 25, height: 25 }}
          />
        </div>
      )}
    </>
  )
}

export default JujoSVGZoomBtn
