import React from 'react'
import { connect } from 'react-redux'
import uuid from 'react-uuid'
import requestTypesMap from '../../../../enums/requestTypesMap'

import * as actionCreators from '../../../../store/actions'
import { mapStateToProps } from '../../../../utils'
import { GenericField } from './genericField'

export class TextareaField extends GenericField {
  processOnChange = value => {
    this.setState({ fieldValue: value })
  }

  processOnBlur = value => {
    this.saveOnRedux(value)
  }

  renderField = () => {
    const { fieldValue } = this.state
    const { field, environment, specialization, dynamicForm } = this.props
    const { name, translateRenderField, disabled, rows } = field
    const { translations } = specialization
    const { texts } = translations
    const { locale } = environment
    const { type } = dynamicForm

    const html = []
    html.push(
      <textarea
        key={uuid()}
        name={name}
        rows={rows}
        defaultValue={
          translateRenderField ? texts[locale][fieldValue] : fieldValue
        }
        disabled={disabled || type === requestTypesMap.view}
        onPaste={e => {
          if (field.paste_disabled === true) {
            e.preventDefault()
          }
          return false
        }}
        onChange={e => {
          const { value } = e.target
          this.processOnChange(value)
        }}
        onBlur={e => {
          const { value } = e.target
          this.processOnBlur(value)
        }}
      />
    )
    return html
  }
}

export default connect(mapStateToProps, actionCreators)(TextareaField)
