/* eslint-disable consistent-return */
/* eslint-disable array-callback-return */
import React from 'react'
import { connect } from 'react-redux'

import parse from 'html-react-parser'
import * as actionCreators from '../../store/actions'
import JujoLoading from '../loading'
import { mapStateToProps } from '../../utils'

const classNames = require('classnames')

export class JujoBaseMenuComponent extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      loading: false,
      burger_menu_expanded: false,
    }
  }

  componentWillUnmount() {
    // fix Warning: Can't perform a React state update on an unmounted component
    this.setState = () => {}
  }

  componentDidMount = async () => {}

  handleMenuItemClicked = itm => {
    const { environment } = this.props
    const { locale } = environment
    const { static_name, url, sub_menu } = itm
    if (typeof window !== 'undefined') {
      if (sub_menu) {
        const sub_menu_obj = document.getElementById(`${static_name}_submenu`)
        const sub_menu_arrow = document.getElementById(
          `${static_name}_submenu_arrow`
        )
        const { classList } = sub_menu_obj
        if (classList.contains('d-none')) {
          classList.remove('d-none')
          sub_menu_arrow.style.transform = 'scaleY(-1)'
        } else {
          classList.add('d-none')
          sub_menu_arrow.style.transform = null
        }
      } else {
        window.location.href = url[locale]
      }
    }
  }

  handleBurgerMenuClicked = () => {
    const { burger_menu_expanded } = this.state
    this.setState({ burger_menu_expanded: !burger_menu_expanded })
  }

  renderMenuItems = (list, level) => {
    const { environment, data } = this.props
    const { locale, path } = environment
    const { selected_item_class, selected_expand_icon_class } = data

    const html = []
    for (let i = 0; i !== list.length; i += 1) {
      const itm = list[i]
      const { static_name, title, label, sub_menu, customizations, url } = itm

      const { class_names } = customizations || {}
      const menu_label = label ? label[locale] : title

      let item_class = {}
      let expand_icon_class = {}
      item_class =
        url[locale] === path && level === 'menu' ? selected_item_class : ''
      if (sub_menu) {
        for (let j = 0; j < sub_menu.length; j += 1) {
          const sub_menu_item = sub_menu[j]
          if (sub_menu_item.url[locale] === path) {
            item_class = selected_item_class
            expand_icon_class = selected_expand_icon_class
          }
        }
      }

      if (sub_menu) {
        html.push(
          <div
            key={static_name}
            className={classNames(
              ' p-2 ',
              class_names || '',
              item_class,
              level === 'menu' ? 'border-bottom border-md-unset' : ''
            )}
            role="button"
            tabIndex={0}
            onClick={e => {
              e.stopPropagation()
              this.handleMenuItemClicked(itm)
            }}
            onKeyPress={e => {
              e.stopPropagation()
              this.handleMenuItemClicked(itm)
            }}
          >
            <div
              className={classNames(
                'd-flex align-items-center justify-content-center'
              )}
            >
              <div>{parse(menu_label)}</div>
              {sub_menu && (
                <div
                  id={`${static_name}_submenu_arrow`}
                  className={classNames(
                    'background-image expand_submenu_icon ms-1',
                    expand_icon_class
                  )}
                  style={{
                    width: 15,
                    height: 15,
                  }}
                />
              )}
            </div>
            {sub_menu && (
              <div
                id={`${static_name}_submenu`}
                className={classNames(
                  'd-none position-unset position-md-absolute mt-2 d-flex flex-column'
                )}
              >
                {this.renderMenuItems(sub_menu, 'sub_menu')}
              </div>
            )}
          </div>
        )
      } else {
        html.push(
          <a
            key={static_name}
            className={classNames(
              'p-2 d-flex d-md-unset justify-content-center align-items-center',
              class_names || '',
              item_class,
              level === 'menu' ? 'border-bottom border-md-unset' : ''
            )}
            href={url[locale]}
            title={menu_label.replace(/<[^>]+>/g, '')}
            style={{ textDecoration: 'none', color: 'inherit' }}
          >
            {parse(menu_label)}
          </a>
        )
      }
    }
    return html
  }

  render() {
    const { burger_menu_expanded } = this.state
    const { session } = this.props
    const { session_data } = session
    const { menu } = session_data

    const { loading } = this.state
    return (
      <>
        {loading && <JujoLoading />}
        {!loading && (
          <div
            className={classNames(
              'd-flex flex-column justify-content-center align-items-center my-0'
            )}
          >
            <div
              className={classNames(
                'd-flex d-md-none mb-0 mb-md-2 theme-svg background-image',
                burger_menu_expanded ? 'close-icon' : 'burger-menu-icon'
              )}
              style={{ width: '30px', height: '30px' }}
              role="button"
              label="burger-menu"
              tabIndex={0}
              onClick={() => {
                this.handleBurgerMenuClicked()
              }}
              onKeyPress={() => {
                this.handleBurgerMenuClicked()
              }}
            />

            <div
              className={classNames(
                `w-100vw w-md-unset 
                bg-white bg-md-unset 
                d-md-flex flex-column flex-md-row 
                fs-7 
                position-fixed position-md-unset 
                bottom-0 start-0 top-0 
                mt-56px mt-md-0`,
                burger_menu_expanded ? '' : 'd-none'
              )}
            >
              {this.renderMenuItems(menu, 'menu')}
            </div>
          </div>
        )}
      </>
    )
  }
}

export default connect(mapStateToProps, actionCreators)(JujoBaseMenuComponent)
