import React, { useEffect, useState } from 'react'
import classNames from 'classnames'
import { connect, useSelector } from 'react-redux'

import { translate } from '../../../../../utils'

function JujoSVGDefaultFontManager({ svg_id, default_font, onChange }) {
  const [svg, setSvg] = useState(null)
  const [font_id, setFontId] = useState(null)
  const { jujo_fonts: fonts } = useSelector(state => state.session.session_data)

  useEffect(() => {
    const svg_element = document.getElementById(svg_id)
    if (svg_element) {
      setSvg(svg_element)
    }
  }, [svg_id])

  useEffect(() => {
    if (default_font) {
      const { id } = default_font
      setFontId(id)
    } else {
      setFontId(0)
    }
  }, [default_font])

  useEffect(() => {
    if (font_id !== null && font_id > 0) {
      const selected_font_id = `font_${font_id}`

      const font_definition = fonts.find(
        font => font.id === parseInt(font_id, 10)
      )

      const updated_default_font = {
        id: font_definition.id,
        src: font_definition.data[0].src,
      }

      const existing_style = document.getElementById(selected_font_id)

      if (!existing_style) {
        const style_obj = document.createElement('style')
        style_obj.setAttribute('id', selected_font_id)

        const fface_code = `
        @font-face {
          font-family:'${selected_font_id}';
          src: url('${updated_default_font.src}');
        }`

        const styleBody = document.createTextNode(fface_code)
        style_obj.appendChild(styleBody)
        svg.appendChild(style_obj)
      }

      onChange('default_font', updated_default_font)
    }
  }, [font_id])

  const handleSelectChange = event => {
    const { value } = event.target
    if (value === 0) return

    setFontId(value)
  }

  return (
    <div className={classNames('')}>
      <div className={classNames('fw-bold ffamily-secondary fst-uppercase')}>
        <div>{translate('default_font')}</div>
      </div>
      <div className={classNames('bg-white p-2 mb-2 border')}>
        {font_id !== null && (
          <select onChange={e => handleSelectChange(e)} defaultValue={font_id}>
            <option value="0" label="Seleziona" />
            {fonts.map(font => (
              <option key={font.id} value={font.id}>
                {font.name}
              </option>
            ))}
          </select>
        )}
      </div>
    </div>
  )
}

export default connect()(JujoSVGDefaultFontManager)
