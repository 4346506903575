import React from 'react'
import { connect } from 'react-redux'
import * as actionCreators from '../../../../../../src/store/actions'
import { mapStateToProps } from '../../../../../../src/utils'
import OssigenoWizardStepStart from './_parts/start'
import OssigenoWizardStepInvoicing from './_parts/invoicing'
import OssigenoWizardStepStores from './_parts/stores'
import OssigenoWizardStepPurges from './_parts/purges'
import OssigenoWizardStepReport from './_parts/report'

const classNames = require('classnames')

export class OssigenoSteps extends React.Component {
  constructor(props) {
    super(props)
    this.state = {}
  }

  componentWillUnmount() {
    // fix Warning: Can't perform a React state update on an unmounted component
    this.setState = () => {}
  }

  componentDidMount = async () => {}

  render() {
    const { session } = this.props
    const { session_data } = session
    const { wizard } = session_data
    const { active_step } = wizard
    return (
      <div
        className={classNames(
          'w-100 d-flex align-items-center justify-content-center my-1 my-md-5'
        )}
      >
        {active_step === 1 && <OssigenoWizardStepStart />}
        {active_step === 2 && <OssigenoWizardStepInvoicing />}
        {active_step === 3 && <OssigenoWizardStepStores />}
        {active_step === 4 && <OssigenoWizardStepPurges />}
        {active_step === 5 && <OssigenoWizardStepReport />}
      </div>
    )
  }
}
export default connect(mapStateToProps, actionCreators)(OssigenoSteps)
