import React from 'react'
import { connect } from 'react-redux'

import classNames from 'classnames'

import loadable from '@loadable/component'
import * as actionCreators from '../../../../store/actions'
import { mapStateToProps } from '../../../../utils'
import { JujoSVGGenericSingleElementComponent } from './generic_single_element'

const JujoSVGInteractiveText = loadable(() => import('./text/interactive_text'))

export class JujoSVGSingleMultilineEditorComponent extends JujoSVGGenericSingleElementComponent {
  retrieveGenericElement = () => {
    const { g_element } = this.state
    const generic_element = g_element.getElementsByTagName('text')[0]
    return generic_element
  }

  drawEditElement = () => {
    const { element_key, setFocusedElement } = this.props

    const html = []
    html.push(
      <div
        key={`${element_key}_multiline_editor`}
        className={classNames('col-12')}
      >
        <JujoSVGInteractiveText
          element_key={element_key}
          setFocusedElement={setFocusedElement}
        />
      </div>
    )
    return html
  }
}

export default connect(
  mapStateToProps,
  actionCreators
)(JujoSVGSingleMultilineEditorComponent)
