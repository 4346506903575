import conditionCheckTypesMap from '../../../enums/conditionCheckTypesMap'
import sourcesMap from '../../../enums/sourcesMap'

export function verifyGenericConditions(sender, conditions, data) {
  const { environment, authentication, session } = sender.props
  const { user } = authentication

  if (!conditions || conditions.length === 0) {
    return true
  }

  let error = false

  for (let i = 0; i !== conditions.length; i += 1) {
    const condition = conditions[i]

    const { source, relatedField, checkType, check } = condition

    let relFieldValue = null
    let sourceObj = {}
    if (source === sourcesMap.dynamicField) {
      sourceObj = data
    }
    if (source === sourcesMap.environment) {
      sourceObj = environment
    }
    if (source === sourcesMap.session) {
      sourceObj = session
    }
    if (source === sourcesMap.user) {
      sourceObj = user
    }

    const steps = relatedField.split('/')
    relFieldValue = sourceObj

    for (let j = 0; j !== steps.length; j += 1) {
      const next_step = steps[j]
      relFieldValue = relFieldValue[next_step]
    }

    if (checkType === conditionCheckTypesMap.equals_to) {
      if (Array.isArray(check)) {
        let meet = false
        for (let x = 0; x !== check.length; x += 1) {
          const currentCheck = check[x]
          if (currentCheck === relFieldValue) {
            meet = true
            break
          }
        }

        error = !meet
      } else {
        error = check !== relFieldValue
      }
    } else if (checkType === conditionCheckTypesMap.not_equals_to) {
      const relFieldValueString = relFieldValue ? relFieldValue.toString() : ''

      if (Array.isArray(check)) {
        let meet = false
        for (let x = 0; x !== check.length; x += 1) {
          const currentCheck = check[x]
          if (currentCheck === relFieldValueString) {
            meet = true
            break
          }
        }
        error = meet
      } else {
        error = check.toString() === relFieldValueString
      }
    }

    if (error === true) {
      break
    }
  }

  return !error
}

export function retrieveEntityValue(props) {
  const { c_def, authentication } = props

  const { entity } = c_def
  const { user } = authentication

  const { source, defValue, relatedField } = entity

  let entityValue = ''
  if (source === sourcesMap.static) {
    entityValue = defValue
  }

  if (source === sourcesMap.user) {
    entityValue = user[relatedField] || ''
  }

  if (source === sourcesMap.api) {
    entityValue = defValue
  }

  return entityValue
}

export function retrieveEntitySource(props) {
  const { c_def } = props
  const { entity } = c_def
  const { source } = entity
  return source
}
