import React from 'react'
import { connect } from 'react-redux'

import loadable from '@loadable/component'
import classNames from 'classnames'

import * as actionCreators from '../../../../store/actions'
import { mapStateToProps, randomString } from '../../../../utils'
import {
  bindSvgDragActionToImageElement,
  bindSvgDragActionToTextElement,
} from '../../../../utils/svg'

const JujoLoading = loadable(() => import('../../../loading'))

const JujoSVGSingleMultilineEditorComponent = loadable(() =>
  import('./single_multiline')
)

const JujoSVGSingleImageEditorComponent = loadable(() =>
  import('./single_image')
)

export class JujoSVGEditorsComponent extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      loading: true,
      svg_id: '',
      elements_to_render: [],
    }
  }

  componentWillUnmount() {
    // Fix Warning: Can't perform a React state update on an unmounted component
    this.setState = () => {}
  }

  componentDidMount = async () => {
    const { data } = this.props
    const { svg_id } = data
    await this.setState({ svg_id })

    this.reloadElementsToRender()
  }

  retrieveElementstoRender = () => {
    const elements_to_render = []
    const { svg_id } = this.state

    const svg_object = document.getElementById(svg_id)

    const element_list = svg_object.querySelectorAll('text,image')

    for (let i = 0; i !== element_list.length; i += 1) {
      const element = element_list[i]
      const { nodeName } = element

      if (nodeName === 'image') {
        const element_key = element.closest('g').id
        elements_to_render.push({
          element_key,
          type: 'image',
        })
      } else {
        const element_key = element.closest('g').id
        elements_to_render.push({
          element_key,
          type: 'text',
        })
      }
    }

    return elements_to_render
  }

  reloadElementsToRender = () => {
    this.setState({ loading: true })

    const elements_to_render = this.retrieveElementstoRender()

    this.setState({
      elements_to_render,
      loading: false,
    })
  }

  moveElement = (id, direction) => {
    const { svg_id } = this.state
    const { elements_to_render } = this.state

    let element_idx = -1
    for (let i = 0; i !== elements_to_render.length; i += 1) {
      const { element_key } = elements_to_render[i]
      if (element_key === id) {
        element_idx = i
        break
      }
    }

    if (element_idx === -1) return

    const element_to_move_up_idx =
      direction === 'up' ? element_idx : element_idx + 1

    const prev_position_idx = element_to_move_up_idx - 1

    if (
      element_to_move_up_idx === 0 ||
      element_to_move_up_idx >= elements_to_render.length
    )
      return

    const element_to_move = document.getElementById(
      elements_to_render[element_to_move_up_idx].element_key
    )
    const element_before = document.getElementById(
      elements_to_render[prev_position_idx].element_key
    )

    document
      .getElementById(svg_id)
      .insertBefore(element_to_move, element_before)

    this.reloadElementsToRender()
  }

  duplicateElement = id => {
    const { svg_id } = this.state

    /* Getting the svg node, the element to duplicate and the related style node. */
    const svg_node = document.getElementById(svg_id)
    const element_to_duplicate = document.getElementById(id)
    const related_style_node = document.getElementById(`${id}_style`)

    /* Cloning the element, giving it a new id, and replacing the old id with the new one. */
    const cloned_element = element_to_duplicate.cloneNode(true)
    const new_id = randomString(16)

    const current_label = cloned_element.getAttribute('label')

    cloned_element.setAttribute('id', new_id)
    cloned_element.setAttribute('label', `${current_label} duplicated`)
    cloned_element.innerHTML = cloned_element.innerHTML.replaceAll(id, new_id)

    /* Cloning the style node of the element to duplicate. */
    if (related_style_node) {
      const cloned_style = related_style_node.cloneNode(true)

      cloned_style.setAttribute('id', `${new_id}_style`)
      cloned_style.innerHTML = cloned_style.innerHTML.replaceAll(id, new_id)

      svg_node.append(cloned_style)
    }

    /* Adding the cloned element to the svg node, binding the drag action to the element and reloading the
    elements to render. */
    svg_node.append(cloned_element)
    this.bindDragToDuplicatedNode(cloned_element)
    this.reloadElementsToRender()
  }

  bindDragToDuplicatedNode = cloned_element => {
    const { svg_id } = this.state
    const { nodeName } = cloned_element.children[0]
    if (nodeName === 'image') {
      bindSvgDragActionToImageElement(svg_id, cloned_element)
    } else {
      bindSvgDragActionToTextElement(svg_id, cloned_element)
    }
  }

  deleteElement = id => {
    const element_to_delete = document.getElementById(id)
    element_to_delete.remove()
    this.reloadElementsToRender()
  }

  drawSingleEditors = () => {
    const html = []
    const { data } = this.props
    const { setFocusedElement } = data

    const { elements_to_render } = this.state

    if (elements_to_render.length === 0) return html

    for (let i = 0; i !== elements_to_render.length; i += 1) {
      const { element_key, type } = elements_to_render[i]

      html.push(
        <div key={`${element_key}_container`} className={classNames('')}>
          {type === 'text' && (
            <JujoSVGSingleMultilineEditorComponent
              element_key={element_key}
              setFocusedElement={setFocusedElement}
              duplicateElement={this.duplicateElement}
              moveElement={this.moveElement}
              deleteElement={this.deleteElement}
            />
          )}
          {type === 'image' && (
            <JujoSVGSingleImageEditorComponent
              element_key={element_key}
              setFocusedElement={setFocusedElement}
              duplicateElement={this.duplicateElement}
              moveElement={this.moveElement}
              deleteElement={this.deleteElement}
            />
          )}
        </div>
      )
    }

    return html
  }

  render() {
    const { loading } = this.state
    return (
      <>
        {loading === true && <JujoLoading />}
        {loading === false && (
          <div className={classNames('')}>{this.drawSingleEditors()}</div>
        )}
      </>
    )
  }
}

export default connect(mapStateToProps, actionCreators)(JujoSVGEditorsComponent)
