const initialState = {
  visible: false,
  subject_id: 0,
  relatedEntity: '',
  action: '',
  customizations: {
    specialized: false,
    render_component: '',
    show_close_button: true,
    working_area_classes: '',
    width: '',
    height: '',
  },
}
export default initialState
