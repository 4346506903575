import React from 'react'
import { connect } from 'react-redux'
import { saveSessionToServer } from '../../../../../src/services/sessionHelper'
import * as actionCreators from '../../../../../src/store/actions'
import { cloneObj, mapStateToProps } from '../../../../../src/utils'

import ECDroppableFieldComponent from '../../../../../src/components/common/fields/droppableField'
import ECSelectFieldComponent from '../../../../../src/components/common/fields/selectField'
import FieldShell from '../common/field_shell'
import JujoButtonComponent from '../../../../../src/components/jujo_button'
import * as apiService from '../../../../../src/services/apiService'

import { showMessageBox } from '../common/message_box'

const classNames = require('classnames')

export class OssigenoImports extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      import_status: 0,
    }
  }

  componentWillUnmount() {
    // fix Warning: Can't perform a React state update on an unmounted component
    this.setState = () => {}
  }

  handleFileUploaded = (_name, urls) => {
    this.handleParamChange('urls', urls)
  }

  handleParamChange = async (item, value) => {
    const { session, updateSession } = this.props
    const session_copy = cloneObj(session)
    const { session_id, session_data } = session_copy
    const { import_data } = session_data

    if (
      !import_data.hasOwnProperty(item) &&
      (item === 'urls' || item === 'custom_id_urls')
    ) {
      import_data[item] = []
    }
    console.log('item', item)
    console.log('import_data', import_data)
    if (Array.isArray(import_data[item])) {
      import_data[item] = import_data[item].concat(value)
    } else {
      import_data[item] = value
    }
    const returned_data = await saveSessionToServer(session_id, session_data)
    session_copy.session_data = returned_data
    await updateSession(session_copy)
  }

  handleCustomIdFileUploaded = (_name, urls) => {
    this.handleParamChange('custom_id_urls', urls)
  }

  handleImport = () => {
    const { session } = this.props
    const { session_data } = session
    const { import_data } = session_data
    const { urls, import_file_manually } = import_data
    if (import_file_manually === true) {
      if (!urls || urls.length === 0) {
        const response_error = {}
        response_error.data = {}
        response_error.data.status = 400
        response_error.data.message = 'Non hai selezionato il file da importare'
        showMessageBox(this, response_error, 'error')
      }
    }
    this.setState({ import_status: 1 })
  }

  handleDoImportCustomId = async () => {
    const { session, updateSession } = this.props
    const session_copy = cloneObj(session)
    const session_updated = cloneObj(session)
    const { session_id, session_data } = session_copy
    const session_data_updated = cloneObj(session_data)
    const { import_data } = session_data
    const { custom_id_urls } = import_data

    session_data_updated.import_data = {}
    const returned_data = await saveSessionToServer(
      session_id,
      session_data_updated
    )
    session_updated.session_data = returned_data
    await updateSession(session_updated)

    import_data.custom_id_urls = custom_id_urls
    import_data.session_id = session_id
    const parsedEp = 'action/post/importation/new-import-custom-id-for-youtube'
    const response = await apiService.httpPost(
      `${process.env.apiUrl}${parsedEp}`,
      import_data
    )
    const { status } = response
    if (status === 200 || status === 201) {
      showMessageBox(this, response, 'success')
      setTimeout(() => {
        window.location.href = '/admin/importations/'
      }, 4000)
    } else {
      showMessageBox(this, response, 'error')
    }
  }

  handleDoImport = async () => {
    const { session, updateSession } = this.props
    const session_copy = cloneObj(session)
    const session_updated = cloneObj(session)
    const { session_id, session_data } = session_copy
    const session_data_updated = cloneObj(session_data)
    const { import_data } = session_data
    const { import_file_manually } = import_data

    session_data_updated.import_data = {}
    const returned_data = await saveSessionToServer(
      session_id,
      session_data_updated
    )
    session_updated.session_data = returned_data
    await updateSession(session_updated)

    import_data.url = import_file_manually ? import_data.url : ''
    import_data.session_id = session_id
    const parsedEp = 'action/post/importation/new-import'
    const response = await apiService.httpPost(
      `${process.env.apiUrl}${parsedEp}`,
      import_data
    )
    const { status } = response
    if (status === 200 || status === 201) {
      showMessageBox(this, response, 'success')
      setTimeout(() => {
        window.location.href = '/admin/importations/'
      }, 4000)
    } else {
      showMessageBox(this, response, 'error')
    }
  }

  handleCancel = () => {
    this.setState({ import_status: 0 })
  }

  handleReset = async () => {
    const { session, updateSession } = this.props
    const session_updated = cloneObj(session)
    const { session_id, session_data } = session
    const session_data_updated = cloneObj(session_data)
    session_data_updated.import_data = {}
    const returned_data = await saveSessionToServer(
      session_id,
      session_data_updated
    )
    session_updated.session_data = returned_data
    await updateSession(session_updated)
    window.location.href = '/admin/new_import/'
  }

  render() {
    const { import_status } = this.state
    const { session } = this.props
    const { session_data } = session
    const { clients, months, import_data } = session_data
    const { import_file_manually, custom_id_urls } = import_data
    let { client_id, month } = import_data

    const clientField = {
      name: 'client_id',
      options: clients,
    }

    const monthField = {
      name: 'month',
      options: months,
    }

    client_id = client_id === undefined || client_id === null ? 0 : client_id
    month = month === undefined || client_id === null ? '' : month

    return (
      <div
        className={classNames(
          'bg-1-light p-5 d-flex flex-column align-items-center justify-content-center'
        )}
      >
        <div className={classNames('w-50')}>
          <div
            className={classNames(
              'flex-column align-items-center justify-content-center fs-3 fw-bold'
            )}
          >
            Nuova importazione
            <hr />
          </div>
          <div
            className={classNames(
              'flex-column align-items-center justify-content-center mb-3'
            )}
          >
            <h3>Importazione file compensi</h3>
          </div>
          <div
            className={classNames(
              'flex-column align-items-center justify-content-center mb-4'
            )}
          >
            <>
              <div className={classNames('my-3')}>
                <FieldShell fieldName="client_name" withBorder>
                  <ECSelectFieldComponent
                    field={clientField}
                    handleValueChanged={this.handleParamChange}
                    initialValue={parseInt(client_id, 10)}
                  />
                </FieldShell>
              </div>
              {client_id > 0 && (
                <div className={classNames('my-3')}>
                  <FieldShell fieldName="month" withBorder>
                    <ECSelectFieldComponent
                      field={monthField}
                      handleValueChanged={this.handleParamChange}
                      initialValue={month}
                    />
                  </FieldShell>
                </div>
              )}
              {client_id > 0 && month && import_file_manually && (
                <div className={classNames('my-3')}>
                  <FieldShell fieldName="file" withBorder>
                    <ECDroppableFieldComponent
                      field={{
                        name: 'file_field',
                        fieldType: 'droppableField',
                        customWidth: 'col-12',
                        acceptedTypes: 'text/csv',
                        maxFileSize: '1000MB',
                        relatedEntity: 'import',
                        visibility: 'private',
                        fileOwner: {
                          source: 'static',
                          relatedField: '',
                          defValue: 0,
                        },
                      }}
                      handleValueChanged={this.handleFileUploaded}
                    />
                  </FieldShell>
                </div>
              )}
              {client_id > 0 && month && import_status === 0 && (
                <div
                  className={classNames(
                    'd-flex align-items-center justify-content-between my-5'
                  )}
                >
                  <div>
                    <JujoButtonComponent
                      bstyle={2}
                      bwidth="200px"
                      blabel="Resetta"
                      handleClick={() => {
                        this.handleReset()
                      }}
                    />
                  </div>
                  <div>
                    <JujoButtonComponent
                      bstyle={3}
                      bwidth="200px"
                      blabel="Importa"
                      handleClick={() => {
                        this.handleImport()
                      }}
                    />
                  </div>
                </div>
              )}
              {client_id > 0 && month && import_status === 1 && (
                <div>
                  <div>
                    L&apos;operazione potrebbe richiedere alcuni minuti. Sei
                    certo di voler importare il file per il cliente e mese
                    selezionati?
                  </div>
                  <div
                    className={classNames(
                      'd-flex align-items-center justify-content-between my-5'
                    )}
                  >
                    <div>
                      <JujoButtonComponent
                        bstyle={2}
                        bwidth="200px"
                        blabel="Annulla"
                        handleClick={() => {
                          this.handleCancel()
                        }}
                      />
                    </div>
                    <div>
                      <JujoButtonComponent
                        bstyle={3}
                        bwidth="200px"
                        blabel="Procedi"
                        handleClick={() => {
                          this.handleDoImport()
                        }}
                      />
                    </div>
                  </div>
                </div>
              )}
            </>
          </div>
          {client_id === 0 && (
            <div>
              <div
                className={classNames(
                  'flex-column align-items-center justify-content-center mt-5'
                )}
              >
                <hr />
                <h3>Importazione trasformazioni Custom ID per Youtube</h3>
              </div>
              <div className={classNames('my-3')}>
                <FieldShell fieldName="file" withBorder>
                  <ECDroppableFieldComponent
                    field={{
                      name: 'file_field',
                      fieldType: 'droppableField',
                      customWidth: 'col-12',
                      acceptedTypes:
                        'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel',
                      maxFileSize: '1000MB',
                      relatedEntity: 'import',
                      visibility: 'private',
                      fileOwner: {
                        source: 'static',
                        relatedField: '',
                        defValue: 0,
                      },
                    }}
                    handleValueChanged={this.handleCustomIdFileUploaded}
                  />
                </FieldShell>
              </div>
              {custom_id_urls != undefined && custom_id_urls.length > 0 && (
                <div>
                  <div className="border rounded-3 p-3">
                    <div key="file" className="my-1">
                      File selezionati:
                    </div>
                    {custom_id_urls.map((custom_id_url, index) => (
                      <div key={index} className="my-1">
                        {custom_id_url.name}
                      </div>
                    ))}
                  </div>
                  <div className="d-flex align-items-center justify-content-center mt-3">
                    <JujoButtonComponent
                      bstyle={3}
                      bwidth="300px"
                      blabel="Aggiorna Custom ID"
                      handleClick={() => {
                        this.handleDoImportCustomId()
                      }}
                    />
                  </div>
                </div>
              )}
            </div>
          )}
        </div>
      </div>
    )
  }
}
export default connect(mapStateToProps, actionCreators)(OssigenoImports)
