import merge from 'lodash/merge'
import { corePagesConfig } from '../../../../../../src/utils'

const data_transformations = require('./json/admin/data_transformations.json')

const specialized_admin = {
  settings: {
    data_transformations,
  },
}
const specialized_my_account = {}
const specialized_front = {}

const core_config = corePagesConfig()

const { core_admin, core_my_account, core_front, reset_password } = core_config

const admin = merge(core_admin, specialized_admin)
const my_account = merge(core_my_account, specialized_my_account)
const front = merge(core_front, specialized_front)

export { admin, my_account, front, reset_password }
