import React from 'react'
import { connect } from 'react-redux'
import uuid from 'react-uuid'

import * as actionCreators from '../../../../store/actions'
import { mapStateToProps } from '../../../../utils'
import { GenericField } from './genericField'

const classNames = require('classnames')

export class ImagesPickerField extends GenericField {
  renderField = () => {
    const { fieldValue } = this.state
    const { field, environment, specialization } = this.props
    const { images, height } = field
    const { translations } = specialization
    const { texts } = translations

    const { locale } = environment

    const html = []
    html.push(
      <div
        key={uuid()}
        className={classNames('row bg-light rounded-4 p-2')}
        style={{
          margin: '-6px -12px',
        }}
      >
        {images &&
          images.length &&
          images.map(image => (
            <div
              key={uuid()}
              style={{
                // width: imageWidth,
                height,
                filter: image.value === fieldValue ? '' : 'grayscale(100%)',
                opacity: image.value === fieldValue ? '1' : '.4',
              }}
              className={classNames('col mx-1 p-2 border rounded')}
              role="button"
              tabIndex={0}
              onClick={() => this.processOnChange(image.value)}
              onKeyPress={() => this.processOnChange(image.value)}
            >
              <div
                className={classNames('')}
                style={{
                  background: `url(${image.url})`,
                  backgroundSize: 'contain',
                  backgroundRepeat: 'no-repeat',
                  backgroundPosition: 'center',
                  height: '90%',
                }}
              />
              {image.name && image.name !== '' && (
                <div
                  className={classNames(
                    'd-flex justify-content-center align-self-baseline'
                  )}
                >
                  {texts[locale][image.name]}
                </div>
              )}
            </div>
          ))}
      </div>
    )
    return html
  }
}
export default connect(mapStateToProps, actionCreators)(ImagesPickerField)
