import merge from 'lodash/merge'
import { coreEntitesConfig } from '../../../../../../src/utils'

const importation = require('./json/importation.json')
const importation_file = require('./json/importation_file.json')
const fee = require('./json/fee.json')
const data_transformation = require('./json/data_transformation.json')

const specialized_entities = {
  importation,
  importation_file,
  fee,
  data_transformation,
}

const core_entities = coreEntitesConfig()
const list = merge(core_entities, specialized_entities)
export { list }
