import React from 'react'
import loadable from '@loadable/component'

import { connect } from 'react-redux'

import * as actionCreators from '../../store/actions'
import { mapStateToProps } from '../../utils'

const JujoParsableContentComponent = loadable(() =>
  import('./_parts/parsable_content')
)

const classNames = require('classnames')

export class JujoPageRendererComponent extends React.Component {
  constructor(props) {
    super(props)
    this.state = {}
  }

  componentDidMount = async () => {}

  componentWillUnmount() {
    // fix Warning: Can't perform a React state update on an unmounted component
    this.setState = () => {}
  }

  renderBlock = block => {
    const { session, environment } = this.props
    const { session_data } = session
    const { jujo_page } = session_data

    const { locale } = environment
    const { defaultLang } = process.env

    const html = []

    if (jujo_page && block in jujo_page) {
      const dynamic_block = jujo_page[block]
      const dynamic_block_lang =
        dynamic_block[locale] || dynamic_block[defaultLang]

      if (dynamic_block_lang) {
        html.push(
          <div key={block}>
            <JujoParsableContentComponent html={dynamic_block_lang} />
          </div>
        )
      }
    }
    return html
  }

  render() {
    const blocks_to_render = ['header', 'body_container', 'footer']

    return (
      <div className={classNames('')}>
        {blocks_to_render.map(block => this.renderBlock(block))}
      </div>
    )
  }
}

export default connect(
  mapStateToProps,
  actionCreators
)(JujoPageRendererComponent)
