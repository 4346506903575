export function useMediaQuery(query) {
  let { matches } = window.matchMedia(query)
  const mediaQuery = window.matchMedia(query)
  // eslint-disable-next-line no-return-assign
  const listener = e => (matches = e.matches)
  mediaQuery.addEventListener('change', listener)

  return matches
}
export function mqMobileOrTablet() {
  const check_rule = useMediaQuery('(max-width: 768px)')
  return check_rule
}
export function mqDesktop() {
  const check_rule = useMediaQuery('(min-width: 769px)')
  return check_rule
}
