import React from 'react'
import { connect } from 'react-redux'

import loadable from '@loadable/component'
import classNames from 'classnames'

import * as actionCreators from '../../../../../store/actions'
import {
  embedStyleCode,
  mapStateToProps,
  translate,
} from '../../../../../utils'

const JujoLoading = loadable(() => import('../../../../loading'))

export class JujoFontFamilyVariantSelectorComponent extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      loading: true,
      selected_src: '',
    }
  }

  componentWillUnmount() {
    // Fix Warning: Can't perform a React state update on an unmounted component
    this.setState = () => {}
  }

  componentDidMount = async () => {
    const { data } = this.props
    const { element_key } = data

    const style_obj = document.getElementById(`${element_key}_style`)
    let selected_src = ''
    if (style_obj) {
      const { textContent } = style_obj
      selected_src = textContent
        .replace(/\s/g, '')
        .split('src:url(')
        .pop()
        .split(')')[0]
        .slice(1, -1)
    }

    this.setState({ selected_src, loading: false })
  }

  handleVariantSelected = variant => {
    const { setFontVariant } = this.props
    const { src } = variant
    this.setState({ selected_src: src })
    setFontVariant(variant)
  }

  drawSelector = () => {
    const { data } = this.props
    const { selected_src } = this.state
    const html = []

    if (typeof document !== 'undefined') {
      const { family } = data

      const { data: variants } = family
      for (let i = 0; i !== variants.length; i += 1) {
        const variant = variants[i]
        const { key, src } = variant

        const fvariant_key = `variant_${key}`
        const font_face_code = `
        @font-face {
          font-family:'${key}';
          src: url('${src}');
        }`

        embedStyleCode(document, font_face_code, 'head', fvariant_key)

        html.push(
          <div
            key={key}
            style={{ fontFamily: `'${key}'` }}
            className={classNames(
              'p-1 mb-1 rounded-2',
              src === selected_src ? 'bg-orange-500 fc-white' : 'bg-white'
            )}
            role="button"
            tabIndex={0}
            onClick={() => {
              this.handleVariantSelected(variant)
            }}
            onKeyPress={() => {
              this.handleVariantSelected(variant)
            }}
          >
            {translate('use_this_variant')}
          </div>
        )
      }
    }
    return html
  }

  render() {
    const { loading } = this.state
    return (
      <>
        {loading === true && <JujoLoading />}
        {loading === false && (
          <div className={classNames('mb-2')}>{this.drawSelector()}</div>
        )}
      </>
    )
  }
}

export default connect(
  mapStateToProps,
  actionCreators
)(JujoFontFamilyVariantSelectorComponent)
