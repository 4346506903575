import loadable from '@loadable/component'
import React from 'react'
import { connect } from 'react-redux'

import * as actionCreators from '../../../../store/actions'
import { cloneObj, mapStateToProps } from '../../../../utils'

const JujoLoading = loadable(() => import('../../../loading'))
const ECDroppableFieldComponent = loadable(() =>
  import('../../fields/droppableField')
)

export class UploadVariantComponent extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      loading: true,
    }
  }

  componentWillUnmount() {
    // Fix Warning: Can't perform a React state update on an unmounted component
    this.setState = () => {}
  }

  componentDidMount = async () => {
    this.setState({ loading: false })
  }

  handleDropCompleted = async (_sender, value) => {
    const { updateFlowState, updateFlowData, transitionaData } = this.props

    const { url } = value[0]
    const flow_data = cloneObj(transitionaData)
    flow_data.src = url

    await updateFlowData(flow_data)
    await updateFlowState('edit_variant')
  }

  render() {
    const { loading } = this.state

    return (
      <>
        {loading === true && <JujoLoading />}
        {loading === false && (
          <ECDroppableFieldComponent
            field={{
              name: 'upload_font',
              fieldType: 'droppableField',
              customWidth: 'col-12',
              acceptedTypes: '.woff, .woff2, .ttf, .eot',
              maxFileSize: '3MB',
              maxFileCount: 1,
              relatedEntity: 'font',
              visibility: 'public',
              override: {
                onDropCompleted: {
                  action: this.handleDropCompleted,
                },
              },
              fileOwner: {
                source: 'static',
                relatedField: '',
                defValue: 'jujo_user_id',
              },
            }}
            handleValueChanged={() => {
              console.log(
                'this function should never be called in this component'
              )
            }}
          />
        )}
      </>
    )
  }
}

export default connect(mapStateToProps, actionCreators)(UploadVariantComponent)
