import React from 'react'
import { connect } from 'react-redux'

import * as actionCreators from '../../../store/actions'
import { mapStateToProps, retrieveComponent, translate } from '../../../utils'

const classNames = require('classnames')

export class FileRendererComponent extends React.Component {
  close = () => {
    const { comp_id } = this.props
    retrieveComponent(this, comp_id)
  }

  render() {
    const { data } = this.props
    const { url } = data

    return (
      <div
        className={classNames(
          'position-fixed w-100vw h-100vh start-0 top-0 bg-black-opacity-6 zindex-2'
        )}
      >
        <div
          className={classNames(
            'position-fixed end-20px top-20px p-1 rounded-3 fc-white d-flex align-items-center'
          )}
          role="button"
          tabIndex={0}
          label="close"
          onClick={() => {
            this.close()
          }}
          onKeyPress={() => {
            this.close()
          }}
        >
          <div
            className={classNames('background-image close-icon white-svg mx-1')}
            style={{ width: 10, height: 10 }}
          />
          <div>{translate('close')}</div>
        </div>
        <img
          alt="file"
          className={classNames(
            'position-fixed ofit-sdown w-90vw h-90vh start-5vw top-5vh'
          )}
          src={url}
        />
      </div>
    )
  }
}

export default connect(mapStateToProps, actionCreators)(FileRendererComponent)
