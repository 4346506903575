import React from 'react'
import { connect } from 'react-redux'

import * as actionCreators from '../../../../../store/actions'
import { mapStateToProps } from '../../../../../utils'

const classNames = require('classnames')

export class JujoLogoComponent extends React.Component {
  componentWillUnmount() {
    // Fix Warning: Can't perform a React state update on an unmounted component
    this.setState = () => {}
  }

  handleLogoClicked = () => {
    if (typeof window !== 'undefined') {
      window.location.href = '/'
    }
  }

  render() {
    return (
      <div
        className={classNames('logo-white')}
        style={{
          height: '25px',
          width: '125px',
          backgroundPosition: 'center',
          backgroundSize: 'contain',
          backgroundRepeat: 'no-repeat',
        }}
        label="logo"
        role="button"
        tabIndex={0}
        onClick={() => {
          this.handleLogoClicked()
        }}
        onKeyPress={() => {
          this.handleLogoClicked()
        }}
      />
    )
  }
}

export default connect(mapStateToProps, actionCreators)(JujoLogoComponent)
