function getEnvData() {
  const opsys = process.platform === 'linux' ? 'linux' : 'windows'

  const env_suffix = 'production'
  let app_slash = '/'
  if (opsys === 'windows') {
    // env_suffix = 'development'
    app_slash = '\\'
  }

  return {
    opsys,
    env_suffix,
    app_slash,
  }
}
exports.getEnvData = getEnvData

function processEnv() {
  const { env_suffix } = getEnvData()
  require('dotenv').config({
    path: `.env.${env_suffix}`,
  })
  return process.env
}
exports.processEnv = processEnv

function createFolderIfNotExists(path) {
  const fs = require('fs')

  if (!fs.existsSync(path)) {
    fs.mkdirSync(path, { recursive: true })
  }
}
exports.createFolderIfNotExists = createFolderIfNotExists

async function copyFile(from, to) {
  const fs = require('fs')
  const { promisify } = require('util')
  const copyFileAsync = promisify(fs.copyFile)

  await copyFileAsync(from, to)
}
exports.copyFile = copyFile

async function readFile(path) {
  const fs = require('fs')
  const { promisify } = require('util')
  const readFileAsync = promisify(fs.readFile)
  const data = await readFileAsync(path, 'utf8')
  return data
}
exports.readFile = readFile

async function writeFile(path, data) {
  const fs = require('fs')
  const { promisify } = require('util')
  const writeFileAsync = promisify(fs.writeFile)

  await writeFileAsync(path, data, 'utf-8')
}
exports.writeFile = writeFile

async function getApiData(path) {
  const fetch = require('node-fetch')
  const response = await fetch(path, {
    method: 'GET',
  })
  const data = await response.json()
  return data
}
exports.getApiData = getApiData

function parseHtml(data) {
  const html_parser = require('node-html-parser')
  const html = html_parser.parse(data)
  return html
}
exports.parseHtml = parseHtml
