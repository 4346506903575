import React from 'react'
import { connect } from 'react-redux'

import * as actionCreators from '../../../store/actions'
import { mapStateToProps, translate } from '../../../utils'
import NotificationComponent from '../notification'

const classNames = require('classnames')

export class ECSelectFieldComponent extends React.Component {
  componentWillUnmount() {
    // Fix Warning: Can't perform a React state update on an unmounted component
    this.setState = () => {}
  }

  notifyRemovedOptions = () => {
    const { environment, specialization, initialValue, field } = this.props

    const { locale } = environment
    const { translations } = specialization
    const { texts } = translations

    const html = []

    if (!initialValue || initialValue === '') return html

    const { options } = field
    const selected_record = options.filter(
      o => o.value.toString() === initialValue.toString()
    )
    if (selected_record.length === 0) {
      html.push(
        <NotificationComponent
          key="removed_option_notification"
          body={texts[locale].removed_option_warning_message}
        />
      )
    }
    return html
  }

  render() {
    const { field, custom_key, initialValue, handleValueChanged } = this.props

    const { name, options } = field

    const custom_key_updated = custom_key !== undefined ? `_${custom_key}` : ''

    return (
      <>
        {this.notifyRemovedOptions()}
        <select
          className={classNames('ffamily-primary')}
          name={name}
          defaultValue={initialValue}
          onChange={e => {
            const { value } = e.target
            handleValueChanged(name, value)
          }}
        >
          <option aria-label="empty" key="empty" value="">
            {translate('select')}
          </option>
          {options.map(opt => (
            <option
              key={`${opt.label}_${opt.value}${custom_key_updated}`}
              value={opt.value}
            >
              {translate(opt.label)}
            </option>
          ))}
        </select>
      </>
    )
  }
}

export default connect(mapStateToProps, actionCreators)(ECSelectFieldComponent)
