import React from 'react'
import { connect } from 'react-redux'

import * as actionCreators from '../../../../../store/actions'
import { mapStateToProps } from '../../../../../utils'

const classNames = require('classnames')

export class MobileSlideableComponent extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      visible: false,
    }
  }

  componentWillUnmount() {
    // Fix Warning: Can't perform a React state update on an unmounted component
    this.setState = () => {}
  }

  handleLogoClicked = () => {
    if (typeof window !== 'undefined') {
      window.location.href = '/'
    }
  }

  renderBodyComponent = () => {
    const { mobileSlideableProps } = this.props
    const { static_name, body_component, specialized } = mobileSlideableProps
    let DynamicComponent = null
    if (specialized === 'true') {
      DynamicComponent =
        require(`../../../../../../jujo_specializations/src/${process.env.client}/components/${body_component}`).default
    } else {
      DynamicComponent = require(`../../../../${body_component}`).default
    }

    const html = []
    html.push(<DynamicComponent key={static_name} />)
    return html
  }

  render() {
    const { visible } = this.state
    const { mobileSlideableProps } = this.props
    const { visible_icon, hidden_icon, icon_svg_class, bg_color, text_color } =
      mobileSlideableProps
    return (
      <>
        <div
          className={classNames(
            icon_svg_class,
            visible ? visible_icon : hidden_icon
          )}
          style={{
            height: '25px',
            width: '25px',
            backgroundPosition: 'center',
            backgroundSize: 'contain',
            backgroundRepeat: 'no-repeat',
          }}
          label="burger_menu"
          role="button"
          tabIndex={0}
          onClick={() => {
            this.setState({
              visible: !visible,
            })
          }}
          onKeyPress={() => {
            this.setState({
              visible: !visible,
            })
          }}
        />
        <div
          className={classNames(
            'd-flex d-lg-none flex-column position-absolute p-2 fs-6 text-center shadow',
            bg_color,
            text_color
          )}
          style={{
            left: visible ? '0' : '-200vw',
            top: '56px',
            width: '100vw',
            transition: 'all 0.5s ease-in-out 0s',
            minHeight: 'calc(100vh - 56px)',
          }}
        >
          {this.renderBodyComponent()}
        </div>
      </>
    )
  }
}

export default connect(
  mapStateToProps,
  actionCreators
)(MobileSlideableComponent)
