import React, { useState } from 'react'
import classNames from 'classnames'

function JujoSVGEditableName({ name, updateName }) {
  const [isEditing, setIsEditing] = useState(false)
  const [inputValue, setInputValue] = useState(name)
  const [touchCount, setTouchCount] = useState(0)

  function handleTouchEnd() {
    setTouchCount(count => count + 1)
    if (touchCount === 2) {
      setIsEditing(true)
      setTouchCount(0)
    }
  }

  function handleDoubleClick() {
    setIsEditing(true)
  }

  function handleChange(e) {
    setInputValue(e.target.value)
  }

  function handleBlur() {
    updateName(inputValue)
    setIsEditing(false)
  }

  return (
    <div
      className={classNames('fw-bold')}
      onDoubleClick={handleDoubleClick}
      onTouchEnd={handleTouchEnd}
    >
      {isEditing ? (
        <input
          className={classNames('text-center fw-bold fc-blue-500')}
          type="text"
          value={inputValue}
          onChange={handleChange}
          onBlur={handleBlur}
          autoFocus
        />
      ) : (
        name
      )}
    </div>
  )
}

export default JujoSVGEditableName
