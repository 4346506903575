import React from 'react'
import { connect } from 'react-redux'
import * as actionCreators from '../../../../../../src/store/actions'
import { mapStateToProps } from '../../../../../../src/utils'

const classNames = require('classnames')

export class OssigenoMulticheckButtons extends React.Component {
  constructor(props) {
    super(props)
    this.state = {}
  }

  componentWillUnmount() {
    // fix Warning: Can't perform a React state update on an unmounted component
    this.setState = () => {}
  }

  componentDidMount = async () => {}

  handleClick = async item => {
    const { onClick } = this.props
    await onClick(item)
  }

  render() {
    const { data, label } = this.props

    return (
      <div className={classNames('fs-8 text-center')}>
        <div className={classNames('fw-bold mb-2')} key="clients_label">
          {label}
        </div>
        <div className={classNames('row p-0 m-0')}>
          {data &&
            data.map(item => {
              const { selected, name, id, purging_count } = item || {}
              return (
                <div
                  key={`${label}_${id}`}
                  role="button"
                  tabIndex={0}
                  onClick={() => {
                    this.handleClick(item)
                  }}
                  onKeyPress={() => {
                    this.handleClick(item)
                  }}
                  className={classNames(
                    'col-6 col-md-4 border py-2 bg-white d-flex align-items-center justify-content-center position-relative',
                    selected ? 'bg-1 fc-white' : ''
                  )}
                >
                  {name}
                  {purging_count && purging_count !== '0' && (
                    <div
                      className={classNames(
                        'position-absolute zindex-dropdown fs-9 border-0 rounded-3 bg-danger px-2 fc-white fw-bold'
                      )}
                      style={{ top: '3px', right: '4px' }}
                    >
                      {purging_count}
                    </div>
                  )}
                </div>
              )
            })}
        </div>
      </div>
    )
  }
}

export default connect(
  mapStateToProps,
  actionCreators
)(OssigenoMulticheckButtons)
