import {
  applyMiddleware,
  combineReducers,
  compose,
  createStore as reduxCreateStore,
} from 'redux'
import thunk from 'redux-thunk'
import authentication from './reducers/authentication'
import environment from './reducers/environment'
import injectedComponents from './reducers/injected_components'
import session from './reducers/session'
import specialization from './reducers/specialization'

const rootReducer = combineReducers({
  authentication,
  environment,
  specialization,
  session,
  injectedComponents,
})

const composeEnhancers =
  typeof window === 'object' && window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
    ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
    : compose

const createLightStore = () =>
  reduxCreateStore(rootReducer, composeEnhancers(applyMiddleware(thunk)))
export default createLightStore
