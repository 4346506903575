import classNames from 'classnames'
import React from 'react'
import { translate } from '../../../../../utils'

const SVGSwitcher = ({ changeSide, curr_side }) => {
  const switcherBtn = (label, side) => (
    <div
      className={classNames(
        'fw-bold fst-uppercase',
        curr_side === side ? 'fc-1' : ''
      )}
      role="button"
      tabIndex={0}
      onClick={() => {
        changeSide(side)
      }}
      onKeyPress={() => {
        changeSide(side)
      }}
    >
      {translate(label)}
    </div>
  )

  return (
    <div
      className={classNames(
        'd-flex justify-content-center align-items-center mb-2'
      )}
    >
      {switcherBtn('front_side', 'front')}
      <div className={classNames('mx-2')}>|</div>
      {switcherBtn('back_side', 'back')}
    </div>
  )
}
export default SVGSwitcher
