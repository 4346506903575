import React from 'react'
import { connect } from 'react-redux'
import { saveSessionToServer } from '../../../../../../src/services/sessionHelper'
import * as actionCreators from '../../../../../../src/store/actions'
import { cloneObj, mapStateToProps } from '../../../../../../src/utils'

const classNames = require('classnames')

export class OssigenoWizard extends React.Component {
  constructor(props) {
    super(props)
    this.state = {}
  }

  componentWillUnmount() {
    // fix Warning: Can't perform a React state update on an unmounted component
    this.setState = () => {}
  }

  componentDidMount = async () => {}

  handleStepClicked = async id => {
    const { session, updateSession } = this.props
    const session_copy = cloneObj(session)
    const { session_id, session_data } = session_copy
    const { wizard } = session_data
    wizard.active_step = id
    const returned_data = await saveSessionToServer(session_id, session_data)
    session_copy.session_data = returned_data
    await updateSession(session_copy)
  }

  drawWizard = () => {
    const { session } = this.props
    const { session_data } = session
    const { wizard } = session_data || []
    const { steps } = wizard
    const html = []

    for (let i = 0; i < steps.length; i += 1) {
      const { id, label, selected, active, visited } = steps[i]

      let circle_class = 'bg-secondary fc-white'
      if (!selected) {
        circle_class = visited ? 'bg-white' : 'bg-light '
        if (active) {
          circle_class += visited ? ' fc-medium-grey' : ' fc-black'
        } else {
          circle_class += ' fc-medium-grey'
        }
      }
      circle_class += active && !selected ? ' border border-1 border-dark' : ''

      const label_class = !active ? 'fc-medium-grey' : 'fc-black'

      html.push(
        <div
          key={`circle_${id}`}
          className={classNames(
            'my-2 m-md-y-0 col-12 col-md p-0 d-flex flex-column align-items-center justify-content-center'
          )}
        >
          <div
            className={classNames(
              'fs-6 rounded-circle bg-secondary px-2 user-select-none',
              circle_class
            )}
            role="button"
            tabIndex={0}
            onClick={
              !active
                ? null
                : () => {
                    this.handleStepClicked(id)
                  }
            }
            onKeyPress={
              !active
                ? null
                : () => {
                    this.handleStepClicked(id)
                  }
            }
          >
            {id}
          </div>
          <div className={classNames('fs-8 mt-2', label_class)}>{label}</div>
        </div>
      )
      if (i < steps.length - 1) {
        html.push(
          <div
            key={`line_${id}`}
            className={classNames(
              'd-none d-md-block col-12 col-md p-0 d-flex align-items-center position-relative'
            )}
          >
            <hr style={{ position: 'absolute', width: '100%', top: '-62px' }} />
          </div>
        )
      }
    }

    return html
  }

  render() {
    const { session } = this.props
    const { session_data } = session
    const { wizard } = session_data
    const { label } = wizard

    return (
      <div className={classNames('d-flex flex-column ')}>
        <div
          className={classNames(
            'bg-1-light pt-5 pb-4 d-flex align-items-center justify-content-center w-100'
          )}
        >
          <div
            className={classNames('row w-100 w-md-50 d-flex align-items-end')}
          >
            {this.drawWizard()}
          </div>
        </div>
        <div
          className={classNames(
            'pt-0 pb-4  d-flex align-items-center justify-content-center w-100'
          )}
        >
          <div
            className={classNames(
              'bg-1-light text-black-50 pt-1 pb-2 px-5 fw-bold rounded-bottom '
            )}
          >
            {label}
          </div>
        </div>
      </div>
    )
  }
}
export default connect(mapStateToProps, actionCreators)(OssigenoWizard)
