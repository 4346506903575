const sourcesMap = {
  actionData: 'actionData',
  api: 'api',
  browserQueryString: 'browserQueryString',
  contextVariable: 'contextVariable',
  customAction: 'customAction',
  dynamicField: 'dynamicField',
  environment: 'environment',
  injectedData: 'injectedData',
  jujoGrid: 'jujoGrid',
  parent: 'parent',
  self: 'self',
  session: 'session',
  static: 'static',
  user: 'user',
}

export default sourcesMap
