import React from 'react'
import { connect } from 'react-redux'

import uuid from 'react-uuid'

import * as actionCreators from '../../../store/actions'
import { mapStateToProps } from '../../../utils'

const classNames = require('classnames')

export class ErrorsComponent extends React.Component {
  parseErrors = () => {
    const { response } = this.props
    const { data } = response

    const keys = Object.keys(data)
    const html = []

    function addError(list, err) {
      list.push(
        <div className={classNames('')} key={uuid()}>
          <div>{err}</div>
        </div>
      )
    }

    for (let i = 0; i !== keys.length; i += 1) {
      const k = keys[i]
      if (k !== 'trace') {
        const msg = data[k]
        if (Array.isArray(msg)) {
          for (let j = 0; j !== msg.length; j += 1) {
            addError(html, msg[j])
          }
        } else if (typeof msg === 'object') {
          const subKeys = Object.keys(msg)
          for (let j = 0; j !== subKeys.length; j += 1) {
            const sk = subKeys[j]
            addError(html, msg[sk])
          }
        } else if (k !== 'obj') {
          addError(html, msg)
        }
      }
    }
    return html
  }

  render() {
    return <div className={classNames('')}>{this.parseErrors()}</div>
  }
}

export default connect(mapStateToProps, actionCreators)(ErrorsComponent)
