/* eslint-disable import/no-cycle */
import React from 'react'
import { connect } from 'react-redux'

import * as actionCreators from '../../../store/actions'
import {
  calculateColumnTemplate,
  getGridDefinition,
} from '../helper/gridFunctions'
import { getStoredGridProps } from '../helper/lsInteractionFunctions'

import GridRowComponent from './_parts/row'
import ExpandableRow from '../components/expandableRow'
import { mapStateToProps } from '../../../utils'

const classNames = require('classnames')

export class GridRowsComponent extends React.Component {
  shouldComponentUpdate(prevProps) {
    const { entity, data, environment, selectedRows } = this.props
    if (
      entity !== prevProps.entity &&
      JSON.stringify(data) !== JSON.stringify(prevProps.data)
    ) {
      return true
    }

    if (
      entity === prevProps.entity &&
      JSON.stringify(data) !== JSON.stringify(prevProps.data)
    ) {
      return true
    }

    if (
      entity === prevProps.entity &&
      JSON.stringify(data) === JSON.stringify(prevProps.data) &&
      JSON.stringify(environment.grids) !==
        JSON.stringify(prevProps.environment.grids)
    ) {
      return true
    }

    if (
      JSON.stringify(selectedRows.idList) !==
      JSON.stringify(prevProps.selectedRows.idList)
    ) {
      return true
    }

    return false
  }

  componentDidMount = async () => {}

  componentWillUnmount() {
    // fix Warning: Can't perform a React state update on an unmounted component
    this.setState = () => {}
  }

  componentDidUpdate = async () => {}

  render() {
    const {
      entity,
      data,
      filteredColumns,
      gridComponent,
      gridRef,
      editableComponentProps,
      selectedRows,
    } = this.props
    const gridDefinition = getGridDefinition(this.props)
    const gridProps = getStoredGridProps(this.props)

    const { expandableRowContent } = gridDefinition
    return (
      <div
        className={classNames(
          'd-flex flex-column border-bottom fs-7 py-3 py-md-0 px-0'
        )}
        style={{ minHeight: data.data.length > 0 ? '300px' : 'unset' }}
      >
        {data.data.map(row => {
          const identifier = row[gridDefinition.identifier]
          return (
            <span key={identifier} className={classNames('p-0 jujo_grid_row')}>
              <div
                className={
                  classNames('d-block d-md-grid border-bottom ')
                  // 'row border-bottom fs-7 align-items-stretch py-3 py-md-0 mx-0 px-0 gy-0'
                }
                style={{
                  gridTemplateRows: '1fr',
                  gridAutoFlow: 'row',
                  gap: '0px 0px',
                  gridTemplateColumns: calculateColumnTemplate(
                    gridDefinition,
                    filteredColumns
                  ),
                }}
              >
                <GridRowComponent
                  gridComponent={gridComponent}
                  filteredColumns={filteredColumns}
                  entity={entity}
                  row={row}
                  selectedRows={selectedRows}
                  editableComponentProps={editableComponentProps}
                />
              </div>
              {expandableRowContent &&
                gridProps.rows[identifier] &&
                gridProps.rows[identifier].expanded && (
                  <ExpandableRow
                    key={`expanded_${identifier}`}
                    parentIdentifierValue={identifier}
                    entity={entity}
                    data={row}
                    gridRef={gridRef}
                  />
                )}
            </span>
          )
        })}
      </div>
    )
  }
}

export default connect(mapStateToProps, actionCreators)(GridRowsComponent)
