/* eslint-disable no-restricted-globals */
// import { createDragButton, deleteDragButton } from '../drag_button'
import { createRectBorder, deleteRectBorder } from '../rect_border'

export function bindDoubleClickToElement(svg_id, element, setFocusedElement) {
  function onDoubleClick(e) {
    function exitEditMode(evt) {
      const { target } = evt
      const { nodeName } = target
      if (nodeName !== 'svg') return

      setFocusedElement(null)
      deleteRectBorder()
      // deleteDragButton()
    }

    const { nodeName } = e.target
    const clicked_element =
      nodeName === 'image' ? e.target : e.target.closest('text')
    const g_element = clicked_element.parentElement

    const element_key = g_element.getAttribute('id')
    setFocusedElement(element_key)
    createRectBorder(clicked_element)
    // createDragButton(element_key)

    const svg = document.getElementById(svg_id)
    svg.addEventListener('click', exitEditMode)
  }

  element.addEventListener('dblclick', onDoubleClick)
  element.addEventListener('touchstart', onDoubleClick)
}
