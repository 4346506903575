import React from 'react'
import { connect } from 'react-redux'
import uuid from 'react-uuid'
import requestTypesMap from '../../../../enums/requestTypesMap'

import * as actionCreators from '../../../../store/actions'
import { mapStateToProps } from '../../../../utils'
import { GenericField } from './genericField'

const classNames = require('classnames')

export class BooleanField extends GenericField {
  renderField = () => {
    const { fieldValue } = this.state
    const { field, environment, specialization, dynamicForm } = this.props
    const { labels } = field
    const { translations } = specialization
    const { texts } = translations
    const { locale } = environment
    const { type } = dynamicForm

    const html = []

    if (type === requestTypesMap.view) {
      html.push(
        <div key={uuid()}>
          {fieldValue === 1 || fieldValue === true
            ? texts[locale][labels.true]
            : texts[locale][labels.false]}
        </div>
      )
    } else {
      html.push(
        <div
          key={uuid()}
          className={classNames(
            'rounded-4 bg-1 text-center p-1 d-flex justify-content-center align-items-center'
          )}
          style={{ margin: '-6px -12px' }}
        >
          <span
            className={classNames(
              fieldValue === 1 || fieldValue === true
                ? 'text-center bg-white shadow-sm rounded-4'
                : '',
              'text-center'
            )}
            style={{
              width: 'calc(50%)',
              lineHeight: '28px',
              color: fieldValue === 1 || fieldValue === true ? '#000' : '#fff',
            }}
            role="button"
            tabIndex={0}
            onClick={() => {
              this.processOnChange(1)
            }}
            onKeyPress={() => {
              this.processOnChange(1)
            }}
          >
            {texts[locale][labels.true]}
          </span>
          <span
            className={classNames(
              fieldValue === 0 || fieldValue === false
                ? 'text-center bg-white shadow-sm rounded-4'
                : '',
              'text-center'
            )}
            style={{
              width: 'calc(50%)',
              lineHeight: '28px',
              color: fieldValue === 0 || fieldValue === false ? '#000' : '#fff',
            }}
            role="button"
            tabIndex={0}
            onClick={() => {
              this.processOnChange(0)
            }}
            onKeyPress={() => {
              this.processOnChange(0)
            }}
          >
            {texts[locale][labels.false]}
          </span>
        </div>
      )
    }
    return html
  }
}

export default connect(mapStateToProps, actionCreators)(BooleanField)
