import classNames from 'classnames'
import React from 'react'
import { connect } from 'react-redux'
import Parser from 'html-react-parser'
import uuid from 'react-uuid'
import * as actionCreators from '../../../../store/actions'
import { mapStateToProps, getCustomizationParam } from '../../../../utils'

export class JujoBlogArchiveView extends React.Component {
  componentWillUnmount() {
    // Fix Warning: Can't perform a React state update on an unmounted component
    this.setState = () => {}
  }

  renderSmallArticleCategorieNames = (category_ids_array, small_key) => {
    const { session } = this.props
    const { session_data } = session
    const { blog_data } = session_data
    const { categories } = blog_data
    const html = []
    const category_parsed_array = []
    for (let i = 0; i < categories.length; i += 1) {
      const item = categories[i]
      if (category_ids_array.includes(item.id.toString())) {
        category_parsed_array.push(item)
      }
    }
    html.push(
      <div
        key={`small_key_container_${small_key}`}
        className={classNames('d-flex align-items-center fs-9')}
      >
        {category_parsed_array &&
          category_parsed_array.map(
            (single_category, single_category_index) => {
              const { category_name_translated, category_customizations } =
                single_category
              const bg = getCustomizationParam(category_customizations, 'bg')
              return (
                <div
                  key={`small_key_${small_key}_${single_category_index}`}
                  className={classNames(bg, 'px-2 me-1 fc-white')}
                >
                  {category_name_translated}
                </div>
              )
            }
          )}
      </div>
    )
    return html
  }

  renderList = (articles_key, articles) => {
    const { environment } = this.props
    const { locale } = environment

    const html = []

    for (let i = 0; i !== articles.data.length; i += 1) {
      const article = articles.data[i]
      const { title, url, cover_image, short_description, category_ids } =
        article
      let cover_image_translated = ''
      // eslint-disable-next-line no-prototype-builtins
      if (cover_image && cover_image.hasOwnProperty(locale)) {
        cover_image_translated = cover_image[locale]
      }
      const cover_url = `${process.env.apiServerUrl}media/file?slug=${cover_image_translated}`

      const category_ids_array = (category_ids || '').split(',')
      html.push(
        <div key={uuid()} className={classNames('col-12 col-md-4')}>
          <a
            href={url[locale] || ''}
            alt={title[locale] || ''}
            className={classNames('text-decoration-none fc-5')}
          >
            <div className={classNames('d-flex flex-column w-100')}>
              <div>
                <img
                  src={cover_url}
                  className={classNames('img-fluid')}
                  alt={title[locale]}
                  title={title[locale]}
                />
              </div>
              <div className={classNames('p-3')} style={{ marginTop: '-50px' }}>
                <div className={classNames('bg-white p-3 d-flex flex-column')}>
                  {this.renderSmallArticleCategorieNames(category_ids_array, i)}

                  <div className={classNames('py-2 fw-bold')}>
                    {Parser(title[locale] || '')}
                  </div>
                  <div className={classNames('py-2 fs-8')}>
                    {Parser(`${short_description[locale]} [...]` || '')}
                  </div>
                </div>
              </div>
            </div>
          </a>
        </div>
      )
    }

    return html
  }

  renderCategories = () => {
    const { specialization, environment, session } = this.props
    const { translations } = specialization
    const { texts } = translations
    const { locale } = environment
    const { session_data } = session
    const { blog_data } = session_data
    let { categories } = blog_data
    const { category_selected_id } = blog_data

    const all = {
      id: 0,
      category_name_translated: texts[locale].all,
      category_customizations: 'fc-5',
      category_url_translated: `/${locale}/blog/`,
    }
    categories = [all, ...categories]
    const html = []
    html.push()
    for (let i = 0; i < categories.length; i += 1) {
      const {
        category_name_translated,
        category_customizations,
        category_url_translated,
        id,
      } = categories[i]
      const fc = getCustomizationParam(category_customizations, 'fc')
      const border_color = getCustomizationParam(
        category_customizations,
        'border-color'
      )

      let category_selected_text_style = ''
      let category_selected_border_style = 'border-1'
      if (
        id !== null &&
        category_selected_id !== null &&
        parseInt(category_selected_id, 10) === parseInt(id, 10)
      ) {
        category_selected_text_style = 'fw-bold'
        category_selected_border_style = 'border-2'
      }
      html.push(
        <div key={i} className={classNames('w-100 col-12 col-md px-2')}>
          <div
            className={classNames(
              'border-bottom ',
              border_color,
              category_selected_border_style
            )}
          >
            <a
              href={category_url_translated}
              alt={category_name_translated}
              className={classNames('text-decoration-none')}
            >
              <div className={classNames('w-100 text-center fs-5 p-2')}>
                <div className={classNames(fc, category_selected_text_style)}>
                  {category_name_translated}
                </div>
              </div>
            </a>
          </div>
        </div>
      )
    }
    return html
  }

  render() {
    const { specialization, environment, session } = this.props
    const { translations } = specialization
    const { texts } = translations
    const { locale } = environment

    const { session_data } = session
    const { blog_data } = session_data
    const { articles, last_articles } = blog_data

    return (
      <div className={classNames('container py-1 py-md-5')}>
        <div className={classNames('d-flex flex-column')}>
          <div className={classNames('row mb-4')}>
            {this.renderCategories()}
          </div>
          <h1 className={classNames('fs-4 fc-1 pt-4 pb-5 text-center')}>
            {texts[locale].recent_articles}
          </h1>
          <div className={classNames('row')}>
            {this.renderList('last_articles', last_articles)}
          </div>
          <h2 className={classNames('fs-4 fc-1 pt-4 pb-5 text-center')}>
            {texts[locale].all_articles}
          </h2>
          <div className={classNames('row')}>
            {this.renderList('articles', articles)}
          </div>
        </div>
      </div>
    )
  }
}

export default connect(mapStateToProps, actionCreators)(JujoBlogArchiveView)
