import React from 'react'
import { connect } from 'react-redux'

import uuid from 'react-uuid'
import * as actionCreators from '../../../store/actions'

import ErrorsComponent from '../error_component'
import { mapStateToProps } from '../../../utils'

import '../styles.scss'
import { lsGet } from '../../../services/localStorage'
import lsEntryMap from '../../../enums/lsEntryMap'
import { httpGet, httpPost } from '../../../services/apiService'

const classNames = require('classnames')

export class LoginComponent extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      email: '',
      password: '',
      processing_request: false,
      forgot_password: false,
      error: {},
      response_message: '',
    }

    this.passwordFieldRef = React.createRef()
  }

  handleShowPassFieldValue = fieldReferenceObj => {
    const { current } = fieldReferenceObj
    const { type } = current
    current.type = type === 'text' ? 'password' : 'text'
  }

  calcualteTokenExpirationTime = expiresIn => {
    const expirationDate = new Date()
    expirationDate.setSeconds(expirationDate.getSeconds() + expiresIn)

    return expirationDate.getTime()
  }

  renderErrorsMessage = result => {
    this.setState({ error: result, processing_request: false })
  }

  handleGoBackToHomepage = () => {
    if (typeof window !== 'undefined') {
      window.location.href = '/my-account/'
    }
  }

  getUserData = async tokenData => {
    const { authentication, updateAuthentication } = this.props

    const { access_token } = tokenData

    const additionalHeaders = {
      headers: { Authorization: `Bearer ${access_token}` },
    }

    const result = await httpGet(
      `${process.env.apiUrl}me`,
      false,
      additionalHeaders
    )
    if (result) {
      const { status, data } = result
      if (status === 200) {
        const updatedAuth = { ...authentication }
        updatedAuth.user = data.user
        updatedAuth.force_login = false
        updatedAuth.access_token = access_token
        await updateAuthentication(updatedAuth)
      } else {
        this.renderErrorsMessage(result)
      }
    }
  }

  handleLoginClicked = async () => {
    const { email, password } = this.state
    this.setState({ error: {}, processing_request: true })

    const result = await httpPost(
      process.env.loginUrl,
      {
        username: email,
        password,
        client_id: process.env.clientId,
        client_secret: process.env.clientSecret,
        grant_type: process.env.grantType,
        scope: '',
      },
      false
    )
    if (result) {
      const { status, data } = result
      if (status === 200) {
        await this.getUserData(data)
      } else {
        this.renderErrorsMessage(result)
      }
    }
  }

  handleForgotPasswordClicked = async () => {
    const { email } = this.state
    this.setState({ error: {}, processing_request: true })

    const environment = lsGet(lsEntryMap.environment)
    const { locale } = environment

    const result = await httpPost(
      `${process.env.apiUrl}reset-password-request`,
      { email, locale },
      false
    )
    if (result) {
      const { status, data } = result
      if (status === 200) {
        const { message } = data
        this.setState({ processing_request: false, response_message: message })
      } else {
        this.renderErrorsMessage(result)
      }
    }
  }

  handleDisableForceLogin = async () => {
    const { updateAuthentication, authentication } = this.props
    const updatedAuthentication = { ...authentication }
    updatedAuthentication.force_login = false
    await updateAuthentication(updatedAuthentication)
  }

  renderLoading = () => {
    const { environment, specialization } = this.props
    const { translations } = specialization
    const { texts } = translations
    const { locale } = environment

    const html = []

    html.push(
      <div
        key="loading"
        className={classNames(
          'd-flex justify-content-center align-items-center p-2 shadow-sm'
        )}
      >
        {texts[locale].loading}
        <div
          className={classNames('loading-icon theme-svg ms-1')}
          style={{
            backgroundPosition: 'center',
            backgroundSize: '30px 30px',
            backgroundRepeat: 'no-repeat',
            height: '30px',
            width: '30px',
          }}
        />
      </div>
    )

    return html
  }

  render() {
    const { error, processing_request, forgot_password, response_message } =
      this.state
    const { environment, specialization, authentication } = this.props
    const { translations } = specialization
    const { texts } = translations
    const { locale } = environment
    const { force_login } = authentication

    return (
      <div
        className={classNames(
          'login_controller bg-gradient-auth d-flex justify-content-center align-items-center'
        )}
        style={{
          height: '100vh',
          backgroundImage: `url(/${process.env.client}/authbg.webp)`,
          backgroundPosition: 'center',
          backgroundRepeat: 'no-repeat',
          backgroundSize: 'cover',
        }}
      >
        <div className={classNames('d-flex flex-column')}>
          <div
            className={classNames('container-fluid rounded shadow p-4 p-md-5')}
            style={{ maxWidth: '400px', background: '#ffffffeb' }}
          >
            <div
              className={classNames('logo mb-3')}
              style={{
                height: '80px',
                width: '100%',
                backgroundRepeat: 'no-repeat',
                backgroundSize: 'contain',
                backgroundPosition: 'center',
              }}
            />

            {forgot_password && (
              <>
                <div
                  className={classNames(
                    'fs-2 fw-bold mb-4 text-center ffamily-secondary'
                  )}
                >
                  {texts[locale].reset_password}
                </div>
                <div className={classNames('f-flex flex-column px-2 mb-5')}>
                  <input
                    className={classNames(
                      'form-control rounded-0 border-top-0 border-start-0 border-end-0 mb-3'
                    )}
                    type="email"
                    placeholder={texts[locale].login_email}
                    onChange={e => {
                      this.setState({ email: e.target.value })
                    }}
                  />
                </div>
                {Object.keys(error).length > 0 && (
                  <div
                    className={classNames(
                      'text-break mb-3 fc-red border p-2 fs-8'
                    )}
                    style={{
                      maxHeight: '100px',
                      overflowY: 'auto',
                    }}
                    key={uuid()}
                  >
                    <ErrorsComponent response={error} />
                  </div>
                )}
                {response_message !== '' && (
                  <div
                    key="resp_message"
                    className={classNames(
                      'mb-2 container p-2 border text-center'
                    )}
                  >
                    {response_message}
                  </div>
                )}
                {!processing_request && response_message === '' && (
                  <>
                    <div
                      role="button"
                      tabIndex={0}
                      onClick={() => {
                        this.handleForgotPasswordClicked()
                      }}
                      onKeyPress={() => {
                        this.handleForgotPasswordClicked()
                      }}
                      className={classNames({ theme_btn: true })}
                    >
                      {texts[locale].reset}
                    </div>
                    <div
                      role="button"
                      tabIndex={0}
                      onClick={() => {
                        this.handleGoBackToHomepage()
                      }}
                      onKeyPress={() => {
                        this.handleGoBackToHomepage()
                      }}
                      style={{ marginTop: '15px' }}
                      className={classNames({ theme_btn: true })}
                    >
                      {texts[locale].cancel}
                    </div>
                  </>
                )}
                {processing_request && this.renderLoading()}
              </>
            )}

            {!forgot_password && (
              <>
                <div
                  className={classNames(
                    'fs-1 mb-4 text-center ffamily-secondary'
                  )}
                >
                  {texts[locale].authentication}
                </div>
                <div className={classNames('f-flex flex-column px-2 mb-5')}>
                  <input
                    className={classNames(
                      'form-control rounded-0 border-top-0 border-start-0 border-end-0 mb-3'
                    )}
                    type="email"
                    placeholder={texts[locale].login_email}
                    onChange={e => {
                      this.setState({ email: e.target.value })
                    }}
                  />
                  <div className={classNames('position-relative')}>
                    <input
                      ref={this.passwordFieldRef}
                      className={classNames(
                        'form-control rounded-0 border-top-0 border-start-0 border-end-0 mb-3'
                      )}
                      type="password"
                      placeholder={texts[locale].password}
                      onChange={e => {
                        this.setState({ password: e.target.value })
                      }}
                    />
                    <div
                      className={classNames(
                        'eye-icon theme-svg position-absolute top-50 end-0 translate-middle-y'
                      )}
                      style={{
                        backgroundPosition: 'center',
                        backgroundSize: 'contain',
                        backgroundRepeat: 'no-repeat',
                        height: '20px',
                        width: '20px',
                      }}
                      label="show/hide"
                      role="button"
                      tabIndex={0}
                      onClick={() => {
                        this.handleShowPassFieldValue(this.passwordFieldRef)
                      }}
                      onKeyPress={() => {
                        this.handleShowPassFieldValue(this.passwordFieldRef)
                      }}
                    />
                  </div>
                </div>
                {Object.keys(error).length > 0 && (
                  <div
                    className={classNames(
                      'text-break mb-3 fc-red border p-2 fs-8'
                    )}
                    style={{
                      maxHeight: '100px',
                      overflowY: 'auto',
                    }}
                    key={uuid()}
                  >
                    <ErrorsComponent response={error} />
                  </div>
                )}
                {!processing_request && (
                  <div
                    role="button"
                    tabIndex={0}
                    onClick={() => {
                      this.handleLoginClicked()
                    }}
                    onKeyPress={() => {
                      this.handleLoginClicked()
                    }}
                    className={classNames({ theme_btn: true })}
                  >
                    {texts[locale].login}
                  </div>
                )}
                {processing_request && this.renderLoading()}
                <div
                  className={classNames(
                    'd-flex align-items-center mt-3 justify-content-center border p-1'
                  )}
                  role="button"
                  tabIndex={0}
                  onClick={() => {
                    this.setState({ forgot_password: true })
                  }}
                  onKeyPress={() => {
                    this.setState({ forgot_password: true })
                  }}
                >
                  <div
                    className={classNames('reset-password-icon theme-svg me-1')}
                    style={{
                      backgroundPosition: 'center',
                      backgroundSize: 'contain',
                      backgroundRepeat: 'no-repeat',
                      height: '25px',
                      width: '25px',
                    }}
                  />
                  <div>{`${texts[locale].forgot_password} ?`}</div>
                </div>
                {force_login && (
                  <div
                    className={classNames(
                      'd-flex align-items-center mt-3 justify-content-center border p-1 fw-bold'
                    )}
                    role="button"
                    tabIndex={0}
                    onClick={() => {
                      this.handleDisableForceLogin()
                    }}
                    onKeyPress={() => {
                      this.handleDisableForceLogin()
                    }}
                  >
                    <div>{texts[locale].go_back}</div>
                  </div>
                )}
              </>
            )}
          </div>
          <div
            className={classNames(
              'tracking-in-expand d-flex justify-content-center align-items-center text-center fs-8 mt-2 fst-italic'
            )}
          >
            <div className={classNames('me-1')}>Powered by</div>
            <div
              className={classNames('jujo-icon')}
              style={{
                width: '10px',
                height: '10px',
                backgroundPosition: 'center',
                backgroundSize: 'contain',
                backgroundRepeat: 'no-repeat',
                opacity: '0.7',
              }}
            />
            <div className={classNames('ms-1')}>Jujo Framework</div>
          </div>
        </div>
      </div>
    )
  }
}

export default connect(mapStateToProps, actionCreators)(LoginComponent)
