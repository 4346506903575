import classNames from 'classnames'
import React from 'react'
import { connect } from 'react-redux'

import * as actionCreators from '../../store/actions'
import { mapStateToProps } from '../../utils'

export class JujoBlog extends React.Component {
  componentWillUnmount() {
    // Fix Warning: Can't perform a React state update on an unmounted component
    this.setState = () => {}
  }

  renderDynamicController = () => {
    const { session } = this.props
    const { session_data } = session

    const { blog_data } = session_data
    const { view } = blog_data
    const DynamicViewComponent = require(`./views/${view}`).default

    const html = []
    html.push(<DynamicViewComponent key={view} />)
    return html
  }

  render() {
    return (
      <div className={classNames('')}>{this.renderDynamicController()}</div>
    )
  }
}

export default connect(mapStateToProps, actionCreators)(JujoBlog)
