export function saveFontVariant(sender) {
  const { state, props } = sender
  const { c_def } = props
  const { injections } = c_def
  const { handleEditVariantCompleted } = injections

  const { localDataSource } = state

  handleEditVariantCompleted(localDataSource)
}
