import React, { useState, useEffect } from 'react'
import classNames from 'classnames'
import loadable from '@loadable/component'
import { translate } from '../../../../../utils'

const JujoLoading = loadable(() => import('../../../../loading'))

function ImageMultiplyManager({ element_key }) {
  const [img_element, setImageElement] = useState(null)
  const [filter_multiply, setFilterMultiply] = useState('')

  const initData = async () => {
    const g_element = document.getElementById(element_key)
    const img_el = g_element.getElementsByTagName('image')[0]

    const f_multiply = img_el.style.mixBlendMode

    setImageElement(img_el)
    setFilterMultiply(f_multiply)
  }

  useEffect(() => {
    const execInitData = async () => {
      await initData()
    }

    execInitData()
  }, [])

  const handleFilterClicked = () => {
    const f_multiply = filter_multiply === '' ? 'multiply' : ''
    img_element.style.mixBlendMode = f_multiply
    setFilterMultiply(f_multiply)
  }

  return (
    <>
      {img_element === null && <JujoLoading />}
      {img_element !== null && (
        <div className={classNames('d-flex flex-column flex-md-row mb-md-2')}>
          <div className={classNames('me-md-3 mb-2 mb-md-0')}>
            <div
              className={classNames('fw-bold ffamily-secondary fst-uppercase')}
            >
              <div>{translate('filter_multiply')}</div>
            </div>
            <div
              className={classNames(
                'background-image filter-multiply-icon',
                filter_multiply && 'theme-svg'
              )}
              style={{ width: 36, height: 36 }}
              role="button"
              tabIndex={0}
              label="multiply-image"
              onClick={() => {
                handleFilterClicked()
              }}
              onKeyPress={() => {
                handleFilterClicked()
              }}
            />
          </div>
        </div>
      )}
    </>
  )
}

export default ImageMultiplyManager
