import classNames from 'classnames'
import JoditEditor from 'jodit-react'
import React from 'react'
import { connect } from 'react-redux'

import * as actionCreators from '../../../store/actions'
import {
  cloneObj,
  jujoHexDecode,
  jujoHexEncode,
  mapStateToProps,
} from '../../../utils'
import JujoLoading from '../../loading'

export class ECMultilingualFieldComponent extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      initialized: false,
      field_value: {},
    }
  }

  componentDidMount = async () => {
    const { field, dataSource } = this.props
    const { name } = field

    const field_value = dataSource[name] || {}

    this.setState({ initialized: true, field_value })
  }

  shouldComponentUpdate(prevProps, prevState) {
    const { initialized } = this.state
    const { field, dataSource } = this.props
    const { name } = field

    const current_field_value = JSON.stringify(dataSource[name])
    const prev_field_value = JSON.stringify(prevProps.dataSource[name])

    let update = false
    if (current_field_value !== prev_field_value) {
      update = true
    }
    if (initialized !== prevState.initialized) {
      update = true
    }

    return update
  }

  componentWillUnmount() {
    // Fix Warning: Can't perform a React state update on an unmounted component
    this.setState = () => {}
  }

  processOnChange = (key, value) => {
    const { field_value } = this.state

    const current_field_value = cloneObj(field_value)
    current_field_value[key] = value

    this.setState({ field_value: current_field_value })
  }

  processOnBlur = () => {
    const { field_value } = this.state
    const { field, handleValueChanged } = this.props
    const { name } = field

    handleValueChanged(name, field_value)
  }

  render() {
    const { initialized, field_value } = this.state
    const { field, specialization, environment, readonly } = this.props
    const { name, richTextMode, defaultMode, rows } = field

    const useHexEncoding = field.useHexEncoding || false

    const { translations } = specialization
    const { texts } = translations
    const { locale } = environment

    const keys = process.env.languages.split(',')

    return (
      <>
        {!initialized && <JujoLoading />}
        {initialized && (
          <div
            onBlur={() => {
              this.processOnBlur()
            }}
          >
            {keys.map(key => {
              const lang_value = field_value[key] || ''
              const parsed_initial_value = useHexEncoding
                ? jujoHexDecode(lang_value)
                : lang_value

              return (
                <div key={key} className={classNames('flex-fill px-1 py-3')}>
                  <div className={classNames('ffamily-secondary')}>
                    {texts[locale][key] || key}
                  </div>
                  {richTextMode && (
                    <JoditEditor
                      key={`richtext_${name}_${key}`}
                      value={lang_value}
                      config={{
                        enter: 'div',
                        useSplitMode: true,
                        defaultMode: defaultMode || 1, //  MODE_WYSIWYG = 1 MODE_SOURCE = 2 MODE_SPLIT = 3,
                        readonly, // all options from https://xdsoft.net/jodit/doc/
                      }}
                      tabIndex={0}
                      onChange={content => {
                        this.processOnChange(key, content)
                      }}
                    />
                  )}
                  {!richTextMode && (
                    <textarea
                      key={`textarea_${name}_${key}`}
                      className={classNames('border rounded')}
                      name={`textarea_${name}_${key}`}
                      rows={rows || 3}
                      defaultValue={parsed_initial_value}
                      onBlur={e => {
                        const { value } = e.target
                        const parsed_value = useHexEncoding
                          ? jujoHexEncode(value)
                          : value

                        this.processOnChange(key, parsed_value)
                      }}
                    />
                  )}
                </div>
              )
            })}
          </div>
        )}
      </>
    )
  }
}

export default connect(
  mapStateToProps,
  actionCreators
)(ECMultilingualFieldComponent)
