import React, { useState } from 'react'
import { cloneObj } from '../../../utils'
import ParserContext from './parser_context'

function ParserProvider({ children }) {
  const [definition, setDefinition] = useState({})
  const [isSaving, setIsSaving] = useState(false)

  const onSvgFilesChanged = value => {
    const updated_def = cloneObj(definition)
    updated_def.front = value.front
    updated_def.back = value.back
    setDefinition(updated_def)
  }

  const onFontFamiliesMappingChanged = value => {
    const updated_def = cloneObj(definition)
    updated_def.families_mapping = value
    setDefinition(updated_def)
  }

  const onTemplateNameChanged = value => {
    const updated_def = cloneObj(definition)
    updated_def.name = value
    setDefinition(updated_def)
  }

  const onDefaultFontChanged = value => {
    const updated_def = cloneObj(definition)
    updated_def.default_font = value
    setDefinition(updated_def)
  }

  return (
    <ParserContext.Provider
      value={{
        definition,
        setDefinition,
        isSaving,
        setIsSaving,
        onSvgFilesChanged,
        onFontFamiliesMappingChanged,
        onDefaultFontChanged,
        onTemplateNameChanged,
      }}
    >
      {children}
    </ParserContext.Provider>
  )
}

export default ParserProvider
