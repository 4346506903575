import React, { useState, useEffect } from 'react'
import classNames from 'classnames'
import loadable from '@loadable/component'
import { translate } from '../../../../../utils'
import { updatedRectBorder } from '../../../../../utils/svg/rect_border'

const JujoLoading = loadable(() => import('../../../../loading'))

function JujoSVGImageSelector({ element_key }) {
  const [image_element, setImageElement] = useState(null)

  const initData = async () => {
    const g_element = document.getElementById(element_key)
    const i_element = g_element.querySelector('image')
    setImageElement(i_element)
  }

  useEffect(() => {
    const execInitData = async () => {
      await initData()
    }

    execInitData()
  }, [element_key])

  const handleChangeImage = () => {
    const inputFile = document.getElementById(`${element_key}_image_picker`)
    inputFile.click()
  }

  const handleImageChanged = e => {
    const { files } = e.target
    const file = files[0]

    /* Maximum allowed size in bytes - 3MB */
    const maxAllowedSize = 3 * 1024 * 1024
    if (file.size > maxAllowedSize) {
      e.target.value = ''
      console.log('file too big')
      return
    }

    const reader = new FileReader()
    reader.readAsDataURL(file)
    reader.onload = () => {
      const img = new Image()
      img.src = window.URL.createObjectURL(file)
      img.onload = () => {
        let { width, height } = img

        const svg_obj = image_element.closest('svg')
        const svg_bbox = svg_obj.getBBox()

        const { width: svg_w, height: svg_h } = svg_bbox
        if (width > svg_w) {
          const new_width = svg_w
          const new_height = parseFloat((height / width) * new_width).toFixed(2)

          width = new_width
          height = new_height
        }

        if (height > svg_h) {
          const new_height = svg_h
          const new_width = parseFloat((width / height) * new_height).toFixed(2)

          width = new_width
          height = new_height
        }

        const base64 = reader.result
        image_element.setAttribute('href', base64)
        image_element.setAttribute('width', width)
        image_element.setAttribute('height', height)

        document
          .getElementById(`${element_key}_width`)
          .querySelector('input[type=number]').value = width

        document
          .getElementById(`${element_key}_height`)
          .querySelector('input[type=number]').value = height

        updatedRectBorder(image_element)
      }
    }
    reader.onerror = error => console.log(error)
  }

  return (
    <>
      {image_element === null && <JujoLoading />}
      {image_element !== null && (
        <div className={classNames('')}>
          <div
            className={classNames('fw-bold ffamily-secondary fst-uppercase')}
          >
            <div>{translate('choose_image')}</div>
          </div>
          <div>
            <input
              id={`${element_key}_image_picker`}
              className={classNames('d-none')}
              type="file"
              accept="image/*"
              onChange={e => handleImageChanged(e)}
            />
            <div
              className={classNames('background-image upload-image-icon')}
              style={{ width: 25, height: 25 }}
              role="button"
              tabIndex={0}
              label="change-image"
              onClick={() => {
                handleChangeImage()
              }}
              onKeyPress={() => {
                handleChangeImage()
              }}
            />
          </div>
        </div>
      )}
    </>
  )
}

export default JujoSVGImageSelector
