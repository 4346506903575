// eslint-disable-next-line import/no-cycle
import { getGridDefinition } from './gridFunctions'

export function getStoredGridProps(props) {
  const { environment, entity } = props
  const gridDefinition = getGridDefinition(props)
  const { defaults } = gridDefinition

  const { grids } = environment
  const storedGridData = grids[entity] || {}

  const keys = defaults ? Object.keys(defaults) : []
  const gridProps = {}
  for (let i = 0; i !== keys.length; i += 1) {
    const key = keys[i]
    gridProps[key] =
      storedGridData[key] === undefined ? defaults[key] : storedGridData[key]
  }

  gridProps.rows =
    Object.keys(storedGridData).length > 0 ? storedGridData.rows : {}

  return gridProps
}
