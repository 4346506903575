import React from 'react'
import loadable from '@loadable/component'

import { connect } from 'react-redux'

import * as actionCreators from '../../../../../src/store/actions'
import { BlankPageLayout } from '../../../../../src/components/page_layout/layouts/blank_page'
import './fonts.scss'
import './custom.scss'
import { mapStateToProps } from '../../../../../src/utils'

const classNames = require('classnames')

const PageComponent = loadable(() =>
  import('../../../../../src/components/page_layout/layouts/_parts/page')
)

export class FrontPageLayout extends BlankPageLayout {
  componentWillUnmount() {
    // Fix Warning: Can't perform a React state update on an unmounted component
    this.setState = () => {}
  }

  renderBody = () => {
    const html = []
    html.push(
      <div key="vitae_ayout_front" className={classNames('d-flex p-0 m-0')}>
        <PageComponent />
      </div>
    )
    return html
  }
}

export default connect(mapStateToProps, actionCreators)(FrontPageLayout)
