/* eslint-disable no-eval */
/* eslint-disable jsx-a11y/iframe-has-title */
import React from 'react'
import { connect } from 'react-redux'
import * as actionCreators from '../../store/actions'
import { mapStateToProps, processLogout } from '../../utils'
import ChangePasswordComponent from './_parts/change_password'

const classNames = require('classnames')

export class UserPanelComponent extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      showChangePassword: false,
    }
  }

  handleLogoutClicked = () => {
    processLogout(this)
  }

  handleShowChangePassword = () => {
    const { showChangePassword } = this.state
    this.setState({ showChangePassword: !showChangePassword })
  }

  processFunction = act => {
    eval(`this.${act}()`)
  }

  render() {
    const { showChangePassword } = this.state
    const { authentication, specialization, environment } = this.props
    const { user } = authentication
    const { name } = user

    const { translations } = specialization
    const { texts } = translations
    const { locale } = environment

    const actionBtns = [
      {
        icon: 'key-icon',
        label: texts[locale].change_password,
        action: 'handleShowChangePassword',
      },
      {
        icon: 'logout-icon',
        label: texts[locale].logout,
        action: 'handleLogoutClicked',
      },
    ]

    return (
      <div
        className={classNames(
          'p-2 overflow-hidden border-color-1 border-bottom'
        )}
      >
        <div
          className={classNames(
            'd-flex flex-column flex-md-row justify-content-between align-items-center'
          )}
        >
          <div className={classNames('fw-bold fs-5')}>
            {`${texts[locale].hello} ${name}!`}
          </div>
          <div
            className={classNames(
              'justify-content-end d-flex flex-column flex-md-row align-items-center'
            )}
            style={{
              width: 'min(400px, 100%)',
            }}
          >
            {actionBtns.map(b => {
              const { icon, label, action } = b
              return (
                <div
                  key={icon}
                  className={classNames(
                    'container-fluid d-flex align-items-center justify-content-center shadow-sm p-1 ms-2 me-2 me-md-0 my-1 ffamily-secondary fs-6 bg-1 fc-white rounded'
                  )}
                  role="button"
                  tabIndex={0}
                  onClick={() => {
                    this.processFunction(action)
                  }}
                  onKeyPress={() => {
                    this.processFunction(action)
                  }}
                >
                  <div
                    className={classNames(icon, ' white-svg me-1')}
                    style={{
                      height: '20px',
                      width: '20px',
                      backgroundSize: 'contain',
                      backgroundPosition: 'center',
                      backgroundRepeat: 'no-repeat',
                    }}
                  />
                  <div>{label}</div>
                </div>
              )
            })}
          </div>
        </div>
        {showChangePassword && <ChangePasswordComponent />}
      </div>
    )
  }
}

export default connect(mapStateToProps, actionCreators)(UserPanelComponent)
