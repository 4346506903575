import React from 'react'
import { connect } from 'react-redux'
import { saveSessionToServer } from '../../../../../../src/services/sessionHelper'
import * as actionCreators from '../../../../../../src/store/actions'
import { cloneObj, mapStateToProps } from '../../../../../../src/utils'
import MulticheckButtons from '../multicheck_buttons'

const classNames = require('classnames')

export class OssigenoMonthPicker extends React.Component {
  constructor(props) {
    super(props)
    this.state = {}
  }

  componentWillUnmount() {
    // fix Warning: Can't perform a React state update on an unmounted component
    this.setState = () => {}
  }

  componentDidMount = async () => {}

  handleClick = async item => {
    const { session, updateSession } = this.props
    const session_copy = cloneObj(session)
    const { session_id, session_data } = session_copy
    const { months } = session_data
    const { id } = item

    for (let i = 0; i < months.length; i += 1) {
      const client = months[i]
      client.selected = false
      if (client.id === id) {
        const { selected } = client
        const selectedUpdated = selected ? !selected : true
        client.selected = selectedUpdated
      }
    }
    const returned_data = await saveSessionToServer(session_id, session_data)
    session_copy.session_data = returned_data
    await updateSession(session_copy)
  }

  render() {
    const { session } = this.props
    const { session_data } = session

    const { months } = session_data

    return (
      <div className={classNames('')}>
        <MulticheckButtons
          label="Mese"
          data={months}
          onClick={this.handleClick}
        />
      </div>
    )
  }
}

export default connect(mapStateToProps, actionCreators)(OssigenoMonthPicker)
