import React from 'react'
import { connect } from 'react-redux'

import * as actionCreators from '../../../../../store/actions'
import { mapStateToProps, processLogout } from '../../../../../utils'
import MobileSlideableComponent from '../mobile_slideable'
import {
  goToPrivateSection,
  gotoSignUpPage,
  isUserLoggedIn,
  showLoginForm,
} from './helper'

const classNames = require('classnames')

export class AccessRegisterComponent extends React.Component {
  componentWillUnmount() {
    // Fix Warning: Can't perform a React state update on an unmounted component
    this.setState = () => {}
  }

  render() {
    const { environment, specialization, authentication } = this.props

    const { locale } = environment
    const { translations } = specialization
    const { texts } = translations
    return (
      <>
        <div
          className={classNames(
            'd-flex d-md-none fc-white fs-8 justify-content-center align-items-center'
          )}
        >
          <MobileSlideableComponent
            mobileSlideableProps={{
              static_name: 'mobile_account_register',
              visible_icon: 'user-filled-icon white-svg',
              hidden_icon: 'user-icon white-svg',
              bg_color: 'bg-2',
              text_color: 'fc-white',
              body_component:
                'page_layout/layouts/_parts/access_register/mobile_view',
            }}
          />
        </div>
        <div
          className={classNames(
            'd-none d-md-flex justify-content-center justify-content-start text-center text-md-start fw-600 fc-white fs-8 align-items-center fst-uppercase'
          )}
        >
          {isUserLoggedIn(this) === true && (
            <div
              className={classNames(
                'd-flex justify-content-end align-items-center'
              )}
            >
              <div>{`${texts[locale].hello} ${
                authentication.user.name || authentication.user.first_name
              }`}</div>
              <div
                className={classNames('user-full-icon white-svg ms-1')}
                style={{
                  height: '25px',
                  width: '25px',
                  backgroundSize: 'contain',
                  backgroundRepeat: 'no-repeat',
                  backgroundPosition: 'center',
                }}
                role="button"
                tabIndex={0}
                label="logout"
                onClick={async () => {
                  await goToPrivateSection(this)
                }}
                onKeyPress={async () => {
                  await goToPrivateSection(this)
                }}
              />
              <div
                className={classNames('logout-icon white-svg ms-1')}
                style={{
                  height: '25px',
                  width: '25px',
                  backgroundSize: 'contain',
                  backgroundRepeat: 'no-repeat',
                  backgroundPosition: 'center',
                }}
                role="button"
                tabIndex={0}
                label="logout"
                onClick={async () => {
                  await processLogout(this)
                }}
                onKeyPress={async () => {
                  await processLogout(this)
                }}
              />
            </div>
          )}
          {isUserLoggedIn(this) === false && (
            <>
              <div
                className={classNames('me-1')}
                role="button"
                tabIndex={0}
                onClick={() => {
                  showLoginForm(this)
                }}
                onKeyPress={() => {
                  showLoginForm(this)
                }}
              >
                {texts[locale].login}
              </div>
              <div
                className={classNames(
                  'ms-1 border border-color-white rounded px-2'
                )}
                role="button"
                tabIndex={0}
                onClick={() => {
                  gotoSignUpPage()
                }}
                onKeyPress={() => {
                  gotoSignUpPage()
                }}
              >
                {texts[locale].sign_up}
              </div>
            </>
          )}
        </div>
      </>
    )
  }
}

export default connect(mapStateToProps, actionCreators)(AccessRegisterComponent)
