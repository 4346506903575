import React from 'react'
import { connect } from 'react-redux'

import loadable from '@loadable/component'
import classNames from 'classnames'

import * as actionCreators from '../../../../store/actions'
import { mapStateToProps, translate } from '../../../../utils'

const JujoLoading = loadable(() => import('../../../loading'))

export class JujoEditorBlockWidgetComponent extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      loading: true,
      collapsed: false,
    }
  }

  componentWillUnmount() {
    // Fix Warning: Can't perform a React state update on an unmounted component
    this.setState = () => {}
  }

  componentDidMount = async () => {
    const { config } = this.props

    const { collapsed } = config

    this.setState({ collapsed: collapsed || false, loading: false })
  }

  handleExpandCollapseClicked = () => {
    const { collapsed } = this.state
    this.setState({ collapsed: !collapsed })
  }

  drawWidget = () => {
    const { collapsed } = this.state
    const { config } = this.props
    const { title, specialized, path, data } = config

    let DynamicComponent = null
    if (specialized) {
      DynamicComponent =
        require(`../../../../../jujo_specializations/src/${process.env.client}/${path}`).default
    } else {
      DynamicComponent = require(`../../../../${path}`).default
    }

    const html = []

    html.push(
      <div key="widget_component">
        <div className={classNames('bg-white shadow-sm rounded-4 p-4')}>
          <div
            className={classNames(
              'd-flex justify-content-between mb-2 bg-gray-100 p-1 align-items-center rounded-2'
            )}
          >
            <div className={classNames('fw-bold ffamily-secondary')}>
              {translate(title)}
            </div>
            <div
              className={classNames(
                'background-image',
                collapsed ? 'expand-icon-2' : 'minus-icon'
              )}
              style={{ width: 20, height: 20 }}
              role="button"
              tabIndex={0}
              label="expand-collapese-btn"
              onClick={() => this.handleExpandCollapseClicked()}
              onKeyPress={() => this.handleExpandCollapseClicked()}
            />
          </div>
          {!collapsed && <DynamicComponent data={data} />}
        </div>
      </div>
    )
    return html
  }

  render() {
    const { loading } = this.state
    return (
      <>
        {loading === true && <JujoLoading />}
        {loading === false && (
          <div className={classNames('')}>{this.drawWidget()}</div>
        )}
      </>
    )
  }
}

export default connect(
  mapStateToProps,
  actionCreators
)(JujoEditorBlockWidgetComponent)
