import React from 'react'
import { connect } from 'react-redux'

import * as actionCreators from '../../../store/actions'
import TabsComponent from '../../tabs'
import dataRenderComponentTypesMap from '../../../enums/dataRenderComponentTypesMap'

import { getGridDefinition } from '../helper/gridFunctions'

import BoxList from '../../boxList'
// eslint-disable-next-line import/no-cycle
import JujoGridComponent from '..'
import JujoEditableComponent from '../../editable_component'

import { mapStateToProps } from '../../../utils'

const classNames = require('classnames')

export class ExpandableRow extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      initialized: false,
      tabList: [],
      entityDefinition: {},
    }
  }

  componentDidMount = async () => {
    const gridDefinition = getGridDefinition(this.props)

    const { expandableRowContent } = gridDefinition
    const { sections } = expandableRowContent
    const tabs = []
    for (let i = 0; i !== sections.length; i += 1) {
      const section = sections[i]
      const { label, entity } = section
      tabs.push({
        tabLabel: label,
        tabEntity: entity,
      })
    }
    this.setState({
      entityDefinition: gridDefinition,
      tabList: tabs,
      initialized: true,
    })
  }

  componentWillUnmount() {
    this.setState = () => {}
  }

  getEntityDefinition = async () => {
    const { entity, specialization } = this.props
    let definition = {}
    if (entity) {
      const { entities } = specialization.config
      const config = entities[entity]

      definition = config.grid
    }
    return definition
  }

  renderSection = section => {
    const { data, entity, parentIdentifierValue } = this.props
    const html = []
    const { type, custom_component_props, editable_component_props } = section
    const { specialized, relPath } = custom_component_props || {}

    if (type === dataRenderComponentTypesMap.box_list) {
      html.push(
        <BoxList
          key={`${parentIdentifierValue}_${type}`}
          section={section}
          entity={section.entity}
          parentData={{ entity, data }}
        />
      )
    } else if (type === dataRenderComponentTypesMap.jujo_grid) {
      html.push(
        <JujoGridComponent
          key={`${parentIdentifierValue}_${type}`}
          section={section}
          entity={section.entity}
          parentData={{ entity, data }}
        />
      )
    } else if (type === dataRenderComponentTypesMap.editable_component) {
      html.push(
        <JujoEditableComponent
          key={`${parentIdentifierValue}_${type}`}
          c_def={editable_component_props}
          section={section}
          parentData={{ entity, data }}
        />
      )
    } else if (type === dataRenderComponentTypesMap.custom_component) {
      let DynamicComponent = null
      if (specialized) {
        DynamicComponent =
          require(`../../../../jujo_specializations/src/${process.env.client}/components/${relPath}`).default
      } else {
        DynamicComponent = require(`../../${relPath}`).default
      }
      html.push(
        <DynamicComponent
          key={`${parentIdentifierValue}_${type}`}
          section={section}
          entity={section.entity}
          parentData={{ entity, data }}
        />
      )
    }
    return html
  }

  renderSections = () => {
    const { environment, parentIdentifierValue } = this.props
    const { tabs } = environment

    const { entityDefinition } = this.state
    const { expandableRowContent } = entityDefinition
    const { sections, tabLabel } = expandableRowContent
    const dynamicTabLabel = tabLabel ? this.generateDynamicLabel(tabLabel) : ''
    const sectionToRender = sections.length > 1 ? tabs[dynamicTabLabel] || 0 : 0
    const section = sections[sectionToRender]
    const { entity } = section

    const html = []
    html.push(
      <div
        key={`${parentIdentifierValue}_sections`}
        className={classNames(
          { expandable_row_section: true },
          `expandable_row_${entity}`
        )}
      >
        {this.renderSection(section)}
      </div>
    )
    return html
  }

  generateDynamicLabel = staticLabel => {
    const { data } = this.props
    const bracesPattern = /[^{]+(?=\})/g
    const placeholderList = staticLabel.match(bracesPattern)

    let dynamicLabel = staticLabel
    for (let i = 0; i !== placeholderList.length; i += 1) {
      const placeholder = placeholderList[i]
      const value = data[placeholder]

      dynamicLabel = dynamicLabel.replace(`{${placeholder}}`, value)
    }

    return dynamicLabel
  }

  render() {
    // const { gridRef } = this.props
    const { initialized, entityDefinition, tabList } = this.state

    const { expandableRowContent } = entityDefinition
    return (
      <>
        {initialized === true && expandableRowContent && (
          <>
            {/*
          <div
            className={classNames('px-0 px-md-4 py-4 w-100')}
            style={
              {
                // width: gridRef.current.offsetWidth,
                // marginLeft: '-1rem',
              }
            }
          >
          */}
            <div
              className={classNames('px-0 px-md-4 py-4')}
              style={{
                maxWidth: 'min(100%, calc(100vw - 294px))',
                marginLeft: '-18px',
              }}
            >
              {expandableRowContent.sections.length > 1 && (
                <TabsComponent
                  label={this.generateDynamicLabel(
                    expandableRowContent.tabLabel
                  )}
                  tabList={tabList}
                />
              )}
              {this.renderSections()}
            </div>
          </>
        )}
      </>
    )
  }
}

export default connect(mapStateToProps, actionCreators)(ExpandableRow)
