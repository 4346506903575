/* eslint-disable global-require */
/* eslint-disable import/no-dynamic-require */
import React from 'react'
import { connect } from 'react-redux'

import * as actionCreators from '../../../store/actions'
import { mapStateToProps } from '../../../utils'

const classNames = require('classnames')

export class AdvancedSearchPanelComponent extends React.Component {
  componentWillUnmount() {
    // fix Warning: Can't perform a React state update on an unmounted component
    this.setState = () => {}
  }

  componentDidMount = async () => {}

  componentDidUpdate = async () => {}

  render() {
    const {
      advancedSearchDefinition,
      loadGrid,
      specialization,
      entity,
      environment,
      handleCloseAdvancedSearch,
    } = this.props
    const { translations } = specialization
    const { texts } = translations
    const { locale } = environment

    return (
      <>
        <div
          className={classNames(
            'bg-white d-flex justify-content-center justify-content-md-start align-items-center border-bottom p-3'
          )}
          style={{ width: 'inherit' }}
        >
          <div>{texts[locale].advanced_search || 'advanced_search'}</div>
          <div
            className={classNames(
              'btn position-absolute end-0 ffamily-secondary ms-1 border rounded bg-1 fc-white px-2 py-1 me-2 fs-7 fst-uppercase'
            )}
            role="button"
            tabIndex={0}
            onClick={() => {
              handleCloseAdvancedSearch()
            }}
            onKeyPress={() => {
              handleCloseAdvancedSearch()
            }}
          >
            {texts[locale].done || 'done'}
          </div>
        </div>
        <div
          className={classNames('p-3')}
          style={{
            overflowY: 'scroll',
            height: 'calc(100% - 57px)',
          }}
        >
          {Object.keys(advancedSearchDefinition).map(key => {
            const filter = advancedSearchDefinition[key]
            let DynamicFilter = null
            try {
              DynamicFilter = require(`./_parts/${filter.type}Filter`).default
              // eslint-disable-next-line no-empty
            } catch (e) {}
            return (
              <div key={key} className={classNames('container')}>
                {DynamicFilter !== null && (
                  <DynamicFilter
                    filter={filter}
                    entity={entity}
                    loadGrid={loadGrid}
                  />
                )}
              </div>
            )
          })}
        </div>
      </>
    )
  }
}

export default connect(
  mapStateToProps,
  actionCreators
)(AdvancedSearchPanelComponent)
