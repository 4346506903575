import React from 'react'
import { connect } from 'react-redux'
import jujoEmbedTypesMap from '../../enums/jujoEmbedTypesMap'

import * as actionCreators from '../../store/actions'
import { mapStateToProps, embedJSCode, embedJSFile } from '../../utils'

export class JujoCodeEmbedComponent extends React.Component {
  componentWillUnmount() {
    // Fix Warning: Can't perform a React state update on an unmounted component
    this.setState = () => {}
  }

  componentDidMount = async () => {
    const { data, content } = this.props
    const { embed_type, js_url, target } = data
    if (typeof document !== 'undefined') {
      if (embed_type === jujoEmbedTypesMap.js_code) {
        const embed_code = content[0].data
        embedJSCode(document, embed_code, target)
      } else if (embed_type === jujoEmbedTypesMap.js_url) {
        embedJSFile(document, js_url, target)
      }
    }
  }

  render() {
    return <></>
  }
}

export default connect(mapStateToProps, actionCreators)(JujoCodeEmbedComponent)
