export function rgbToHex(color) {
  function componentToHex(c) {
    const hex = c.toString(16)
    return hex.length === 1 ? `0${hex}` : hex
  }

  function parseRgb(rgb) {
    const r = rgb[0]
    const g = rgb[1]
    const b = rgb[2]
    return `#${componentToHex(r)}${componentToHex(g)}${componentToHex(b)}`
  }

  if (color === '') return color

  const rgb = color.match(/\d+/g).map(item => parseInt(item, 10))
  const hex = parseRgb(rgb)

  return hex
}
