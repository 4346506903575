export async function overridedFunction(override, funcName) {
  if (override && override[funcName]) {
    const { specialized, action, actionPath, actionName } = override[funcName]
    let injected_actions = null
    let overrided_action = null

    if (action) {
      overrided_action = action
    } else if (specialized) {
      injected_actions =
        await require(`../../../jujo_specializations/src/${process.env.client}/${actionPath}`)
    } else {
      injected_actions = await require(`../../${actionPath}`)
    }
    if (injected_actions || overrided_action) {
      return {
        injected_actions,
        actionName,
        overrided_action,
      }
    }
  }
  return false
}
