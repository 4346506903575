import lsEntryMap from '../../../enums/lsEntryMap'
import { lsSet } from '../../../services/localStorage'
import initialState from '../../reducers/session/initialState'

export function createNewSession() {
  return async dispatch => {
    const newSession = initialState
    lsSet(lsEntryMap.session, initialState)

    dispatch({
      type: 'UPDATE_SESSION',
      session: newSession,
    })
  }
}

export function updateSession(session) {
  return async dispatch => {
    const newSession = session || initialState
    lsSet(lsEntryMap.session, newSession)

    dispatch({
      type: 'UPDATE_SESSION',
      session: newSession,
    })
  }
}
