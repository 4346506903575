import React from 'react'
import { connect } from 'react-redux'

import loadable from '@loadable/component'
import classNames from 'classnames'

import * as actionCreators from '../../../../../store/actions'
import {
  deslugifyString,
  mapStateToProps,
  randomString,
  translate,
} from '../../../../../utils'
import { getSelectedSvgNodes } from '../../../../../utils/svg/interactive_text'

const JujoLoading = loadable(() => import('../../../../loading'))
const ECDataListFieldComponent = loadable(() =>
  import('../../../../common/fields/dataListField')
)

const JujoFontFamilyVariantSelectorComponent = loadable(() =>
  import('./ffamily_variant_selector')
)

export class JujoSVGFontFamilyManagerComponent extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      loading: true,
      dom_obj: null,
      svg_obj: null,
      family: null,
    }
  }

  componentWillUnmount() {
    // Fix Warning: Can't perform a React state update on an unmounted component
    this.setState = () => {}
  }

  componentDidMount = async () => {
    const { dom_obj, svg_obj } = await this.retrievesDomElements()
    await this.setState({ dom_obj, svg_obj })

    const family = this.getSelectedFontFamily()
    this.setState({ family, loading: false })
  }

  retrievesDomElements = async () => {
    const { element_key } = this.props
    let dom_obj = null
    let svg_obj = null

    if (typeof window !== 'undefined') {
      // eslint-disable-next-line prefer-destructuring
      dom_obj = document.getElementById(element_key).querySelector('text')
      svg_obj = dom_obj.closest('svg')
    }

    return {
      svg_obj,
      dom_obj,
    }
  }

  createFontFace = async (ffamily, variant) => {
    const { svg_obj } = this.state
    const style_obj = document.createElement('style')
    const { src } = variant

    const fface_code = `
      @font-face {
        font-family:'${ffamily}';
        src: url('${src}');
      }`

    const styleBody = document.createTextNode(fface_code)
    style_obj.appendChild(styleBody)

    svg_obj.appendChild(style_obj)
  }

  handleFontChanged = async (_fieldName, value) => {
    const { session } = this.props

    const { session_data } = session
    const { jujo_fonts } = session_data

    const selected_family = jujo_fonts.find(x => x.id === value)
    await this.setState({ family: selected_family })

    if (selected_family) {
      const { data: variant_list } = selected_family
      const default_variant = variant_list[0]

      this.setFontVariant(default_variant)
    } else {
      this.removeFontVariant()
    }
  }

  setFontVariant = variant => {
    const { element_key } = this.props

    const selected_nodes = getSelectedSvgNodes(element_key)

    const random_ffamily_name = randomString(16)
    this.createFontFace(random_ffamily_name, variant)

    for (let i = 0; i !== selected_nodes.length; i += 1) {
      const node = selected_nodes[i]
      node.style['font-family'] = random_ffamily_name
    }
  }

  removeFontVariant = () => {
    const { element_key } = this.props

    const selected_nodes = getSelectedSvgNodes(element_key)
    for (let i = 0; i !== selected_nodes.length; i += 1) {
      const node = selected_nodes[i]
      node.style['font-family'] = ''
    }
  }

  getSelectedFontFamily = () => {
    const { dom_obj } = this.state
    const ffamily_name = dom_obj.style['font-family']
    if (ffamily_name === null) return null

    const { session, element_key } = this.props
    const { session_data } = session
    const { jujo_fonts } = session_data

    const parsed_ffamily = ffamily_name.replace(`${element_key}_`, '')
    const deslugged_ffamily = deslugifyString(parsed_ffamily)

    let selected_ffamily = null
    for (let i = 0; i !== jujo_fonts.length; i += 1) {
      const ffamily = jujo_fonts[i]
      const { name } = ffamily
      if (name.toLowerCase() === deslugged_ffamily) {
        selected_ffamily = ffamily
        break
      }
    }

    return selected_ffamily
  }

  drawElement = () => {
    const { family } = this.state
    const { element_key, session } = this.props
    const { session_data } = session
    const { jujo_fonts } = session_data

    const ffamilyListField = {
      name: `${element_key}_font_family_list`,
      renderField: 'name',
      valueField: 'id',
      options: jujo_fonts,
    }

    const selectedFontFamilyId = family ? family.id : 0

    const html = []

    html.push(
      <div key={`${element_key}_font_family_manger`}>
        <div>
          <div
            className={classNames('fw-bold ffamily-secondary fst-uppercase')}
          >
            {translate('choose_the_font')}
          </div>
          <div className={classNames('bg-white px-2 py-1 mb-2')}>
            <ECDataListFieldComponent
              field={ffamilyListField}
              handleValueChanged={this.handleFontChanged}
              initialValue={selectedFontFamilyId}
            />
          </div>
        </div>
        {family && family.data.length > 1 && (
          <JujoFontFamilyVariantSelectorComponent
            data={{ element_key, family }}
            setFontVariant={this.setFontVariant}
          />
        )}
      </div>
    )

    return html
  }

  render() {
    const { loading } = this.state
    return (
      <>
        {loading === true && <JujoLoading />}
        {loading === false && (
          <div className={classNames('')}>{this.drawElement()}</div>
        )}
      </>
    )
  }
}

export default connect(
  mapStateToProps,
  actionCreators
)(JujoSVGFontFamilyManagerComponent)
