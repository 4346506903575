import React, { useState } from 'react'
import classNames from 'classnames'
import { translate } from '../../../../../utils'

function JujoSvgMobileNotification() {
  const [show_notification, setShowNotification] = useState(true)

  return (
    <>
      {show_notification === true && (
        <div className={classNames('')}>
          <div
            className={classNames(
              'position-absolute top-50 start-50 translate-middle bg-1 rounded-2 fc-white p-2 mt-3 shadow-lg fs-7'
            )}
          >
            <div className={classNames('text-center')}>
              {translate('mobile_editor_instructions')}
            </div>
            <div
              className={classNames(
                'fc-1 fst-uppercase bg-white mt-2 rounded-3 p-2 text-center fw-bold'
              )}
              role="button"
              tabIndex={0}
              onClick={() => {
                setShowNotification(false)
              }}
              onKeyPress={() => {
                setShowNotification(false)
              }}
            >
              {translate('i_understand')}
            </div>
          </div>
        </div>
      )}
    </>
  )
}

export default JujoSvgMobileNotification
