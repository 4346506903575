import React from 'react'
import { connect } from 'react-redux'

import * as actionCreators from '../../store/actions'
import { mapStateToProps } from '../../utils'

const classNames = require('classnames')

export class JujoGalleryComponent extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      zoomed_index: -1,
    }
  }

  componentWillUnmount() {
    // Fix Warning: Can't perform a React state update on an unmounted component
    this.setState = () => {}
  }

  handleItemClicked = index => {
    this.setState({ zoomed_index: index })
  }

  handleCloseZoom = () => {
    this.setState({ zoomed_index: -1 })
  }

  handleNextClicked = () => {
    const { zoomed_index } = this.state
    this.setState({ zoomed_index: zoomed_index + 1 })
  }

  handlePrevClicked = () => {
    const { zoomed_index } = this.state
    this.setState({ zoomed_index: zoomed_index - 1 })
  }

  drawGalleryItems = () => {
    const { items } = this.props
    const html = []
    for (let i = 0; i !== items.length; i += 1) {
      const item = items[i]
      const { url } = item
      html.push(
        <div
          key={`gallery_item_${i}`}
          className={classNames(
            'background-image',
            i % 3 === 0 && i !== items.length - 3
              ? 'gre-span-1 gre-md-span-2'
              : ''
          )}
          style={{
            backgroundImage: `url(${url})`,
            backgroundSize: 'cover',
            width: '100%',
            height: '100%',
          }}
          role="button"
          tabIndex={0}
          label="gallery item"
          onClick={() => {
            this.handleItemClicked(i)
          }}
          onKeyPress={() => {
            this.handleItemClicked(i)
          }}
        />
      )
    }
    return html
  }

  renderZoomedItem = () => {
    const { zoomed_index } = this.state
    const { items } = this.props

    const html = []

    if (zoomed_index >= 0) {
      const item = items[zoomed_index]
      const { url } = item
      html.push(
        <div
          key="zoomed_item"
          className={classNames(
            'fixed-top zindex-1 h-100vh bg-black-opacity-5'
          )}
        >
          <div
            className={classNames('d-flex background-image w-100 h-100')}
            style={{ backgroundImage: `url(${url})` }}
          />

          <div
            className={classNames(
              'position-absolute top-20px end-20px bg-white shadow p-2'
            )}
          >
            <div
              className={classNames('background-image theme-svg close-icon')}
              style={{ width: 20, height: 20 }}
              role="button"
              tabIndex={0}
              label="close zoom"
              onClick={() => {
                this.handleCloseZoom()
              }}
              onKeyPress={() => {
                this.handleCloseZoom()
              }}
            />
          </div>
          <div
            className={classNames(
              'position-absolute bottom-20px end-20px bg-white shadow p-2 d-flex justify-content-between w-80px'
            )}
          >
            {zoomed_index > 0 && (
              <div
                className={classNames(
                  'background-image theme-svg arrow-back-icon me-auto'
                )}
                style={{ width: 20, height: 20 }}
                role="button"
                tabIndex={0}
                label="prev item"
                onClick={() => {
                  this.handlePrevClicked()
                }}
                onKeyPress={() => {
                  this.handlePrevClicked()
                }}
              />
            )}

            {zoomed_index < items.length - 1 && (
              <div
                className={classNames(
                  'background-image theme-svg arrow-next-icon ms-auto'
                )}
                style={{ width: 20, height: 20 }}
                role="button"
                tabIndex={0}
                label="prev item"
                onClick={() => {
                  this.handleNextClicked()
                }}
                onKeyPress={() => {
                  this.handleNextClicked()
                }}
              />
            )}
          </div>
        </div>
      )
    }

    return html
  }

  drawGallery = () => {
    const { items } = this.props
    if (items.length === 0) return []

    const html = []
    html.push(
      <div key="shop_gallery" className={classNames('my-3')}>
        {this.renderZoomedItem()}
        <div
          className={classNames('d-grid gar-200px ggap-2px mt-3')}
          style={{
            gridTemplateColumns: 'repeat(auto-fit, minmax(220px, 1fr))',
          }}
        >
          {this.drawGalleryItems()}
        </div>
      </div>
    )
    return html
  }

  render() {
    return <>{this.drawGallery()}</>
  }
}

export default connect(mapStateToProps, actionCreators)(JujoGalleryComponent)
