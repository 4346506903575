/* eslint-disable no-param-reassign */
import React from 'react'
import { connect } from 'react-redux'
import apiCallMap from '../../enums/apiCallMap'
import { baseRequestObject, parseEndpoint } from '../../services/servicesHelper'

import * as apiService from '../../services/apiService'
import * as actionCreators from '../../store/actions'
import apiRequestTypesMap from '../../enums/apiRequestTypesMap'
import { mapStateToProps } from '../../utils'

const classNames = require('classnames')

export class ContextVariableListComponent extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      initialized: false,
      varDataSourceList: {},
    }
  }

  componentWillUnmount() {
    // fix Warning: Can't perform a React state update on an unmounted component
    this.setState = () => {}
  }

  componentDidMount = async () => {
    await this.initComponent()
  }

  componentDidUpdate = async prevProps => {
    const { environment } = this.props
    const { path } = environment

    if (prevProps.environment.path !== path) {
      await this.initComponent()
    }
  }

  initComponent = async () => {
    this.setState({ initialized: false })
    const { environment, updateEnvironment } = this.props

    const initializedCntxVars = await this.initializeVariablesData()
    const { variableDataSourceList, updatedCtxVars } = initializedCntxVars

    const updatedEnvironment = { ...environment }
    updatedEnvironment.contextVariables = updatedCtxVars
    await updateEnvironment(updatedEnvironment)

    this.setState({
      varDataSourceList: variableDataSourceList,
      initialized: true,
    })
  }

  initializeVariablesData = async () => {
    const { varList, environment } = this.props
    const clonedList = [...varList].reverse()
    const variableDataSourceList = {}
    await this.recursivelyPopulateVariablesData(
      clonedList,
      variableDataSourceList
    )
    const { contextVariables, path } = environment
    const updatedCtxVars = { ...contextVariables }
    for (let i = 0; i !== varList.length; i += 1) {
      const { name, defValue, valueField } = varList[i]

      if (!updatedCtxVars[path]) {
        updatedCtxVars[path] = {}
      }
      if (!updatedCtxVars[path][name]) {
        // I do this because 0 is verified as FALSE in next check so I have to specify !== undefined
        let defaultVariableValue = defValue !== undefined ? defValue : undefined
        if (defaultVariableValue === undefined) {
          defaultVariableValue =
            variableDataSourceList[name].data.length > 0
              ? variableDataSourceList[name].data[0][valueField]
              : 0
        }
        updatedCtxVars[path][name] = defaultVariableValue
      }
    }

    return { variableDataSourceList, updatedCtxVars }
  }

  recursivelyPopulateVariablesData = async (list, variableDataSourceList) => {
    if (list.length === 0) return

    const varDef = list.pop()
    const { name, apis, staticData } = varDef

    if (apis) {
      const reqObj = this.composeRequest(varDef)
      const { parsedEp } = reqObj

      const result = await apiService.httpGet(
        `${process.env.apiUrl}${parsedEp}`
      )
      if (result) {
        const { status, data } = result
        variableDataSourceList[name] =
          status === 200
            ? { data: data.data, def: varDef }
            : { data: [], def: varDef }
      }
    } else {
      variableDataSourceList[name] = {
        data: staticData,
        def: varDef,
      }
    }

    await this.recursivelyPopulateVariablesData(list, variableDataSourceList)
  }

  composeRequest = varDef => {
    const { authentication, environment } = this.props
    const { apis } = varDef
    const { getData } = apis
    const { apiCall, defaultFilters, placeholderMapping, relatedEntity } =
      getData

    const requestData = baseRequestObject(
      apiCallMap[apiCall],
      relatedEntity,
      apiRequestTypesMap.get,
      environment,
      authentication
    )
    requestData.defaultFilters = defaultFilters || []
    requestData.placeholderMapping = placeholderMapping || []
    const parsedEp = parseEndpoint(requestData)

    return {
      parsedEp,
      data: [],
    }
  }

  handleVariableSelected = async (key, value) => {
    const { updateContextVariablesIntoStorageAndRedux, environment } =
      this.props
    const { path } = environment

    const cVarObj = {
      path,
      key,
      value,
    }

    await updateContextVariablesIntoStorageAndRedux(cVarObj)
  }

  render() {
    const { environment, specialization } = this.props
    const { initialized, varDataSourceList } = this.state

    const { translations } = specialization
    const { texts } = translations
    const { locale, contextVariables, path } = environment

    return (
      <>
        {initialized === false && (
          <div
            className={classNames(
              'd-flex justify-content-center align-items-center p-2 shadow-sm'
            )}
          >
            {texts[locale].loading}
            <div
              className={classNames('loading-icon theme-svg ms-1')}
              style={{
                backgroundPosition: 'center',
                backgroundSize: '30px 30px',
                backgroundRepeat: 'no-repeat',
                height: '30px',
                width: '30px',
              }}
            />
          </div>
        )}
        {initialized === true && (
          <div
            className={classNames(
              'd-flex flex-column flex-md-row justify-content-between py-2 mb-4 ffamily-secondary border-bottom fst-uppercase'
            )}
          >
            {Object.keys(varDataSourceList).map(key => {
              const varObj = varDataSourceList[key]
              const { data, def } = varObj
              return (
                <div
                  key={key}
                  className={classNames(
                    'd-flex justify-content-center justify-content-md-start align-items-center py-1 py-md-0'
                  )}
                >
                  <div className={classNames('me-2 fw-bold')}>
                    {texts[locale][def.alias] || def.alias}
                  </div>
                  <div className={classNames('rounded-4 border')}>
                    <select
                      className={classNames('')}
                      defaultValue={contextVariables[path][key]}
                      onChange={e => {
                        this.handleVariableSelected(key, e.target.value)
                      }}
                    >
                      {data.map(opt => (
                        <option
                          key={opt[def.valueField]}
                          value={opt[def.valueField]}
                        >
                          {texts[locale][opt[def.renderField]] ||
                            opt[def.renderField]}
                        </option>
                      ))}
                    </select>
                  </div>
                </div>
              )
            })}
          </div>
        )}
      </>
    )
  }
}

export default connect(
  mapStateToProps,
  actionCreators
)(ContextVariableListComponent)
