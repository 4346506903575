import React from 'react'
import { connect } from 'react-redux'
import apiCallMap from '../../../enums/apiCallMap'
import * as apiService from '../../../services/apiService'
import {
  baseRequestObject,
  parseEndpoint,
} from '../../../services/servicesHelper'

import * as actionCreators from '../../../store/actions'
import { mapStateToProps } from '../../../utils'
import { setDefaultIfInitialValueIsEmpty } from './helper'

const classNames = require('classnames')

export class ECPickerFieldComponent extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      initialized: false,
      data: [],
    }
  }

  componentWillUnmount() {
    // fix Warning: Can't perform a React state update on an unmounted component
    this.setState = () => {}
  }

  componentDidMount = async () => {
    setDefaultIfInitialValueIsEmpty(this)

    const reqObj = this.composeRequest()
    const { parsedEp } = reqObj

    const result = await apiService.httpGet(`${process.env.apiUrl}${parsedEp}`)
    if (result) {
      const { status, data } = result
      if (status === 200) {
        this.setState({ initialized: true, data: data.data })
      }
    }
  }

  composeRequest = () => {
    const { field, environment, authentication } = this.props
    const { apis } = field

    const { getData } = apis
    const { apiCall, requestType, relatedEntity } = getData

    const requestData = baseRequestObject(
      apiCallMap[apiCall],
      relatedEntity,
      requestType,
      environment,
      authentication
    )

    const parsedEp = parseEndpoint(requestData)

    return {
      parsedEp,
      data: [],
    }
  }

  render() {
    const { initialized, data } = this.state
    const {
      specialization,
      environment,
      field,
      initialValue,
      handleValueChanged,
    } = this.props

    const { name, renderField, valueField } = field

    const { translations } = specialization
    const { texts } = translations
    const { locale } = environment

    return (
      <>
        {!initialized && (
          <div
            className={classNames(
              'd-flex justify-content-center align-items-center'
            )}
            style={{ height: '100%' }}
          >
            {texts[locale].loading}
            <div
              className={classNames('loading-icon theme-svg ms-1')}
              style={{
                backgroundPosition: 'center',
                backgroundSize: '25px 25px',
                backgroundRepeat: 'no-repeat',
                height: '25px',
                width: '25px',
              }}
            />
          </div>
        )}
        {initialized && (
          <select
            className={classNames('ffamily-primary')}
            name={name}
            defaultValue={initialValue}
            onChange={e => {
              const { value } = e.target
              handleValueChanged(name, value)
            }}
          >
            <option key={`${name}_unselected`} value="">
              --
            </option>
            {data.map(opt => (
              <option key={opt[valueField]} value={opt[valueField]}>
                {opt[renderField]}
              </option>
            ))}
          </select>
        )}
      </>
    )
  }
}

export default connect(mapStateToProps, actionCreators)(ECPickerFieldComponent)
