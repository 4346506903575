import * as apiService from '../../../../../src/services/apiService'
import { showMessageBox } from '../../components/common/message_box'

export async function syncYoutubeDataFromV1(sender) {
  const parsedEp = 'action/post/importation/sync-youtube-data-from-v1'
  const dataToSend = {}
  const response = await apiService.httpPost(
    `${process.env.apiUrl}${parsedEp}`,
    dataToSend
  )

  const { status } = response
  if (status === 200 || status === 201) {
    if (typeof window !== 'undefined') {
      showMessageBox(sender, response, 'info')
    }
  } else {
    showMessageBox(sender, response, 'error')
  }
}
