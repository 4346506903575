import React from 'react'
import { connect } from 'react-redux'

import * as actionCreators from '../../../../../../store/actions'
import { mapStateToProps, processLogout } from '../../../../../../utils'
import {
  goToPrivateSection,
  gotoSignUpPage,
  isUserLoggedIn,
  showLoginForm,
} from '../helper'

const classNames = require('classnames')

export class AccessRegisterMobileComponent extends React.Component {
  componentWillUnmount() {
    // Fix Warning: Can't perform a React state update on an unmounted component
    this.setState = () => {}
  }

  render() {
    const { environment, specialization, authentication } = this.props
    const { locale } = environment
    const { translations } = specialization
    const { texts } = translations

    return (
      <div className={classNames('')}>
        {isUserLoggedIn(this) === true && (
          <div
            className={classNames(
              'd-flex flex-column justify-content-center align-items-center'
            )}
          >
            <div
              className={classNames('fw-bold mb-2')}
            >{`${texts[locale].hello} ${authentication.user.name}`}</div>
            <div
              className={classNames('d-flex align-items-center')}
              role="button"
              tabIndex={0}
              onClick={async () => {
                await goToPrivateSection(this)
              }}
              onKeyPress={async () => {
                await goToPrivateSection(this)
              }}
            >
              <div>{texts[locale].my_account}</div>
              <div
                className={classNames('user-full-icon white-svg ms-1')}
                style={{
                  height: '25px',
                  width: '25px',
                  backgroundSize: 'contain',
                  backgroundRepeat: 'no-repeat',
                  backgroundPosition: 'center',
                }}
              />
            </div>

            <div
              className={classNames('d-flex align-items-center')}
              role="button"
              tabIndex={0}
              onClick={async () => {
                await processLogout(this)
              }}
              onKeyPress={async () => {
                await processLogout(this)
              }}
            >
              <div>{texts[locale].logout}</div>
              <div
                className={classNames('logout-icon white-svg ms-1')}
                style={{
                  height: '25px',
                  width: '25px',
                  backgroundSize: 'contain',
                  backgroundRepeat: 'no-repeat',
                  backgroundPosition: 'center',
                }}
              />
            </div>
          </div>
        )}
        {isUserLoggedIn(this) === false && (
          <>
            <div
              role="button"
              tabIndex={0}
              onClick={() => {
                showLoginForm(this)
              }}
              onKeyPress={() => {
                showLoginForm(this)
              }}
            >
              {texts[locale].login}
            </div>
            <div
              role="button"
              tabIndex={0}
              onClick={() => {
                gotoSignUpPage()
              }}
              onKeyPress={() => {
                gotoSignUpPage()
              }}
            >
              {texts[locale].sign_up}
            </div>
          </>
        )}
      </div>
    )
  }
}

export default connect(
  mapStateToProps,
  actionCreators
)(AccessRegisterMobileComponent)
