/* eslint-disable global-require */
/* eslint-disable import/no-dynamic-require */
import React from 'react'
import { connect } from 'react-redux'

import apiCallMap from '../../enums/apiCallMap'
import { baseRequestObject, parseEndpoint } from '../../services/servicesHelper'

import * as actionCreators from '../../store/actions'
import JujoAddComponent from './add'
import JujoEditComponent from './edit'

import { retrieveEntitySource, retrieveEntityValue } from './helper'
import sourcesMap from '../../enums/sourcesMap'
import conditionCheckTypesMap from '../../enums/conditionCheckTypesMap'
import {
  cloneObj,
  getQsValue,
  mapStateToProps,
  removeParametersFromQueryString,
  translate,
} from '../../utils'
import editableCompViewComponentsMap from '../../enums/editableCompViewComponentsMap'
import { getSessionFromServer } from '../../services/sessionHelper'
import { httpGet, httpPost } from '../../services/apiService'
import apiRequestTypesMap from '../../enums/apiRequestTypesMap'

const classNames = require('classnames')

export class JujoEditableComponent extends React.Component {
  constructor(props) {
    super(props)

    this.renderModeMap = {
      view: 'view',
      edit: 'edit',
      add: 'add',
    }

    this.renderPositionsMap = {
      after: 'after',
      before: 'before',
    }

    this.state = {
      initialized: false,
      renderMode: this.renderModeMap.view,
      readonly: false,
      entity: '',
      entityDefinition: {},
      data: [],
      dataSource: {},
      // eslint-disable-next-line react/no-unused-state
      editModeTransitionalDataSource: {},
      customizations: {},
      query_hooks: {},
      searchValue: '',
    }
  }

  componentWillUnmount() {
    // fix Warning: Can't perform a React state update on an unmounted component
    this.setState = () => {}
  }

  verifyCustomizationCondition = conditions => {
    const { environment } = this.props
    let errors = false

    for (let i = 0; i !== conditions.length; i += 1) {
      const cond = conditions[i]
      const { source, relatedField, checkType, check } = cond

      let relFieldValue = null
      if (source === sourcesMap.environment) {
        relFieldValue = environment[relatedField]
      }

      if (checkType === conditionCheckTypesMap.contains) {
        errors = !check.includes(relFieldValue)
      } else if (checkType === conditionCheckTypesMap.equals_to) {
        if (Array.isArray(check)) {
          let meet = false
          for (let x = 0; x !== check.length; x += 1) {
            const currentCheck = check[x]
            if (currentCheck === relFieldValue) {
              meet = true
              break
            }
          }
          errors = !meet
        } else {
          errors = check !== relFieldValue
        }
      } else if (checkType === conditionCheckTypesMap.greater_than) {
        if (relFieldValue === undefined) {
          errors = true
        } else {
          errors = parseInt(relFieldValue, 10) <= parseInt(check, 10)
        }
      } else if (checkType === conditionCheckTypesMap.not_equals_to) {
        const relFieldValueString = relFieldValue
          ? relFieldValue.toString()
          : ''
        errors = relFieldValueString === check.toString()
      }

      if (errors === true) {
        break
      }
    }

    return !errors
  }

  componentDidMount = async () => {
    const { c_def, specialization } = this.props

    const { config } = specialization

    const entity_source = retrieveEntitySource(this.props)
    const entity = retrieveEntityValue(this.props)
    let entityDefinition = {}

    if (entity_source === sourcesMap.api) {
      entityDefinition = await this.getEntityDefinitionFromApi()
    } else {
      const { entities } = config
      const entityObject = entities[entity] || {}

      if (Object.keys(entityObject).length === 0) {
        console.log('entity not found!')
        return
      }
      entityDefinition = entityObject[c_def.comp]
    }

    let { customizations } = entityDefinition || {}
    customizations = customizations === undefined ? [] : customizations
    let parsed_customizations = {}
    for (let i = 0; i !== customizations.length; i += 1) {
      const customization_obj = customizations[i]

      const { conditions, values } = customization_obj

      const verified = this.verifyCustomizationCondition(conditions)
      if (verified) {
        parsed_customizations = Object.assign(parsed_customizations, values)
      }
    }

    const { query_hooks } = entityDefinition

    await this.setState({
      entity,
      entityDefinition,
      customizations: parsed_customizations,
      query_hooks: query_hooks || {},
    })

    await this.doInitDataAndRenderMode()

    await this.verifyHookOnComponentDidMount()

    this.setState({ initialized: true })
  }

  getEntityDefinitionFromApi = async () => {
    const { environment, authentication, c_def } = this.props
    const { entity } = c_def
    const { defValue, action, data } = entity

    const requestData = baseRequestObject(
      apiCallMap.serverAction,
      defValue,
      apiRequestTypesMap.post,
      environment,
      authentication
    )

    requestData.placeholderMapping.push({
      pSource: 'static',
      pKey: '{action}',
      pValue: action,
      pDefValue: action,
    })

    const parsedEp = parseEndpoint(requestData)

    const response = await httpPost(
      `${process.env.apiUrl}${parsedEp}`,
      data,
      true
    )

    let definition = {}
    const { status } = response
    if (status === 200 || status === 201) {
      try {
        definition = JSON.parse(response.data.data)
      } catch (e) {
        console.log('unable to parse the entity definition json')
      }
    }

    return definition
  }

  /* Verifying if there is a hook on componentDidMount. */
  verifyHookOnComponentDidMount = async () => {
    // hcdm=[{"an":"emode","p":{"ID":21}}]
    const { query_hooks } = this.state

    const hcdm = getQsValue('hcdm')
    if (hcdm) {
      const hcdm_list = JSON.parse(hcdm)

      hcdm_list.forEach(async hcdm_obj => {
        /* Destructuring the hcdm_obj object. */
        const { an: action_name, p: params } = hcdm_obj
        const current_hook = query_hooks[action_name]
        const { specialized, actionName, actionPath } = current_hook

        /* Loading the action file and calling the action function. */
        let actions = null
        if (specialized) {
          actions =
            await require(`../../../jujo_specializations/src/${process.env.client}/${actionPath}`)
        } else {
          actions = await require(`../../${actionPath}`)
        }
        actions[actionName](this, params)

        /* Removing the parameter hcdm from the query string. */
        removeParametersFromQueryString(['hcdm'])
      })
    }
  }

  doInitDataAndRenderMode = async () => {
    await this.initializeData()
    await this.verifyInitialRenderMode()
  }

  componentDidUpdate = async prevProps => {
    const { c_def } = this.props
    const { reloadOnPropChanged } = c_def || false
    if (!reloadOnPropChanged) return

    const reInit = this.checkPropertyChanged(
      prevProps,
      this.props,
      reloadOnPropChanged
    )

    if (reInit) {
      await this.doInitDataAndRenderMode()
    }
  }

  checkPropertyChanged = (prevProps, props, propToVerify) => {
    let difference_found = false
    for (let i = 0; i !== propToVerify.length; i += 1) {
      const prop_to_verify = propToVerify[i]
      const splitted_prop = prop_to_verify.split('.')

      let prop_value = props[splitted_prop[0]]
      let prev_prop_value = prevProps[splitted_prop[0]]

      for (let j = 1; j !== splitted_prop.length; j += 1) {
        const prop_key = splitted_prop[j]
        prop_value = prop_value[prop_key]
        prev_prop_value = prev_prop_value[prop_key]
      }

      if (JSON.stringify(prop_value) !== JSON.stringify(prev_prop_value)) {
        difference_found = true
        break
      }
    }
    return difference_found
  }

  getInitialRenderMode = async () => {
    const { customizations } = this.state
    const { initialRenderMode, actionsMap } = customizations || {}

    let renderMode = initialRenderMode || this.renderModeMap.view

    if (typeof renderMode === 'object') {
      const { actionName } = renderMode
      const actionPath = actionsMap[actionName]

      const specialized_actions =
        await require(`../../../jujo_specializations/src/${process.env.client}/${actionPath}`)

      renderMode = specialized_actions[actionName](this)
    }

    return renderMode
  }

  verifyInitialRenderMode = async () => {
    const { data } = this.state
    const initialRenderMode = await this.getInitialRenderMode()
    if (initialRenderMode === this.renderModeMap.edit) {
      const firstRecord = data[0]
      this.enterEditMode(firstRecord)
    } else if (initialRenderMode === this.renderModeMap.add) {
      this.enterAddMode()
    }
  }

  verifyCustomGetDataSource = async () => {
    const { customizations } = this.state
    const { session, c_def } = this.props
    const { injectedData } = c_def
    const { session_id } = session

    const { customDataSource, actionsMap } = customizations || {}
    const { get_data } = customDataSource || {}
    if (get_data) {
      const pattern = /^{act:/i
      const { source, defaultValue, relatedPath } = get_data

      let data_obj = {}
      if (source === sourcesMap.session) {
        const updated_session_data = await getSessionFromServer(session_id)

        const steps = relatedPath.split('/')
        data_obj = cloneObj(updated_session_data)
        for (let i = 0; i !== steps.length; i += 1) {
          let next_step = steps[i]
          const is_action = pattern.test(next_step)
          if (is_action) {
            const actionName = next_step.match(/{act:(.*)/)[1].slice(0, -1)
            const actionPath = actionsMap[actionName]
            const specialized_actions = require(`../../../jujo_specializations/src/${process.env.client}/${actionPath}`)
            next_step = specialized_actions[actionName](this)
          }
          data_obj = data_obj[next_step] || {}
        }
      } else if (source === sourcesMap.static) {
        data_obj = defaultValue
      } else if (source === sourcesMap.injectedData) {
        data_obj = injectedData
      } else if (source === sourcesMap.jujoGrid) {
        data_obj = {}
      }

      return data_obj
    }
    return false
  }

  initializeData = async () => {
    this.setState({ initialized: false })

    const custom_data_source = await this.verifyCustomGetDataSource()
    if (custom_data_source !== false) {
      const data_value = Array.isArray(custom_data_source)
        ? custom_data_source
        : [custom_data_source]
      this.setState({ initialized: true, data: data_value })

      return
    }

    const reqObj = this.composeRequest()
    const { parsedEp } = reqObj

    const result = await httpGet(`${process.env.apiUrl}${parsedEp}`)
    if (result) {
      const { status, data } = result
      if (status === 200) {
        this.setState({ data: data.data })
      }
    }

    this.setState({ initialized: true })
  }

  composeRequest = () => {
    const { entityDefinition, entity, searchValue } = this.state
    const { environment, authentication, parentData } = this.props

    const { apis } = entityDefinition
    const { getData } = apis
    const { apiCall, requestType, defaultFilters, defaults } = getData

    const requestData = baseRequestObject(
      apiCallMap[apiCall],
      entity,
      requestType,
      environment,
      authentication
    )

    requestData.defaultFilters = defaultFilters || []
    requestData.parentData = parentData || {}
    requestData.qsDefaultProps = defaults || requestData.qsDefaultProps
    requestData.qsDefaultProps.searchValue = searchValue

    const parsedEp = parseEndpoint(requestData)

    return {
      parsedEp,
      data: [],
    }
  }

  performSearch = async value => {
    await this.setState({ searchValue: value })
    await this.initializeData()
  }

  setViewMode = async (initComponent = false) => {
    if (initComponent) {
      await this.initializeData()
    }

    this.setState({ renderMode: this.renderModeMap.view, dataSource: {} })
  }

  enterEditMode = async (data, readonly = false) => {
    // anche se eslint dice che questo await non è necessario, è assolutamente necessario! Non toccare
    // eslint-disable-next-line react/no-unused-state
    await this.setState({ editModeTransitionalDataSource: data })

    const { customizations } = this.state
    let dataSourceForEdit = data

    const { customDataSource } = customizations || {}
    const { edit_data } = customDataSource || {}
    if (edit_data) {
      dataSourceForEdit = await this.getCustomEditData(edit_data)
    }

    this.setState({
      renderMode: this.renderModeMap.edit,
      dataSource: dataSourceForEdit,
      readonly,
    })
  }

  enterAddMode = async () => {
    this.setState({ renderMode: this.renderModeMap.add, dataSource: {} })
  }

  getCustomEditData = async edit_data_definition => {
    const { source, relatedPath, actionName } = edit_data_definition
    let data = {}
    if (source === sourcesMap.customAction) {
      const DynamicActionClass = require(`../../../jujo_specializations/src/${process.env.client}/actions/${relatedPath}`)
      data = await DynamicActionClass[actionName](this)
    }

    return data
  }

  handleCancel = async () => {
    await this.setViewMode()
  }

  handleOnComplete = async () => {
    const { data } = this.state
    const initialRenderMode = await this.getInitialRenderMode()

    if (initialRenderMode === this.renderModeMap.view) {
      await this.setViewMode(true)
    } else if (initialRenderMode === this.renderModeMap.edit) {
      const firstRecord = data[0]
      this.enterEditMode(firstRecord)
    }
  }

  renderAddons = (position, mode) => {
    const { c_def } = this.props
    const { addons } = c_def

    const html = []

    if (!addons) return html
    for (let i = 0; i !== addons.length; i += 1) {
      const addon = addons[i]
      const { component, renderPosition, renderMode, specialized } = addon
      if (
        renderPosition.indexOf(position) >= 0 &&
        renderMode.indexOf(mode) >= 0
      ) {
        let DynamicComponent = false
        if (specialized && specialized === true) {
          DynamicComponent =
            require(`../../../jujo_specializations/src/${process.env.client}/components/${component}`).default
        } else {
          DynamicComponent = require(`../${component}`).default
        }

        html.push(<DynamicComponent key={component} sender={this} />)
      }
    }

    return html
  }

  render() {
    const {
      renderMode,
      readonly,
      entityDefinition,
      initialized,
      data,
      dataSource,
      customizations,
      entity,
    } = this.state
    const { c_def } = this.props

    const { renderer } = c_def
    const { noHeader, title } = customizations || {}

    const viewPath = renderer.viewPath || ''
    const viewComponent = renderer.viewComponent || ''
    const actionPath = renderer.actionPath || ''

    let DynamicViewComponent = null
    if (viewPath !== '') {
      DynamicViewComponent =
        require(`../../../jujo_specializations/src/${process.env.client}/components/${viewPath}/view.js`).default
    } else if (viewComponent === editableCompViewComponentsMap.jujo_grid) {
      DynamicViewComponent =
        require(`./default_views/${viewComponent}_view`).default
    }

    let DynamicActionsComponent = false
    if (actionPath !== '') {
      DynamicActionsComponent =
        require(`../../../jujo_specializations/src/${process.env.client}/components/${viewPath}/actions.js`).default
    }

    return (
      <div className={classNames('')}>
        {!initialized && (
          <div
            className={classNames(
              'd-flex justify-content-center align-items-center'
            )}
            style={{ height: '100%' }}
          >
            {translate('loading')}
            <div
              className={classNames('loading-icon theme-svg ms-1')}
              style={{
                backgroundPosition: 'center',
                backgroundSize: '30px 30px',
                backgroundRepeat: 'no-repeat',
                height: '30px',
                width: '30px',
              }}
            />
          </div>
        )}
        {initialized && (
          <>
            {!noHeader && (
              <div
                className={classNames(
                  'd-flex justify-content-between align-items-center border-bottom border-2 py-2'
                )}
              >
                <div className={classNames('fw-bold')}>{translate(title)}</div>
                {DynamicActionsComponent && (
                  <DynamicActionsComponent
                    enterEditMode={this.enterEditMode}
                    enterAddMode={this.enterAddMode}
                    data={data}
                  />
                )}
              </div>
            )}
            {renderMode === this.renderModeMap.view && DynamicViewComponent && (
              <div className={classNames('')}>
                {this.renderAddons(
                  this.renderPositionsMap.before,
                  this.renderModeMap.view
                )}
                <DynamicViewComponent
                  data={data}
                  c_def={c_def}
                  customizations={customizations}
                  entity={entity}
                  entityDefinition={entityDefinition}
                  enterEditMode={this.enterEditMode}
                  enterAddMode={this.enterAddMode}
                  reloadData={this.initializeData}
                />
                {this.renderAddons(
                  this.renderPositionsMap.after,
                  this.renderModeMap.view
                )}
              </div>
            )}
            {renderMode === this.renderModeMap.edit && (
              <div className={classNames('')}>
                <JujoEditComponent
                  dataSource={dataSource}
                  readonly={readonly}
                  c_def={c_def}
                  customizations={customizations}
                  entity={entity}
                  entityDefinition={entityDefinition}
                  handleCancel={this.handleCancel}
                  handleOnComplete={this.handleOnComplete}
                />
              </div>
            )}
            {renderMode === this.renderModeMap.add && (
              <div className={classNames('')}>
                <JujoAddComponent
                  dataSource={dataSource}
                  c_def={c_def}
                  customizations={customizations}
                  entity={entity}
                  entityDefinition={entityDefinition}
                  handleCancel={this.handleCancel}
                  handleOnComplete={this.handleOnComplete}
                />
              </div>
            )}
          </>
        )}
      </div>
    )
  }
}

export default connect(mapStateToProps, actionCreators)(JujoEditableComponent)
