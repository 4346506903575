import classNames from 'classnames'
import React from 'react'
import { connect } from 'react-redux'
import fileVisibilityTypesMap from '../../../enums/fileVisibilityTypesMap'

import * as actionCreators from '../../../store/actions'
import { mapStateToProps, uploadFileToServer } from '../../../utils'
import { getFileTypeClass } from './helper'

export class ECFileFieldComponent extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      fieldValue: '',
    }
  }

  componentWillUnmount() {
    // Fix Warning: Can't perform a React state update on an unmounted component
    this.setState = () => {}
  }

  componentDidMount = async () => {
    const { initialValue } = this.props
    this.setState({ fieldValue: initialValue })
  }

  renderFileField = () => {
    const { fieldValue } = this.state
    const { field, handleValueChanged, authentication } = this.props
    const { access_token } = authentication

    const { name, acceptedTypes, visibility } = field

    const html = []

    const fileUrl =
      visibility === fileVisibilityTypesMap.private
        ? `${fieldValue}?access_token=${access_token}`
        : fieldValue

    const ext = fieldValue ? fieldValue.split('.').pop() : ''
    const typeClass = getFileTypeClass(ext)

    html.push(
      <div key={`file_${name}`}>
        <div
          className={classNames(
            'd-flex justify-content-between align-items-center'
          )}
        >
          <div
            className={classNames(
              'me-2 theme-svg',
              fieldValue ? typeClass : ''
            )}
            style={{
              backgroundSize: 'contain',
              backgroundRepeat: 'no-repeat',
              backgroundPosition: 'left',
              height: '24px',
              width: '24px',
            }}
          />

          <div className={classNames('d-flex align-items-center')}>
            <div
              className={classNames(
                'theme-svg mx-1',
                fieldValue ? 'edit-icon' : 'upload-icon'
              )}
              style={{
                backgroundPosition: 'center',
                backgroundSize: 'contain',
                backgroundRepeat: 'no-repeat',
                height: '18px',
                width: '18px',
              }}
              label="file_action_btn"
              role="button"
              tabIndex={0}
              onClick={() => {
                const inputFile = document.getElementById(name)
                inputFile.click()
              }}
              onKeyPress={() => {
                const inputFile = document.getElementById(name)
                inputFile.click()
              }}
            />

            {fieldValue && (
              <>
                <div
                  className={classNames('trash-icon theme-svg mx-1')}
                  style={{
                    backgroundPosition: 'center',
                    backgroundSize: 'contain',
                    backgroundRepeat: 'no-repeat',
                    height: '18px',
                    width: '18px',
                  }}
                  label="delete"
                  role="button"
                  tabIndex={0}
                  onClick={() => {
                    this.setState({ fieldValue: '' })
                    handleValueChanged(name, '')
                  }}
                  onKeyPress={() => {
                    this.setState({ fieldValue: '' })
                    handleValueChanged(name, '')
                  }}
                />
                <div
                  className={classNames('download-icon-2 theme-svg mx-1')}
                  style={{
                    backgroundPosition: 'center',
                    backgroundSize: 'contain',
                    backgroundRepeat: 'no-repeat',
                    height: '18px',
                    width: '18px',
                  }}
                  label="download"
                  role="button"
                  tabIndex={0}
                  onClick={() => {
                    if (typeof window !== 'undefined') {
                      window.open(fileUrl)
                    }
                  }}
                  onKeyPress={() => {
                    if (typeof window !== 'undefined') {
                      window.open(fileUrl)
                    }
                  }}
                />
              </>
            )}
          </div>
        </div>
        <input
          id={name}
          type="file"
          accept={acceptedTypes}
          className={classNames('d-none')}
          onChange={async e => {
            const { files } = e.target

            if (files && files.length > 0) {
              const uploadedFile = await uploadFileToServer(field, files[0], {})
              const { data, status } = uploadedFile
              if (status === 200) {
                this.setState({ fieldValue: data.url })
                handleValueChanged(name, data.url)
              }
            }
          }}
        />
      </div>
    )

    return html
  }

  render() {
    return <>{this.renderFileField()}</>
  }
}

export default connect(mapStateToProps, actionCreators)(ECFileFieldComponent)
